import axios, { AxiosInstance } from "axios";

import * as Config from "../Config";

import { ErrorHandler, et500 } from "../components/shared/Types";

import { DiaryEntry } from "../api";


const axiosClient = (): AxiosInstance => {
    axios.defaults.withCredentials = true;
    return axios;
}

export const insertEntity = (visit : DiaryEntry, callback: ErrorHandler): void => {
    axiosClient().post<DiaryEntry>(Config.api_url + "diary", visit
    ).catch((error)  => {
        callback(et500("Error adding diary entry"));
    }).finally(callback) ;
};

export const updateEntity = (visit : DiaryEntry, callback: ErrorHandler): void => {
    axiosClient().put<DiaryEntry>(Config.api_url + "diary/"  + visit.uuid, visit
    ).catch(() => {
        callback(et500("Error saving diary entry"));
    }).finally(callback) ;
};

export const deleteEntity = (visit : DiaryEntry, callback: ErrorHandler): void => {
    axiosClient().delete<DiaryEntry>(Config.api_url + "diary/" + visit.uuid
    ).catch(() => {
        callback(et500("Error deleting diary entry"));
    }).finally(callback) ;
};