import React, { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import AddIcon from '@mui/icons-material/Add';

import * as Config from "../../Config";
import EditTourDialog from "./EditTourDialog"
import {EnrichedUser} from "../shared/Types"
import {flagImages} from "../tools/Assets"
import {EnrichedTour, Tour, TourResponse} from "../../api"

import { postTour } from "../../httpclient/TourDAO";

import { UserContext } from "../../contexts/UserContext";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./Plan.module.scss";

interface PlanProps {
    // Optionally a preselected tour (e.g. url path parameter). If it is defined, it will trigger switching
    // to the tour editor after loading the tours.
    tourUuid?: string;
    openTourEditor: (tour: EnrichedTour) => void;
    openLoginDialog: () => void;
}

const PlanList = ({tourUuid, openTourEditor, openLoginDialog}: PlanProps): ReactElement => {
    const user: EnrichedUser = useContext(UserContext).user;
    
    const [tours, setTours] = useState<EnrichedTour[]>([]);
    const [publicTours, setPublicTours] = useState<EnrichedTour[]>([]);
    const [errormsg, setErrormsg] = useState<string>("");

    const [growl, setGrowl] = useState<string>("");
    
    const [modalNewTourShow, setModalNewTourShow] = useState<boolean>(false);

    const loadTours = () : void => {
        axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        axios.get<TourResponse>(Config.api_url + "tours?count=10").then(response =>
            {
	            const loadedTours = response.data.tours;
                const loadedPublicTours = response.data.publicTours;
                const allTours = loadedTours.concat(loadedPublicTours);
                setTours(loadedTours);
                setPublicTours(loadedPublicTours);
                if (tourUuid) {
					// If a tourUuid was present, then switch to it
					for (const tour of allTours) {
						if (tour.tour.uuid === tourUuid) {
							openTourEditor(tour);
							break;
						}
					}
				} 
            },
            () => {
                        setErrormsg("Failed loading Tours");
            });
    };

    useEffect(loadTours, [user, tourUuid, openTourEditor]);

    const setStatus = (message: string, errormsg: string) : void => {
        setGrowl(message);
        setErrormsg(errormsg)
    }

	const handleNewTour = (tour: Tour): void => {
        postTour(tour, (error) => {setStatus("", error?.message || "")}).then(resp => resp && openTourEditor(resp)); 
    };

    const handleEditTour = (tour : EnrichedTour ): void => {
        //alert("Edit tour")
        openTourEditor(tour);
    };



    const renderAsTable = (toursArg: EnrichedTour[]): ReactElement[] => (
        toursArg.map((tour, index) => (
            <TableRow
                      key={index} hover  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => handleEditTour(tour) }
                      >

                    <TableCell component="td" scope="row"> {flagImages(tour.tour.countryCodes, 2) }      </TableCell>
                    <TableCell component="td" scope="row"> {tour.tour.name}      </TableCell>
                    <TableCell component="td" scope="row"> {tour.tour.start}      </TableCell>
            </TableRow>
        ))
    );
    
    const renderTourList = (toursArg: EnrichedTour[], tourTitle: string): ReactElement => {
        return (
            <div className={lstyles.list} style={{margin: "10px"}}>
                {toursArg && toursArg.length?
                 (

                    <TableContainer component={Paper}>
                      <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell><b>{tourTitle}</b></TableCell>
                            <TableCell><b>Start</b></TableCell>
                            {/* <TableCell>Owner</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>

                            {renderAsTable(toursArg)}

                        </TableBody>
                      </Table>
                    </TableContainer> )
                  : ""}
</div>
            );
    }


    return (
	<>
        <div className={styles.verticalList}>
            { (growl ) && <div> {growl}   </div> }
            { (errormsg) && <div className={styles.errormsg}> {errormsg} </div> }

            <div className={styles.tourTitle} >
            		{user.loggedIn ? (<>
            		       
            		          <div>Your Tours{user.userProfile.publicName && ",  " + user.userProfile.publicName}</div>
            		          <div><Button color="primary"  variant="outlined"  startIcon={<AddIcon />}
                                       			          onClick={() => {setModalNewTourShow(true); }} >Add new Tour</Button></div>
                      </>
                        )
                    : ( <>
                        Welcome guest
                        <div><Button color="primary"  variant="outlined"  startIcon={<AddIcon />}
                              onClick={() => {openLoginDialog(); }} >Login</Button> to create your own adventure</div>
                        </>  
                      )
            		    }
            </div>
            
            <div style ={{marginBottom:"10px"}}/>
            <div className={lstyles.list} style={{margin: "10px"}}>
            {renderTourList(tours, "Your trips")}
            

            {renderTourList(publicTours, "Shared trips")}
            </div>

            </div>
                    
            {modalNewTourShow ? <EditTourDialog user={user} tour={undefined} modalShow={modalNewTourShow} setModalShow={setModalNewTourShow} editTourCallback={handleNewTour} deleteTourCallback={undefined} /> : ""}
        </>
                    
    );
};

export default PlanList;
