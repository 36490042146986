import { ReactElement, useState, useEffect } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import * as Config from "../../Config";
import { formatDateYMD, formatNowDate, formatUnixEpochSmartly, parseLocalDate } from "../tools/DateUtil";
import { providerLinkByRefOrCoordinate } from "../shared/Provider";
import { providerRefSerialize} from "../shared/Geo"
import { LinkType, OpeningSeasons, OpeningPeriod, OpeningPeriodType, OpeningPerWeekday, OpeningHours, ProviderRef, UrlLink} from "../../api";
import {CoreMarker, EnrichedUser} from "../shared/Types"
import EditSeasonDialog from "./EditSeasonDialog";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./EditLocationDialog.module.scss";

interface EditProps {
    location: CoreMarker;
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    user: EnrichedUser;
}

const calculateNextSeasonStart = (seasonsRef: OpeningSeasons | undefined): string => {
    if (seasonsRef) {
        const openings: OpeningPeriod[] = seasonsRef.seasons;
        var nextSeason : string | undefined = undefined;
        openings.forEach(opening => {
            if (opening.to && (nextSeason === undefined || opening.to > nextSeason) ) {
                nextSeason = formatDateYMD(parseLocalDate(opening.to).add(1, 'day'));
            }
        });
    }
    
    const today = formatNowDate();
    
    if (nextSeason === undefined || nextSeason < today) {
        // Usually the user does not want to edit in the past. Thus, skip to today, if nextSeason is in the past.
        nextSeason = today;
    }
    
    return nextSeason;
}
       
const EditLocationDialog = ({location, user, modalShow, setModalShow}: EditProps): ReactElement => {
    const [opening, setOpening] = useState<OpeningPeriod | undefined>(undefined);
    // Season data loaded from DB
    const [seasons, setSeasons] = useState<OpeningSeasons | undefined>(undefined);
    const [showSeasonEditor, setShowSeasonEditor] = useState<OpeningPeriodType | undefined>(undefined);
    
    const [openingHoursURL, setOpeningHoursURL] = useState<string>("");
    const [editOpeningHoursURL, setEditOpeningHoursURL] = useState<boolean>(false);
   
   const saveOpeningHoursURL = (newUrl: string, providerRef: ProviderRef): void => {
           axios.post<UrlLink>(Config.api_url + "url?linkType=" + LinkType.OpeningHours + "&providerRef=" + providerRefSerialize(providerRef as ProviderRef) + "&url=" + btoa(newUrl))
           .then(response =>
                {
                    setOpeningHoursURL(response.data.url);
                },
                () => {
                    //setOpeningHoursURL(""); 
                    //setErrormsg("Error loading Seasons");
                });
   }
   
   const loadSeasons = (providerRef? : ProviderRef) : void => {
        if(providerRef) {
            axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)
    
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            axios.get<OpeningSeasons>(Config.api_url + "opening?providerRef=" + providerRefSerialize(providerRef as ProviderRef)).then(response =>
                {
                    setSeasons(response.data);
                },
                () => {
                    //setErrormsg("Error loading Seasons");
                });
                
            axios.get<UrlLink>(Config.api_url + "url?linkType=" + LinkType.OpeningHours + "&providerRef=" + providerRefSerialize(providerRef as ProviderRef)).then(response =>
                {
                    setOpeningHoursURL(response.data.url);
                },
                () => {
                    setOpeningHoursURL("");
                    //setErrormsg("Error loading Seasons");
                });
                
        } else {
            setSeasons(undefined);
        }
    };

    useEffect(() => {
		loadSeasons(location.providerRef);
       } , [location]);


    const hideSeasonEditor = (reloadData: boolean) : void => {
        setShowSeasonEditor(undefined);
        if (reloadData) {
            console.log("Load seasons for: " + location.providerRef)
            loadSeasons(location.providerRef);
        }
    }


    const hideSeasonEditorForce = () =>  {
        hideSeasonEditor(true);
    }




	// Copies the data from an existing OpeningPeriod to the editing fields 
    const handleSelectForEdit = (opening : OpeningPeriod) => {
        setOpening(opening);
        setShowSeasonEditor(opening.type);
	}


    const saveAndClose = () => {
		setModalShow(false);
	} 

    const formatOpening = (openingPerWeekday : OpeningPerWeekday): string => {
		const standardOpening : OpeningHours = openingPerWeekday && openingPerWeekday.standard;
		let out = "";
		if (standardOpening) {
			if (standardOpening.open) {
				out = standardOpening.opens + " - " + standardOpening.closes; 
			} else {
				out = "CLOSED";
			}
			const arr = Object.values(openingPerWeekday.weekdays);
			const firstDefined : OpeningHours | undefined = arr.find(opw => opw !== undefined);
			if (firstDefined) {
				out = out + " (*)";
				console.log(firstDefined + ", taken from: " +  arr);
			}
		} else {
			out = "CLOSED";
		}
		return out;
	}

    const renderAsTable = (rows: OpeningPeriod[]): ReactElement[] => (
        rows.map((row, index) => (
                    <TableRow className={lstyles.poientry}
                      key={index} hover  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => handleSelectForEdit(row) }
                      >

                    <TableCell component="th" scope="row"> {row.from}     </TableCell>
                    <TableCell component="th" scope="row"> {row.to}      </TableCell>
                    <TableCell component="th" scope="row"> {row.confirmTime ? <CheckBoxTwoToneIcon/> : <CheckBoxOutlineBlankTwoToneIcon/>}      </TableCell>
                    <TableCell component="th" scope="row"> {formatOpening(row.weekdays)}      </TableCell>
                    <TableCell component="th" scope="row"> {row.description}     </TableCell>
                    <TableCell component="th" scope="row"> {formatUnixEpochSmartly(row.mtime)}   </TableCell>

            </TableRow>
        ))
    );


    return (
        <>
        <Dialog open={modalShow}
            PaperProps={{ sx: { minWidth: "50vw", padding:"0px" } }}

              onClose={() => setModalShow(false)}>

                <DialogTitle>Opening Calendar: {location.name}</DialogTitle>

                <DialogContent>
                <div className={styles.modalcontent}>
                
                {seasons && seasons.seasons ?
                 (
                    <TableContainer component={Paper}>
                      <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>Confirmed</TableCell>
                            <TableCell>Weekdays</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Last update</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                 {renderAsTable(seasons.seasons)}
                 <TableRow>
                     <TableCell>Exceptions</TableCell>
                     <TableCell/>
                     <TableCell/>
                     <TableCell/>
                     <TableCell/>
                     <TableCell/>
                 </TableRow>

                 {renderAsTable(seasons.exceptions)}

                        </TableBody>
                      </Table>
                    </TableContainer> )
                  : "Opening Seasons unknown"}
                </div>
                
                <Button className={lstyles.cadd} color="primary"  variant="outlined"  startIcon={<AddIcon />}
                  onClick={() => {setOpening(undefined); setShowSeasonEditor(OpeningPeriodType.SEASON); }} >Add Season</Button>
                              
                <Button className={lstyles.cadd} color="primary"  variant="outlined"  startIcon={<AddIcon />}
                  onClick={() => {setOpening(undefined); setShowSeasonEditor(OpeningPeriodType.EXCEPTION); }} >Add Exception</Button>

                <hr/>
                
                <div className={lstyles.poientry}>
                {editOpeningHoursURL ?
                    (<>
                        <TextField value={openingHoursURL}
                            label="Opening hours URL"
                            onChange={e => { setOpeningHoursURL(e.target.value)}}
                            />
                        <Button className={lstyles.cadd} color="primary"  variant="outlined"  startIcon={<AccessTimeIcon />}
                            onClick={() => {saveOpeningHoursURL(openingHoursURL, location.providerRef as ProviderRef); setEditOpeningHoursURL(false); }} >Save URL </Button>
                    </>)
                     :
                    (<>  
                     {  openingHoursURL.length > 0 ?
                        ( <Button variant="outlined"  endIcon={<AccessTimeIcon />}>
                            <a href={openingHoursURL} target="_blank" rel="noreferrer"> Visit opening hours in official site&nbsp;</a>
                            </Button>
                            )
                      : "Click to add official opening hours URL" }
                        <EditIcon onClick={() => setEditOpeningHoursURL(true)} > </EditIcon>
                        
                        <Button variant="outlined"  endIcon={<OpenInNewIcon/>}>
                            <a href={providerLinkByRefOrCoordinate(location.providerRef, location.geoCoordinate)} target="_blank" rel="noreferrer">Info</a>
                        </Button>
                     </>
                        )
                     }
                </div>

                <hr/>
                

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => {setModalShow(false);  } }>Cancel</Button>
                <Button variant="outlined"  onClick={() => {saveAndClose();  } }>OK</Button>
            </DialogActions>
        </Dialog>
        
        {showSeasonEditor !== undefined && <EditSeasonDialog opening={opening}
           seasonStart={calculateNextSeasonStart(seasons)}
           updateAction={opening !== undefined} user={user} location={location}
           show={showSeasonEditor !== undefined} hideCallback={hideSeasonEditorForce} isExcpetion={showSeasonEditor === OpeningPeriodType.EXCEPTION}
          />
          }
          </>          

   );
};

export default EditLocationDialog;
