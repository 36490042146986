/** A Dialog to edit an existing tour or add a new tour
*/
import { ReactElement, useEffect, useState } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';

import DeleteIcon from '@mui/icons-material/Delete';


import MultiItemPicker from "../shared/MultiItemPicker";
import { CountryMap } from "../shared/GeoConstants";
import { EnrichedUser } from "../shared/Types";
import { DiaryEntry } from "../../api";
import { formatYMD_weekday, now, userTimezone } from "../tools/DateUtil";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./EditDiaryEntryDialog.module.scss";

interface EditProps {
    user: EnrichedUser;
    // If DiaryEntry is undefined, then it means that a new DiaryEntry should be inserted instead of modifying an existing one.
    // The DELETE button is also be hidden, as it makes no sense in that case.
    diaryEntry : DiaryEntry | undefined;
    modalShow: boolean;
    hide: () => void;
    editCallback: (diaryEntry : DiaryEntry ) => void;
    insertCallback: (DiaryEntry : DiaryEntry ) => void;
    deleteCallback: undefined | ((DiaryEntry : DiaryEntry ) => void);
}

const isNew = (diaryEntry: DiaryEntry | undefined) : boolean => {
    return diaryEntry === undefined || diaryEntry.uuid === "";
}


const calcDefaultTitle = (user: EnrichedUser, diaryEntry?: DiaryEntry) : string => {
    if (isNew(diaryEntry)) {
        return diaryEntry?.title ? diaryEntry?.title : "Diary entry " + formatYMD_weekday(now(), user.userProfile.language);
    } else {
        return diaryEntry?.title ? diaryEntry?.title : "Diary entry: " + formatYMD_weekday(now(), user.userProfile.language);
    }
       
}

const EditDiaryEntryDialog = ({user, diaryEntry, modalShow, hide, editCallback, insertCallback, deleteCallback}: EditProps): ReactElement => {
    const [title, setTitle] = useState<string>(calcDefaultTitle(user, diaryEntry) );
    const [category, setCategory] = useState<string>((diaryEntry && diaryEntry.category) || "default");
    
    // The country only contains a single country code (or none). Using an array is more convenient for the MultiItemPicker
    const [country, setCountry] = useState<string[]>( (diaryEntry && diaryEntry.address.country) ? [diaryEntry.address.country] : []);
    const [city, setCity] = useState<string>((diaryEntry && diaryEntry.address.city) || "");
    const [street, setStreet] = useState<string>((diaryEntry && diaryEntry.address.street) || "");
    
    const [notes, setNotes] = useState<string>((diaryEntry && diaryEntry.notes) || "");
    

    const updateEditFields = () : void => {
        setTitle(calcDefaultTitle(user, diaryEntry));
        setCategory((diaryEntry && diaryEntry.category) || "default");
        setCountry((diaryEntry && diaryEntry.address.country) ? [diaryEntry.address.country] : []);
        setCity((diaryEntry && diaryEntry.address.city) || "");
        setStreet((diaryEntry && diaryEntry.address.street) || "");
        setNotes((diaryEntry && diaryEntry.notes) || "");
    };
   
   useEffect( updateEditFields
    , [diaryEntry, user]
    );
    
    // add the new TourEvent, and close the Dialog
    const handleEdit = (visit: DiaryEntry | undefined) => {
	    if (isNew(visit)) {
            const hasDefaults = visit !== undefined;
            const nowDate = now();
            const nowTimeString = nowDate.format('HH:mm') ;
		    const newDiaryEntry : DiaryEntry = {
			 userId:user.userId as number,
             title: title,
             category: category,
			 providerRef: hasDefaults ? visit.providerRef : undefined,
			 address: { country: country.length > 0 ? country[0] : "", city: city, street: street},
			 geoCoordinate: hasDefaults ? visit.geoCoordinate : undefined,
			 notes: notes,
			 visitDate: {year: nowDate.year(), month: nowDate.month()+1, day: nowDate.date()}, // TODO add to dialog, e.g. "now", "today", "yesterday", "date-picker"
			 visitTime: nowTimeString,
			 timeZoneOffsetSeconds: undefined,
			 timeZoneName: userTimezone(),
			 
			 
			 // The following fields are set by the backend
			 uuid: "",
             ctime: 0,
             mtime: 0
            };			 

 		   insertCallback(newDiaryEntry);
   	    } else {
            const de : DiaryEntry = visit as DiaryEntry; 
            de.title = title;
            de.category = category;
            de.address = { country: country.length > 0 ? country[0] : "", city: city, street: street};
            de.notes = notes;
 	        editCallback(de);
        }
        hide();
    }

    // For now the user can only retrieve its own diaryEntrys. Thus any diaryEntry is writable.
    const writable: boolean = true;
     
    return (
    <>
        <Dialog open={modalShow}
            PaperProps={{ sx: { minWidth: "30vw", padding:"0px" } }}

              onClose={() => hide()}>

                <DialogTitle>{isNew(diaryEntry) ? "New diary entry" : "Edit diary entry"}</DialogTitle>

                <DialogContent>
                <div className={styles.modalcontent}>
                <hr/>

                   <div className={lstyles.dialogcontent} style={{alignItems:"flex-start"}}>

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="Event" type="search" value={title}
                      onChange={e => { setTitle(e.target.value) }} />

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="Category" type="search" value={category}
                      onChange={e => { setCategory(e.target.value) }} />

                    <MultiItemPicker onChange={setCountry} title="Country" selected={country} keyValues={CountryMap} multiple={false} colonSelectorFormat={true}/>

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="City" type="search" value={city}
                      onChange={e => { setCity(e.target.value) }} />

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="Street / freeform address" autoFocus={true} type="search" value={street}
                      onChange={e => { setStreet(e.target.value) }} />
                      
                    <TextareaAutosize
                        className={lstyles.textarea}
                        minRows={1}
                        placeholder="Notes"
                        autoFocus={true}
                        value={notes} onChange={e => { setNotes(e.target.value) }}
                        disabled={!writable}
                    />
                   

                      </div>

                <hr/>
            </div>

            </DialogContent>
            <DialogActions>
                {diaryEntry && writable && deleteCallback && (<Button variant="outlined" color="error" startIcon={<DeleteIcon />}
                  onClick={() => {deleteCallback(diaryEntry); hide(); }} >Delete</Button>)}
                <Button variant="outlined" onClick={() => {hide();  } }>Cancel</Button>
                <Button variant="outlined" disabled={!writable} onClick={() => {handleEdit(diaryEntry);  } }>OK</Button>
            </DialogActions>
        </Dialog>

     </>   );
};

export default EditDiaryEntryDialog;
