import { ReactElement } from "react";

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel'

import ToggleButton from '@mui/material/ToggleButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';

import {GeocodedLocation} from "../../api"
import {CoreMarker} from "../shared/Types"
import {formatAddress, formatAddressFull, formatAddressOnlyCountry} from "../shared/Geo"
import {providerIcon} from "../shared/Provider"

import lstyles from "./PoiSelector.module.scss";

/** Shows results of a POI search. A bar on the top shows matching countries, which can be used to refine the saearch  */

interface PoiSelectorProps {
    locations: GeocodedLocation[];
    // selectedHandler is triggered if the user clicks the search result
    selectedHandler: (location : CoreMarker) => void;
    // previewHandler is triggered if the user hovers over the search result
    previewHandler: undefined | ((location : CoreMarker) => void);
    // countryFilterHandler is triggered if a user clicks a "country filter" button. The caller should execute a new search to update locations
    countryFilterHandler: undefined | ((countryCode : string) => void);
    resultboxVisible: boolean;
    pristine: boolean; // If the search should be considered pristine, e.g. the corresponding search field(s) are empty 
    noResultsHelpMessage: ReactElement;
}

const PoiSelector = ({selectedHandler, previewHandler, locations, resultboxVisible, countryFilterHandler, pristine, noResultsHelpMessage}: PoiSelectorProps): ReactElement => {

	const ccs = new Set<string>();
	for (const location of locations) {
		const cc = location.address.country;
		if (cc) {
			ccs.add(cc)
		}
	}
	

    const renderCountries = (ccs: Set<string>): ReactElement[] => {
	if (ccs.size === 1) {
		return ( Array.from(ccs).sort().map((cc)  => (
			<>
			 <ToggleButton value="cc" selected={true} onChange={() => {countryFilterHandler && countryFilterHandler("");  } }>{cc} <CancelIcon/></ToggleButton>
			 </>
			 ))
			 )
			
	} else {
		return (
		Array.from(ccs).sort().map((cc)  => (
			<>
			<Button variant="outlined" onClick={() => {countryFilterHandler && countryFilterHandler(cc);  } }>{cc}</Button>
			</>
		))
		)
		}
	};


	// This is a super special method, only to be called from the renderAsTable() method. It knows what this mehtod wants.
	const formatAddressForLocationColumn = (location: GeocodedLocation): string => (
		// If the location is a city, then we deliver just the country (OMITTING THE CITY). The rationale behind this is, that the table already has the city name
		// in the "Place" column. So we do not want to repeat ourselves in the "Location" column.		
		location.address.city === location.name ? formatAddressOnlyCountry(location.address) : formatAddress(location.address)
	);
	
    const renderAsTable = (toursArg: GeocodedLocation[]): ReactElement[] => (
        toursArg.map((location, index) => (
        <>
        <Tooltip title={formatAddressFull(location.address)}>
            <TableRow
              key={index} hover  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => selectedHandler(location)}
              onMouseEnter={() => previewHandler && previewHandler(location)}
            >

			<TableCell>{providerIcon(location.providerRef)}</TableCell>
            <TableCell className={location.operating ? lstyles.poientry : lstyles.poientryClosed} component="th" scope="row"> {location.name}      </TableCell>
            <TableCell align="right">{formatAddressForLocationColumn(location)}</TableCell>
            </TableRow>
            </Tooltip>
            </>
        ))
    );
    
 return (
    <>
   		{ resultboxVisible ? (
			<> 
                {locations && locations.length ? (
				<>
				    <div>
					{renderCountries(ccs)}
					</div>

                    <TableContainer component={Paper}>
                      <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Place</TableCell>
                            <TableCell align="right">Location</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                			{renderAsTable(locations)}

                        </TableBody>
                      </Table>
                    </TableContainer>
                </>)
                    : ""
                }

                {!pristine && locations && locations.length === 0 ? <div className={lstyles.resultinfo}>{noResultsHelpMessage}</div> : ""}
    		</>
            ) : ""
        }
    </>

    );
};

export default PoiSelector;
