import React from "react";

import {AccountStatus, EnrichedTour} from "../api";
import {EnrichedUser} from "../components/shared/Types";

export const GUEST: EnrichedUser = { loggedIn: false, accountStatus:AccountStatus.Guest, userProfile:{publicName:"",countryCode:"",language:"", homeAddress:{}}, metadata:{atime:0, ctime:0,mtime:0,loginCount:0} };


interface UserContextData {
    user: EnrichedUser;
    setUser: (user: EnrichedUser) => void,
    currentTour: EnrichedTour | undefined,
    setCurrentTour: (tour: EnrichedTour | undefined) => void

}

export const UserContext = React.createContext<UserContextData>({
     user: GUEST,
     setUser: () => {},
     currentTour: undefined,
     setCurrentTour: () => {}
    });