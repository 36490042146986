import axios, { AxiosInstance } from "axios";

import * as Config from "../Config";

import { ActivePOIs, ErrorHandler, et500 } from "../components/shared/Types";

import { LocationResponse, SearchResult } from "../api";


const axiosClient = (): AxiosInstance => {
    axios.defaults.withCredentials = true;
    return axios;
}


export const getPois = (actPois : ActivePOIs, cc: string, fromDate: string, daysAhead: number, callback: ErrorHandler) : Promise<void | LocationResponse[]> => {
    let providers="";
    if (actPois.rcdb && actPois.unescoWhc) {
        providers = "rcdb,unesco-whc";
    }
    else if (actPois.unescoWhc) {
        providers = "unesco-whc";
    }
    else if (actPois.rcdb) {
        providers = "rcdb";
    }
    const ccArg = cc?.length > 0 ? "&cc=" + cc : "";
    const fromArg = fromDate?.length > 0 ? "&fromDate=" + fromDate : "";
    const daysAheadArg = daysAhead > 0 ? "&daysAhead=" + daysAhead : "";
    // TODO The GEO query uses hardcoded geo coordinates 
    const url = Config.poi_url + "pois/geo?north=90&south=-90&west=-180&east=180&providers=" + providers + ccArg + fromArg + daysAheadArg;

    
    
    return axiosClient().get<SearchResult<LocationResponse>[]>(url).then(response =>
        {
            let foundLocations = response.data;
            let newLocations : LocationResponse[] = [];
            // Convert the response. We currently only use "result", but not "matchQuality".
            // Additionally we have to convert the opening calendar from an object to an actual Map.
            for (let i=0; i < foundLocations.length; i++) {
                const result = foundLocations[i].result;
                newLocations.push(result);
            }
            return newLocations;
        }).catch(()  => {
            callback(et500("Error loading locations"));
        }).finally(callback) ;
    };

