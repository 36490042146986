import { ReactElement } from "react";

import Box from '@mui/material/Box';

import lstyles from "./Info.module.scss";

/* eslint-disable react/jsx-no-target-blank */ /* We allow links to safe sites like www.openstreetmap.org */

const Info = (): ReactElement => (
<Box component="div" className={lstyles.toplevel}> 
    <h2>Touredo Tour planner</h2>
        Note down and remember interesting activities<br/>
        Plan a tour<br/>
        Get tour proposals based on your remembered activieties<br/>

    <i>Version 0.9</i>

    <h2 id="impressum">Impressum</h2>
    <p>touredo<br/>
    c/o impressumsservice<br/>
    Foostr. 1<br/>
    12345 Barhausen</p>

    <h2 id="anbieterkennzeichnung">Anbieterkennzeichnung</h2>


     {/* Note: With OpenStreetMap and other friendly sites we send a Referrer, to allow to analyze where requests
          originate from. An "opener"  is never required. We use "noopener" for pre-2021 browsers or IE11. */}

    <h2 id="contact">Credits</h2>
    UI: <a href="https://reactjs.org/">React</a> +  <a href="https://mui.com/">MUI</a><br/>
    Geo data by <a href="https://www.openstreetmap.org/" target="_blank" rel="noopener">OpenStreetMap</a>
     - Veröffentlicht unter <a href="https://opendatacommons.org/licenses/odbl/" target="_blank" rel="noopener">ODbL</a><br/>
     Cities and  countries are build using preprocessed OpenStreetMap data from the <a href="https://github.com/OSMNames/OSMNames/releases" target="_blank" rel="noopener noreferrer">OSMNames project</a><br/>

    Geocoding by Nominatim, using <a href="https://www.openstreetmap.org/" target="_blank" rel="noopener">OpenStreetMap</a> data. <br/>
    
    Unesco world heritage descriptions:
      Licensed under <a href="https://creativecommons.org/licenses/by-sa/3.0/igo/" target="_blank" rel="noopener">Creative Commons CC-BY-SA IGO 3.0</a> <br/>

    Icons: Eva Icons: https://akveo.github.io/eva-icons/#/ <br/>


    <h2 id="nutzungsbedingungen">Nutzungsbedingungen</h2>
    <p>
    Zombies reversus ab inferno, nam malum cerebro. De carne animata corpora quaeritis. Summus sit​​, morbo vel maleficia? De Apocalypsi undead dictum mauris. <br/>Hi mortuis soulless creaturas, imo monstra adventus vultus comedat cerebella viventium. Qui offenderit rapto, terribilem incessu. The voodoo sacerdos suscitat mortuos comedere carnem. Search for solum oculi eorum defunctis cerebro. Nescio an Undead zombies. Sicut malus movie horror.

    Cum horribilem resurgere de sepulcris creaturis, sicut de iride et serpens. Pestilentia, ipsa screams. <br/> Pestilentia est haec ambulabat mortuos. Sicut malus voodoo. Aenean a dolor vulnerum aperire accedunt, mortui iam vivam. Qui tardius moveri, sed in magna copia sint terribiles legionis. Alii missing oculis aliorum sicut serpere crabs nostram. Putridi odores aere implent.

    Tremor est vivos magna. Expansis ulnis video missing carnem armis caeruleum in locis. <br/>A morbo amarus in auras. Nihil horum sagittis tincidunt, gelida portenta. The unleashed virus est, et iam mortui ambulabunt super terram. Souless mortuum oculos attonitos back zombies. An hoc incipere Clairvius Narcisse, an ante? Is bello mundi z?

    In omni memoria patriae religionis sunt diri undead historiarum. Golums, zombies et fascinati. Maleficia! Vel a modern perhsaps morbi. A terrenti contagium. Forsitan illud Apocalypsi, vel malum poenae horrifying fecimus. <br/>Indeflexus monstra per plateas currere. Fit de nostra carne undead. Poenitentiam agite pœnitentiam! Vivens mortua sunt apud nos.

    Ut fames cerebro enim carnis, viscera et organa viventium. Sicut spargit virus ad impetum, qui supersumus. Avium, canum, fugere ferae et infecti horrenda monstra. Videmus deformis horrenda daemonum. <br/> Panduntur portae inferi. Finis accedens sentio terrore perterritus et taedium. The horror, monstra significant finem. Terror sit unum superesse sentit, ut caro eaters caule nobis.
    </p>

    <h2 id="dataprotection">Datenschutz-Erklärung</h2>
    <p>
    Bavaria ipsum dolor sit amet nia need Spezi, griaß God beinand. Aasgem is nimma des is hoid aso Freibia, Hetschapfah griasd eich midnand umananda d’. A Maß und no a Maß Kirwa nomoi Kirwa di, Freibia oamoi. Gor griaß God beinand i hab an, liberalitas Bavariae des wiad a Mordsgaudi?<br/> Dringma aweng des is hoid aso greaßt eich nachad middn Gams geh. Goaßmaß Baamwach Ohrwaschl Graudwiggal da.<br/> Und glei wirds no fui lustiga Obazda pfiad de wos Weiznglasl Ewig und drei Dog, a bissal blärrd Wurscht da zünftig. Hallelujah sog i, luja gar nia need baddscher Biaschlegl Biakriagal Landla ned i midanand! San wann griagd ma nacha wos z’dringa Graudwiggal Stubn Habedehre Biaschlegl, wann griagd ma nacha wos z’dringa.<br/> Gelbe Rüam hawadere midananda Spuiratz, wia da Buachbinda Wanninger Graudwiggal? Hawadere midananda Foidweg nix Gwiass woass ma ned des muas ma hoid kenna Weiznglasl Bradwurschtsemmal spernzaln is des liab baddscher. Ma Marei sog i.

    Heimatland Ewig und drei Dog Gidarn Mongdratzal hinter’m Berg san a no Leit Biaschlegl a liabs Deandl und glei wirds no fui lustiga?<br/> Graudwiggal lem und lem lossn Goaßmaß heitzdog wolpern Mongdratzal Gaudi, Griasnoggalsubbm Griasnoggalsubbm dahoam. Om auf’n Gipfe des i daad, Kneedl und wea nia ausgähd, kummt nia hoam gscheid ja, wo samma denn?<br/> Om auf’n Gipfe obandln Kuaschwanz gschmeidig i is ma Wuascht g’hupft wia gsprunga a Maß und no a Maß etza du dadst ma scho daugn oba. A so a Schmarn Gamsbart scheans Sauakraud mim Radl foahn. A bissal wos gehd ollaweil schoo de back mas, Servas so schee mechad. I mechad dee Schwoanshaxn Wurschtsolod i Vergeltsgott gar nia need wos a fescha Bua pfundig midanand Maderln Spuiratz.<br/> Amoi om auf’n Gipfe Zwedschgndadschi Watschnpladdla Spezi wolpern, Bussal griaß God beinand anbandeln: Bussal Xaver Spotzerl, da is a Maß und no a Maß Goaßmaß Wurschtsolod Resi. Hinter’m Berg san a no Leit Foidweg Marei naa, .

    A bravs do Sauwedda in da Trachtnhuat Enzian, Radi sodala schoo a? Heid aba griasd eich midnand, Leonhardifahrt. Nimmds af helfgod, Weißwiaschd gschmeidig: Ded owe Weißwiaschd, heid weida. A Hoiwe Zidern hoggd, ebba kimmt. Weißwiaschd oa glei no Biazelt, und glei wirds no fui lustiga boarischer Zwedschgndadschi oamoi! Geh bitt Foidweg moand oans glei griaß God beinand soi Jodler hea: Vergeltsgott no Brotzeit gscheid!<br/> Ned wea ko, dea ko Weiznglasl obandln trihöleridi dijidiholleri a bravs Foidweg. Ma oa sowos a liabs Deandl, um Godds wujn nois ded amoi allerweil is des liab du dadst ma scho daugn. Du dadst ma scho daugn helfgod kloan Namidog jo mei is des schee Namidog Spuiratz a liabs Deandl a fescha Bua da Kini!
    </p>


    <h2 id="contact">Kontakt</h2>
    <p>Ihr erreicht uns per Mail: info@touredo.org
    </p>
</Box>
);

export default Info;
