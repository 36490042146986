/** A Dialog to edit an existing tour or add a new tour
*/
import { ReactElement, useState } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


import MultiItemPicker from "../shared/MultiItemPicker";
import TouredoDatePicker from "../shared/TouredoDatePicker";
import { providerMap } from "../shared/Provider";
import { CountryMap } from "../shared/GeoConstants";
import { EnrichedUser } from "../shared/Types";
import {Tour, Visibility} from "../../api";
import { formatNowDate } from "../tools/DateUtil";

import { exportTour, postTour } from "../../httpclient/TourDAO";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./EditEventDialog.module.scss";

interface EditProps {
    user: EnrichedUser;
    // If tour os undefined, then it means that a new tour should be inserted instead of modifyingf an existing one.
    // The DELETE button is also be hidden, as it makes no sense in that case.
    tour: Tour | undefined;
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    editTourCallback: (tour : Tour ) => void;
    deleteTourCallback: undefined | ((tour : Tour ) => void);
}

export const visibilityMap: Map<string,string> = new Map([
    ["Public", "Shared with every user"],
    ["Private", "Private tour"]]
    );

const determineDefaultCountryCodes= (user: EnrichedUser ): string[] => {
	if (user.countryCode) {
		return [user.countryCode];
	}
	if (user.language) {
		// This is a hack, and will only work if there is a country "matching" the language 
		return [user.language.toUpperCase()];
	}
	if (navigator.language) {
		return [navigator.language.toUpperCase()]; // same hack as user.language
	}
	return [""]; // No countryCode preference
}

const EditTourDialog = ({user, tour, modalShow, setModalShow, editTourCallback, deleteTourCallback}: EditProps): ReactElement => {
	const nowDateAsString : string = formatNowDate();
    const [titleTF, setTitleTF] = useState<string>(((tour && tour.name)) || "Tour on " + nowDateAsString);
    const [visibilityDD, setVisibilityDD] = useState<string>((tour && tour.visibility) || Visibility.Private); // TODO string vs Enum. Fix "PUBLIC""
    const [startDate, setStartDate] = useState<Date | string>(tour ? tour.start : nowDateAsString);
    //const [endDate, setEndDate] = useState<Date | string>((tour && tour.end) || "");
    const endDate = "";
    const [countryCodes, setCountryCodes] = useState<string[]>(tour && tour.countryCodes.length > 0 ? tour.countryCodes : determineDefaultCountryCodes(user));
    const [providers, setProviders] = useState<string[]>(tour && tour.providers.length > 0 ? tour.providers : ["rcdb","unesco-whc"]);

    const setVisibilityFromPicker = (selectedArr: string[]) => {
        // As it is a single item picker, the selected itgem is in the first element (if exists)
        const selected: string = selectedArr.length > 0 ? selectedArr[0] : "Private";
        setVisibilityDD(selected);
    }
     
    // add the new TourEvent, and close the Dialog
    const handleEditTour = (tour: Tour | undefined) => {
    const visibility = visibilityDD === "Public" ? Visibility.Public : Visibility.Private;
	    if (!tour) {
		   const newTour : Tour = {
			 userId:user.userId as number,
			 name:titleTF,
			 visibility:visibility,
			 countryCodes:countryCodes,
			 providers:providers,
			 start:startDate as string,
			 end:endDate as string,
			 // The following fields are set by the backend on insertion
			 uuid:"",
			 atime:0,
			 ctime:0,
			 mtime:0
		   };
 		   editTourCallback(newTour);
		   

   	    } else {
	        tour.name = titleTF;
	        tour.visibility = visibility;
	        tour.countryCodes = countryCodes;
	        tour.providers = providers;
	        tour.start = startDate as string;
	        tour.end = endDate as string;
 	        editTourCallback(tour);
        }
        setModalShow(false);
    }

    const duplicateTourCallback = (tour: Tour) => {
        alert("Duplication feature is coming soon.");
        return;
        // TODO Duplication requires to duplicate both the tour and thge associated tour events.
        // Thus, it is probably easier to code this in the backend.  
        
        // The code below is incomplete. It only copies the Tour, but not the tour events. 
        const newTour: Tour = { ...tour };
        newTour.userId = user.userId as number;
        // uuid and the *time fields are set by the backend on insertion
        newTour.uuid = "";
        newTour.name += " [copy]";
        postTour(newTour, (error) => {}).then(resp => {});
        setModalShow(false);
    }


    const exportTourCallback = (tour: Tour) => {
        exportTour(tour, () => {}).then(csvContent => {
            if (csvContent) {
                const blob = new Blob([csvContent as BlobPart], { type: 'text/csv;charset=utf-8,' })

                // Trigger download, by creating an anchor, and clicking it        
                const link = document.createElement('a');
                var filename : string = tour.name === "" ? "Tour" : tour.name;
                filename += " " + tour.start + ".csv"
                link.download = filename;
                link.href = URL.createObjectURL(blob); // method creates a string containing a URL representing the object
                link.click();
            }
        });
        

        setModalShow(false);
    }

    // User owning the tour may write. If tour is new (undefined), then the user also may write 
    const writable: boolean = tour === undefined || (user.userId === tour.userId);
     
    return (
    <>
        <Dialog open={modalShow}
            PaperProps={{ sx: { minWidth: "30vw", padding:"0px" } }}

              onClose={() => setModalShow(false)}>

                <DialogTitle>{tour ? "Edit tour" : "New tour"}</DialogTitle>

                <DialogContent>
                <div className={styles.modalcontent}>
                <hr/>

                   <div className={lstyles.dialogcontent} style={{alignItems:"flex-start"}}>

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="Event" autoFocus={true} type="search" value={titleTF}
                      onChange={e => { setTitleTF(e.target.value) }} />


                    <TouredoDatePicker editDate={startDate} setDate={setStartDate} disabled={!writable} id="editevent-start" label="Start Date" />

{/* At the moment we do not make use of endDate 
                    <TextField id="editevent-end" margin="dense" disabled={!writable}
                     label="End Date" type="search" value={endDate}
                      onChange={e => { setEndDate(e.target.value) }} />
*/}

                    <MultiItemPicker onChange={setVisibilityFromPicker} title="Visibility" disabled={!writable} selected={[visibilityDD]} keyValues={visibilityMap} multiple={false} colonSelectorFormat={true}/>
                    
                    <MultiItemPicker onChange={setCountryCodes} title="Countries" disabled={!writable} selected={countryCodes} keyValues={CountryMap} multiple={true} colonSelectorFormat={true}/>

                    <MultiItemPicker onChange={setProviders} title="Providers" disabled={!writable} selected={providers} keyValues={providerMap} multiple={true}  colonSelectorFormat={false}/>

                      </div>

                <hr/>
            </div>

            </DialogContent>
            <DialogActions>
                {tour && writable && deleteTourCallback && (<Button variant="outlined" color="error" startIcon={<DeleteIcon />}
                  onClick={() => {deleteTourCallback(tour); setModalShow(false); }} >Delete</Button>)}
                {tour && writable && <Button variant="outlined" startIcon={<FileDownloadIcon />} disabled={!writable}
                  onClick={() => {exportTourCallback(tour as Tour); setModalShow(false); }} >Export</Button> }
                {tour && user.loggedIn && <Button variant="outlined" startIcon={<ContentCopyIcon />} 
                  onClick={() => {duplicateTourCallback(tour as Tour); setModalShow(false); }} >Clone</Button> }
                <Button variant="outlined" onClick={() => {setModalShow(false);  } }>Cancel</Button>
                <Button variant="outlined" disabled={!writable} onClick={() => {handleEditTour(tour);  } }>OK</Button>
            </DialogActions>
        </Dialog>

     </>   );
};

export default EditTourDialog;
