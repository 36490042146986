

export const pushLocation = (term: string) : void => {
    localStorage.lastLocation = term;
}

export const lastLocation = () : string => {
    const lastString : string = localStorage.lastLocation;
    return lastString ? lastString : "";
}


export const pushCCs = (CCs: string[]) : void => {
    localStorage.lastCC = CCs && CCs.length > 0 ? CCs[0] : [];
}

export const lastCCs = () : string[] => {
    const lastString : string = localStorage.lastCC;
    return lastString && lastString.length > 0 ? [lastString] : [];
}

export const pushCity = (term: string) : void => {
    localStorage.lastCity = term;
}

export const lastCity = () : string => {
    const lastString : string = localStorage.lastCity;
    return lastString ? lastString : "";
}
