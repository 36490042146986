import React, { ReactElement, useEffect } from "react";

import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

interface EditProps {
    title: string;
    selected: string[];
    keyValues: Map<string,string>;
    multiple: boolean;
    disabled?: boolean;
    colonSelectorFormat: boolean;
    onChange : (selected: string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selectedNames: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selectedNames.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

/* This method is mainly here to handle an edge case where the <Select> component returns [""]  (array with one "empty" element), when nothing is selected. */
const limitToKnown = (given: string[], known: string[]): string[] => {
    const res: string[] = [];
    given.forEach(g => {
        if (known.includes(g)) {
            res.push(g);
        }
    });
    //return given;
    return res;
}

const MultiItemPicker = ({title, selected, keyValues, onChange, multiple, disabled, colonSelectorFormat}: EditProps): ReactElement => {
  const theme = useTheme();
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(limitToKnown(selected, Array.from(keyValues.keys()) ));


  useEffect( () => { console.info("Got it:", selected); setSelectedKeys(limitToKnown(selected, Array.from(keyValues.keys()))); }, [selected, keyValues] );
  
  const handleChange = (event: SelectChangeEvent<typeof selectedKeys>) => {
    const { target: { value } } = event;
    // On autofill we get a stringified value.
    const currentSelection = typeof value === 'string' ? value.split(',') : value;
    setSelectedKeys(currentSelection);
    onChange(currentSelection);
  };

    const handleDelete = (value: string) => {
        const newSelectedKeys = selectedKeys.filter(function(e) { return e !== value });
        setSelectedKeys(newSelectedKeys);
        onChange(newSelectedKeys);
    }


    const renderMenuItems = (keyValues: Map<string,string>) : ReactElement[] => {
        const elems : ReactElement[] = [];
        keyValues.forEach((value, key) => elems.push(
            <MenuItem
              key={value}
              value={key}
              style={getStyles(key, selectedKeys, theme)}
            >
            { colonSelectorFormat ? (key + " : " + value) : value + " (" + key + ")" }
            </MenuItem>
            
            ));

        return elems;
    }
    
    
    
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="multi-item-picker">{title}</InputLabel>
        <Select
          labelId="multi-item-picker"
          id="multi-item-picker"
          multiple={multiple}
          disabled={disabled}
          value={selectedKeys}
          onChange={handleChange}
          input={<OutlinedInput id="multi-item-picker-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                return (
                 multiple ?
                
                    <Chip
                        key={value}
                        label={value}
                        onMouseDown={(event) => {event.stopPropagation()}}
                        onDelete={ e => { handleDelete(value)  } }  />
                        
                    : <Chip key={value} label={value} 
                        onMouseDown={(event) => {event.stopPropagation()}}
                        onDelete={ e => { handleDelete(value)  } }  />
                    )
                }
                
              ) }
            </Box>
          )}
          MenuProps={MenuProps}
        >
        {renderMenuItems(keyValues)}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiItemPicker;

