import { ReactElement, useState, useEffect } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { CountryMap } from "../shared/GeoConstants";
import MultiItemPicker from "../shared/MultiItemPicker";
import TouredoTimePicker from "../shared/TouredoTimePicker";

import * as Config from "../../Config";

import { formatAddressFullWithCountryFlag } from "../shared/Geo";
import { providerRefSerialize} from "../shared/Geo"

import { formatDurationHours, localTimeDeserialize } from "../tools/DateUtil";
import {providerLinkByRefOrCoordinate, osmLatLonLink} from "../shared/Provider"


import {EnrichedUser} from "../shared/Types"
import {LinkType, ProviderRef, Tour, TourEvent, UrlLink} from "../../api"

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./EditEventDialog.module.scss";

interface EditProps {
    user: EnrichedUser;
    tour: Tour;
    tourEvent: TourEvent;
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    editTourEventCallback: (tourEvent : TourEvent ) => void;
    deleteTourEventCallback: (tourEventId : string ) => void;
    duplicateEventCallback: (tourEvent : TourEvent ) => void;
}

const limitToHHMM = (dateString :string): string => {
    if (dateString)
        return dateString.length <= 5 ? dateString : dateString.substring(0,5);
    else
        return "";
}

const EditEventDialog = ({user, tour, tourEvent, modalShow, setModalShow,
    editTourEventCallback, deleteTourEventCallback, duplicateEventCallback}: EditProps): ReactElement => {
    const [arrivalTF, setArrivalTF] = useState<Date | string>(tourEvent.arrival || "");
    const [departureTF, setDepartureTF] = useState<Date | string>(tourEvent.departure || "");
    const [titleTF, setTitleTF] = useState<string>(tourEvent.title || "");


    // The country only contains a single country code (or none). Using an array is more convenient for the MultiItemPicker
    const [country, setCountry] = useState<string[]>(tourEvent.address.country ? [tourEvent.address.country] : []);
    const [city, setCity] = useState<string>(tourEvent.address.city || "");
    const [street, setStreet] = useState<string>(tourEvent.address.street || "");
    const [editAddressActive, setEditAddressActive] = useState<boolean>(false);
    
    const [notesTF, setNotesTF] = useState<string>(tourEvent.notes || "");
    const [costsTF, setCostsTF] = useState<string>(tourEvent.costs || "");
    const [openingHoursTF, setOpeningHoursTF] = useState<string>(tourEvent.openingHours || "");
    
    const [openingHoursURL, setOpeningHoursURL] = useState<string | undefined>(undefined);


    useEffect(() => {
       setArrivalTF(limitToHHMM(tourEvent.arrival as string) || "");
       setDepartureTF(limitToHHMM(tourEvent.departure as string) || "");
       setTitleTF(tourEvent.title || "");
       
       setCountry(tourEvent.address.country ? [tourEvent.address.country] : []);
       setCity(tourEvent.address.city || "");
       setStreet(tourEvent.address.street || "");
       setNotesTF(tourEvent.notes || "");
       setCostsTF(tourEvent.costs || "");
       setOpeningHoursTF(tourEvent.openingHours || "");
       setEditAddressActive(false);
       
       if (tourEvent.providerRef) {
           axios.get<UrlLink>(Config.api_url + "url?linkType=" + LinkType.OpeningHours + "&providerRef=" + providerRefSerialize(tourEvent.providerRef as ProviderRef)).then(response =>
            {
                setOpeningHoursURL(response.data.url);
            },
                    () => {
                        setOpeningHoursURL("");
                        //setErrormsg("Error loading Seasons");
                    });
        }
       
       } , [tourEvent]);


    // add the new TourEvent, and close the Dialog
    const handleEditTour = (tourEvent : TourEvent) => {
        tourEvent.arrival = arrivalTF as string; // or undefined
        tourEvent.departure = departureTF as string; // or undefined
        tourEvent.stayDuration = undefined; // not used at the moment
        tourEvent.title = titleTF;
        
        tourEvent.address.country = country[0] || "";
        tourEvent.address.city = city;
        tourEvent.address.street = street;
        tourEvent.notes = notesTF;
        tourEvent.costs = costsTF;
        tourEvent.openingHours = openingHoursTF;
        editTourEventCallback(tourEvent);
        setModalShow(false);
    }

	const link = providerLinkByRefOrCoordinate(tourEvent.providerRef, tourEvent.geoCoordinate);
    // User owning the tour may write. 
    const writable: boolean = user.userId === tourEvent.userId;
    return (
        <Dialog open={modalShow} PaperProps={{ sx: { minWidth: "30vw", padding:"0px" } }} onClose={() => setModalShow(false)}>
            <DialogTitle>Event in {tourEvent.address.city}</DialogTitle>

                <DialogContent>
                <div className={styles.modalcontent}>
                    <div className={lstyles.dialogcontent} style={{alignItems:"flex-start"}}>
                        <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                         label="Event" autoFocus={true} type="search" value={titleTF}
                          onChange={e => { setTitleTF(e.target.value) }} />


                    {link && (
                        <span className={styles.tourLocation}>
                            For more information
                            {/* Safe link to the provider website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                            : <a href={link}  className={styles.tooltipdark} target="_blank" rel="noopener">open {tourEvent.providerRef?.provider} in new tab</a>
                        </span>
                    )}

                    <table>
                        <tbody>
                        <tr>
                            <td><TouredoTimePicker id="editevent-arrival" label="Arrival" className={lstyles.clockpicker} time={arrivalTF} setTime={setArrivalTF}  disabled={!writable} /></td>
                            <td><TouredoTimePicker id="editevent-departure" label="Departure" className={lstyles.clockpicker} time={departureTF} setTime={setDepartureTF}  disabled={!writable} /></td>
                            <td className={styles.tourLocation}>{formatDurationHours(localTimeDeserialize(arrivalTF as string), localTimeDeserialize(departureTF as string)) }</td>
                            {/** Currently the possibility to edit stayDuration is disabled. We show the duration instead, e.g. "7 hours, 30 minutes"  */}
                        </tr>
                        </tbody>
                    </table>
                </div>


<table className={lstyles.ta}>
<tbody>
    <tr>
    <td width="100%">
        <TextareaAutosize className={lstyles.textarea} minRows={1} placeholder="Notes" value={notesTF} onChange={e => { setNotesTF(e.target.value) }} disabled={!writable} />
    </td>
    </tr>


    <tr>
        <td >
        <TextareaAutosize className={lstyles.textarea} minRows={1} placeholder="Costs" value={costsTF} onChange={e => { setCostsTF(e.target.value) }} disabled={!writable} />
        </td><td> <LinkIcon/> </td>
    </tr>
    
    <tr>
    <td >
     {editAddressActive ?
        (
            <>
                <MultiItemPicker onChange={setCountry} title="Country" selected={country} keyValues={CountryMap} multiple={false} colonSelectorFormat={true}/>
    
                <TextField required id="editevent-city" margin="dense" fullWidth disabled={!writable}
                 label="City" autoFocus={true} type="search" value={city}
                  onChange={e => { setCity(e.target.value) }} />
    
                <TextField required id="editevent-street" margin="dense" fullWidth disabled={!writable}
                 label="Street / freeform address" autoFocus={true} type="search" value={street}
                  onChange={e => { setStreet(e.target.value) }} />
            </>
        )
        :
         <span className={styles.tourLocation}> {formatAddressFullWithCountryFlag({country: country[0] || "", city: city, street: street})} 
            <IconButton color={"info"} className={styles.horButtonsRow} size="small" onClick={() => { setEditAddressActive(true); }}> <EditIcon />  </IconButton >
          </span>
     }
    
    
    </td>
    
    <td>
        {/* Safe link to OpenStreetMap website *//* eslint-disable-next-line react/jsx-no-target-blank */}
        {tourEvent && tourEvent.geoCoordinate && (<a href={osmLatLonLink(tourEvent.geoCoordinate.latitude, tourEvent.geoCoordinate.longitude)}target="_blank" rel="noopener">
        <OpenInNewIcon/> </a> ) }
    </td>
    </tr>

    <tr>
        <td>
        <TextareaAutosize className={lstyles.textarea} minRows={1} placeholder="Opening hours" value={openingHoursTF} onChange={e => { setOpeningHoursTF(e.target.value) }} disabled={!writable} />
        </td><td> 
        { openingHoursURL ? ( 
            <a href={openingHoursURL} target="_blank" rel="noopener">
            <AccessTimeIcon/>
            </a>
            )
            : <></>
            }  
         </td>
    </tr>
</tbody>
</table>

            </div>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" startIcon={<DeleteIcon />} disabled={!writable}
                  onClick={() => {deleteTourEventCallback(tourEvent.uuid as string); setModalShow(false); }} >Delete</Button>
                <Button variant="outlined" startIcon={<ContentCopyIcon />} disabled={!writable}
                  onClick={() => {duplicateEventCallback(tourEvent); setModalShow(false); }} >Clone</Button>
                <Button variant="outlined" onClick={() => {setModalShow(false);  } }>Cancel</Button>
                <Button variant="outlined" disabled={!writable} onClick={() => {handleEditTour(tourEvent);  } }>OK</Button>
            </DialogActions>
        </Dialog>

     );
};

export default EditEventDialog;
