import { ReactElement, useEffect, useState } from "react";

import TextField from '@mui/material/TextField';

import {findLocation} from "../shared/Provider"
import {CoreMarker} from "../shared/Types"
import MultiItemPicker from "../shared/MultiItemPicker";
import { CountryMap } from "../shared/GeoConstants";
import { GeocodedLocation} from "../../api"
import PoiSelector from "../shared/PoiSelector"
import {lastCCs, lastCity, lastLocation, pushCCs, pushCity, pushLocation} from "../Search/SearchTerms"

interface SectionProps {
    addTourEvent: (location : CoreMarker ) => void;
    setErrormsg: (msg: string) => void;
}


const AddEventDialogPoiSection = ({addTourEvent, setErrormsg}: SectionProps): ReactElement => {
    const [locations, setLocations] = useState<GeocodedLocation[]>([]);
    const [locationTF, setLocationTF] = useState<string>(lastLocation());
    const [cityTF, setCityTF] = useState<string>(lastCity());
    const [country, setCountry] = useState<string[]>(lastCCs());

    const updateLocations = (location: string, city: string, country: string[]) : void => {
        if (location.trim() === "" && city.trim() === "" && country.length === 0) {
            setLocations([]); // reset
            return;
        }
        // Do a global search. This is currently independent from map (this means w/o Geo coordinate)
        findLocation(location, city, (country.length === 0 ? "" : country[0]), undefined, undefined).then(response =>
            {
                if (response.error) {
                    setErrormsg("Error searching for locations.");                  
                } else {
                    console.info("updateLocations()", location, city, country);
                    setLocations(response.locations);
                    setErrormsg(""); // clear error
                }
            }
        )
    };
    
    // do  search initially (if search parameters are not empty)
    useEffect(() => {
        updateLocations(locationTF, cityTF, country);
    }, [locationTF, cityTF, country])
    
    return (
        <>
           <p>
            Find an attraction by name, city or country.
            </p>

            <TextField required id="addevent-location"  label="Location" fullWidth autoFocus={true} type="search" value={locationTF}
                 onChange={e => { setLocationTF(e.target.value); pushLocation(e.target.value); updateLocations(e.target.value, cityTF, country); }} />
            <TextField id="addevent-city"  label="City" fullWidth type="search"  value={cityTF}
                 onChange={e => {setCityTF(e.target.value); pushCity(e.target.value); updateLocations(locationTF, e.target.value, country); }} />
            <MultiItemPicker onChange={e => {setCountry(e); pushCCs(e); updateLocations(locationTF, cityTF, e); }} title="Country" selected={country} keyValues={CountryMap} multiple={false} colonSelectorFormat={true}/>
            
            <PoiSelector pristine={locationTF.trim() === ""} selectedHandler={addTourEvent} previewHandler={undefined}
             locations={locations} resultboxVisible={true} countryFilterHandler={  cc => {console.info("cc", cc); setCountry([cc]); updateLocations(locationTF, cityTF, [cc]);} }
             noResultsHelpMessage={<>No Locations found.<br/>Search tips: Remove filters. Switch to the ADDRESS search. If you still cannot find it, add the location manually via CUSTOM.</>}  />


        </>
    );
}

export default AddEventDialogPoiSection;
