import axios, { AxiosInstance } from "axios";

import * as Config from "../Config";

import { ErrorHandler, et500 } from "../components/shared/Types";

import { Bookmark } from "../api";


const axiosClient = (): AxiosInstance => {
    axios.defaults.withCredentials = true;
    return axios;
}

export const insertEntity = (bookmark : Bookmark, callback: ErrorHandler): void => {
    axiosClient().post<Bookmark>(Config.api_url + "bookmark", bookmark
    ).catch(()  => {
        callback(et500("Error adding bookmark"));
    }).finally(callback) ;
};

export const updateEntity = (bookmark : Bookmark, callback: ErrorHandler): void => {
    axiosClient().put<Bookmark>(Config.api_url + "bookmark/"  + bookmark.uuid, bookmark
    ).catch(() => {
        callback(et500("Error saving bookmark"));
    }).finally(callback) ;
};

export const deleteEntity = (bookmark : Bookmark, callback: ErrorHandler): void => {
    axiosClient().delete<Bookmark>(Config.api_url + "bookmark/" + bookmark.uuid
    ).catch(() => {
        callback(et500("Error deleting bookmark"));
    }).finally(callback) ;
};