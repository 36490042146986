import { ReactElement } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DeleteIcon from '@mui/icons-material/Delete';


interface ConfirmDialogProps {
	title: string;
	message: string;
    isDeleteAction: boolean;
    hideCancelAction?: boolean;
    extraElement?: ReactElement;
    reference: object; 
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    confirmedCallback: (reference : object ) => void;
}

const ConfirmDialog = ({title, message, isDeleteAction, hideCancelAction, extraElement, reference, modalShow, setModalShow, confirmedCallback}: ConfirmDialogProps): ReactElement => {

    return (
          <Dialog open={modalShow}
            PaperProps={{ sx: { minWidth: "30vw", padding:"0px" } }}

            onClose={() => setModalShow(false)}>

            <DialogTitle>{title}</DialogTitle>
            
            <DialogContent>
                {message}
                {extraElement && <><br/><br/>{extraElement}</>}
            </DialogContent>

            <DialogActions>
                {isDeleteAction && <Button variant="outlined" color="error" startIcon={<DeleteIcon />}
                  onClick={() => {setModalShow(false); confirmedCallback(reference); }} >Delete</Button>}
                {!hideCancelAction && <Button variant="outlined" onClick={() => {setModalShow(false);  } }>Cancel</Button>}
                {!isDeleteAction && <Button variant="outlined"  onClick={() => {setModalShow(false); confirmedCallback(reference);  } }>OK</Button>}
            </DialogActions>
            </Dialog>
    );
};

export default ConfirmDialog;
