import  dayjs from 'dayjs';
import {Dayjs} from 'dayjs';

import { VisitDate } from "../../api";

export interface LocalTime {
    hour: number;
    minute: number;
}

export const len2 = (s: number) : string => {
    if (s < 10) {
        return "0" + s;
    } else {
        return "" + s;
    }
}

export const len2str = (s: string) : string => {
    if (s.length < 2) {
        return "0" + s;
    } else {
        return s;
    }
}


export const localTimeDeserialize = (time: string): LocalTime =>  {
    const split = time.split(":"); // We accept both HH:MM:SS and HH:MM
    if (split.length !== 2 && split.length !== 3) {
        return {hour: 0, minute: 0};
    } else{
        return {hour: Number(split[0]), minute: Number(split[1])};
    }
}

export const localTimeSerialize = (localtime: LocalTime): string => {
    return len2str(localtime.hour + "") + ":" + len2str(localtime.minute+ "");
}


export const dayDiff = (startDay?: string, endDay?: string) : number => {
	if (startDay && endDay) {
		const date1 = dayjs(startDay);
		const date2 = dayjs(endDay);
		return date2.diff(date1, 'd');
	}
	return 0;
}

export const unixEpochSecondsToDate = (seconds: number) : Dayjs => {
	return dayjs.unix(seconds);
}

export const formatUnixEpochSmartly = (seconds: number) : string => {
    const now = dayjs();
    const when = dayjs.unix(seconds);
    const diffMins = Math.abs(now.diff(when, 'minute'));
    if (diffMins < 1) {
        return "just now";
    } else if (diffMins === 1) {
        return diffMins +" minute ago";
    } else if (diffMins < 120) {
        return diffMins +" minutes ago";
    } else if (diffMins < 720) {
        return when.format("HH:mm");
    } else {
        return when.format("YYYY-MM-DD");
    }    
}

export const formatDurationHours = (from: LocalTime, to: LocalTime) : string => {
    const minuteDiff = 60*(to.hour - from.hour) + (to.minute - from.minute);
    if (minuteDiff <= 0) {
        // Also covers the "undefined" case, which we sometimes lazily convert to hour:0, minute:0
        return "";
    }
    
    const hours = Math.floor(minuteDiff / 60);
    const minutes = minuteDiff - (60*hours);
    
    let result = hours + "";
    if (minutes === 0) {
        result += " hour";
        if (hours !== 1) {
            result += "s";
        }
    } else {
      result += " hours, " + minutes +" minutes";
    }
    return result;
} 

export const parseLocalDate = (localDate: string) : dayjs.Dayjs => {
    return dayjs(localDate, "YYYY-MM-DD");
}

export const formatNowDate = () : string => {
    return dayjs().format("YYYY-MM-DD");
}

export const formatDateYMD = (dayjs: Dayjs) : string => {
    return dayjs.format("YYYY-MM-DD");
}

export const formatYMD_weekday = (timestamp: Dayjs, language?: string) : string => {
    const lang = language ? language : "en";
    return timestamp.toDate().toLocaleDateString(lang, { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' });
}

export const formatYMD_l10n = (timestamp: Dayjs, language?: string) : string => {
    const lang = language ? language : "en";
    return timestamp.toDate().toLocaleDateString(lang, { year: 'numeric', month: 'numeric', day: 'numeric' });
}

export const formatTimeHM = (timeString? : string) : string => {
    if (!timeString) {
        return "";
    }
    if (timeString.length < 6) {
        return timeString;
    } else {
        return timeString.substr(0,5);
    }
};

export const nowToVisitDate = (timestamp: Dayjs) : VisitDate => {
    return {year: timestamp.year(), month:1 + timestamp.month(), day: timestamp.date()};
}

export const nowToLocalTime = (timestamp: Dayjs) : string => {
    return len2(timestamp.hour()) + ":" + len2(timestamp.minute()) + ":"  + len2(timestamp.second());
}

export const now = () : Dayjs => {
    return dayjs();
}


/**
  Returns the IANA time zone as text, e.g. "Europe/Berlin"
 */
export const userTimezone = () : string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
