import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";

import IconButton from '@mui/material/IconButton';

import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';

import {ErrorStatusType, EnrichedUser} from "../shared/Types"
import {User} from "../../api"

import styles from "./NavigationBar.module.scss";

interface NavigationBarProps {
    user: EnrichedUser;
    onUpdateUser: (user: User, status: ErrorStatusType) => void;
    onLoginButton: () => void;
}


const NavigationBar = ({user, onUpdateUser, onLoginButton}: NavigationBarProps): ReactElement => {



return (
    <div className={styles.navcontainer}>

        <nav className={styles.navigation}>
            <NavLink end
                     className={({ isActive }) => (isActive ? styles.navActive : styles.navInactive)}
                     to="/">Touredo</NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? styles.navActive : styles.navInactive)}
                to="/planning">Planning</NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? styles.navActive : styles.navInactive)}
                to="/bookmarks">Bookmarks</NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? styles.navActive : styles.navInactive)}
                to="/diary">Diary</NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? styles.navActive : styles.navInactive)}
                to="/info">About</NavLink>
        </nav>

        <IconButton className={styles.navigation} size="small" onClick={() => { onLoginButton() }}>
            <SearchIcon/>
        </IconButton>

        <span className={styles.navigation} onClick={() => { onLoginButton() }}>
        {user.loggedIn && user.userProfile.publicName ? user.userProfile.publicName : "Account"}
        </span>
        <IconButton className={styles.navigation} size="small" onClick={() => { onLoginButton() }}

        >
          {user.loggedIn ? <PersonIcon style={{ color: "white"  }}/> : <LoginIcon  style={{ color: "white"  }}/>}
          </IconButton >
    </div>
);
};

export default NavigationBar;
