import { ReactElement } from "react";

import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';

import EventToolbar, { EventToolbarCallbacks } from "../Map/EventToolbar";

import { CoreMarker, EnrichedUser } from "../shared/Types";

interface EventToolbarDialogProps {
    user: EnrichedUser;
    location : CoreMarker;
    callbacks : EventToolbarCallbacks;
    withAddress : boolean;
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
}

const EventToolbarDialog = ({user, location, callbacks, withAddress, modalShow, setModalShow }: EventToolbarDialogProps ): ReactElement => {
    return (<>
    {location &&
        <Dialog open={modalShow} PaperProps={{ sx: { minWidth: "30vw", padding:"0px" } }} onClose={() => setModalShow(false)}>
            <DialogTitle>Add Event</DialogTitle>
            <DialogContent>
                <EventToolbar user={user} loc={location} callbacks={callbacks} withAddress={true}/>
            </DialogContent>
        </Dialog>
    };

    </>
    );
};

export default EventToolbarDialog;