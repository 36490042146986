import React, { ReactElement, useContext, useState } from "react";

import {AlertColor} from '@mui/material/Alert';

// We import LocalizationProvider and AdapterDateFns for the MUI date picker
// Reasons for choosing dayjs. It is the smallest Adapter (2k bundle). It supports everything needed for the
// DatePicker. If we need more functionality we can go 3 ways:
// 1. Add compatible dajs plugins for functionality that we may want to have, e.g. "Tour starts in 20 days".
// 2. Switch to date-fns
// 3. Implement missing stuff ourselves (only sensible if it does not require translation)
//import AdapterDayjs from '@mui/lab/AdapterDayjs';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import * as locales from '@mui/material/locale';
//import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';


import {EnrichedUser} from "./components/shared/Types"

import {Bookmark, DiaryEntry, EnrichedTour} from "./api";
import {AppToplevel} from "./AppToplevel";

// Contexts
import {UserContext} from "./contexts/UserContext";
import {BookmarkDialogContext, DiaryDialogContext, NotificationContext} from "./contexts/GlobalDialogContext";


import lstyles from "./App.module.scss";


//const getNavigatorLanguage = () => (navigator.languages || [])[0] || navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
/*
const getNavigatorLanguage = () => (navigator.languages || [])[0] || navigator.language || 'en';
const getNavigatorLocale = (language : string ) => {
    const langLoc = language.trim().split("-");
    if (langLoc.length > 1) {
        return langLoc[1].toUpperCase(); // Locale is upper-case. Fix it for Safari (13.1)
    }
    return langLoc[0].toUpperCase(); // use language in upper case to create a (hopefully valid) locale
}
*/







const App = (): ReactElement => {
    // The useContext() here are only for the initial state
    const userContext = useContext(UserContext);
    const notificationContext = useContext(NotificationContext);
    const bookmarkDialogContext = useContext(BookmarkDialogContext);
    const diaryDialogContext = useContext(DiaryDialogContext);
    // Context state for user and tour
    const [user, setUser] = useState<EnrichedUser>(userContext.user);
    const [currentTour, setCurrentTour] = useState<EnrichedTour | undefined>(userContext.currentTour);


    // Context state for Dialogs, Modals and Popups  
    const [notificationMessage, setNotificationMessage] = useState<string>(notificationContext.message);
    const [notificationSeverity, setNotificationSeverity] = useState<AlertColor>(notificationContext.severity);
    const [notificationShow, setNotificationShow] = useState<boolean>(notificationContext.show);

    const [bookmarkEditor, setBookmarkEditor] = useState<Bookmark | undefined>(bookmarkDialogContext.data);
    const [diaryEditor, setDiaryEditor] = useState<DiaryEntry | undefined>(diaryDialogContext.data);







/*
  const theme = useTheme();

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales.deDE),
    [theme], // This could bew made dependent from user.locale if we add that
  );
*/


//<Route path="/planning"  element={<Plan tours={tours} onUpdateTours={setTours}/>} />
    return (
    <>
    {/*
    // LocalizationProvider : See https://mui.com/components/date-picker/
    // TODO Set user preferred localization, .e.g from navigator.language.
     // Is it a string? See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
     // Or is it an array of strings. See https://phrase.com/blog/posts/detecting-browser-language-preference-with-javascript/
     // Or is it called navigator.languages ? See https://phrase.com/blog/posts/detecting-a-users-locale/
    //  <ThemeProvider theme={themeWithLocale}>
    // <LocalizationProvider locale={locales.deDE} theme={themeWithLocale}>
    */}

<div className={lstyles.toplevel}>

<UserContext.Provider value={ {user: user, setUser: setUser, currentTour: currentTour, setCurrentTour: setCurrentTour} }>
<NotificationContext.Provider value={{
    show: notificationShow, setShow: setNotificationShow, 
    message: notificationMessage, setMessage: setNotificationMessage,
    severity: notificationSeverity, setSeverity: setNotificationSeverity
    } }>

    <BookmarkDialogContext.Provider value={{
        open: setBookmarkEditor,
        hide: () => setBookmarkEditor(undefined),
        data: bookmarkEditor //{uuid: "", userId:1, category: "foo", title:"Juhu", address: {}, liked: true, visited: false, ignored: false, visitPlanned: false, notes: "Bla", atime:0, ctime:0, mtime:0} // bookmarkEditor
    }}>
    
    <DiaryDialogContext.Provider value={{
        open: setDiaryEditor,
        hide: () => setDiaryEditor(undefined),
        data: diaryEditor
    }}>




    <AppToplevel/>
    </DiaryDialogContext.Provider>
    </BookmarkDialogContext.Provider>
        

</NotificationContext.Provider>
</UserContext.Provider>
        
        </div>
    </>
    );
}
export default App;




