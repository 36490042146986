import React, { ReactElement, useContext, useEffect, useMemo, useState} from "react";



import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from "react-leaflet";
import { useMapEvent } from "react-leaflet";
import * as L from 'leaflet';



import EditLocationDialog from "../Location/EditLocationDialog"
import EventToolbar, { EventToolbarCallbacks } from "./EventToolbar"


import { Bookmark, DataQuality, DiaryEntry, Importance, OpeningCalendar, OpeningStatus, TourEvent } from "../../api";
import {GeoCoordinate, Tour, OSMBoundingBox} from "../../api";
import {leafletToModel, Location} from "../shared/Geo";
import {ActivePOIs, CoreMarker, EnrichedUser, ErrorHandler, MapMarker} from "../shared/Types";

import { BookmarkDialogContext, DiaryDialogContext } from '../../contexts/GlobalDialogContext';

import { UserContext } from "../../contexts/UserContext";

import lstyles from "./Map.module.scss";
import './MapMarkers.css';

export interface MapControllerProps {
	mapTrigger: boolean;
	setMapTrigger: (enable: boolean) => void;
	center: L.LatLng;
	zoom: number;
	mapModifiedByUser: (center: GeoCoordinate, boundingBox: OSMBoundingBox) => void;
}

interface MapProps {
    tour: Tour;
    events: TourEvent[];
    mapPois: Location[];
    selectedPoi: CoreMarker | undefined;
    activePOIs: ActivePOIs;
    bookmarks: Bookmark[];
    visited: DiaryEntry[];
    highlightedEvent?: TourEvent;
    addTourEventCallback: (location : CoreMarker) => void;
    errorHandler: ErrorHandler;
    
    // Passed to MapControllerProps.
	mapTrigger: boolean;
	setMapTrigger: (enable: boolean) => void;
	center: L.LatLng;
	zoom: number;
	mapModifiedByUser: (center: GeoCoordinate, boundingBox: OSMBoundingBox) => void;
}

type GeoData = [number, number];  // internal type for lat, lon

enum PoiIcon {
    DEFAULT,
    VISITED,
    BOOKMARK,
    SELECTED_POI,
}

//const limeOptions = { color: 'lime' }
const blueOptions = { color: '#6666ff' }
//const orangeOptions = { color: 'orange' }


const focusIcon = L.icon({
    iconUrl: '/markers/radio-button-on-outline.svg',
    iconSize: [40, 40],
    className: 'highlightMarker'
});

const bookmarkIcon = L.icon({
    iconUrl: '/markers/radio-button-on-outline.svg',
    iconSize: [12, 12],
    className: 'highlightMarker'
});

const visitedIcon = L.icon({
    iconUrl: '/markers/radio-button-on-outline.svg',
    iconSize: [12, 12],
    className: 'poiMarkerGreen'
});


// Theme park icons
const poiIconOsOpen = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [12, 12],
    className: 'poiMarkerGreen',
});

const poiIconOsOpenImportant = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [18, 18],
    className: 'poiMarkerGreen',
});

const poiIconOsOpenMajor = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [24, 24],
    className: 'poiMarkerGreen',
});


const poiIconOsClosed = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [12, 12],
    className: 'poiMarkerRed',
});

const poiIconOsClosedImportant = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [18, 18],
    className: 'poiMarkerRed',
});

const poiIconOsClosedMajor = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [24, 24],
    className: 'poiMarkerRed',
});

const poiIconOsUnknown = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [12, 12],
    className: 'poiMarkerGrey',
});

const poiIconOsUnknownImportant = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [18, 18],
    className: 'poiMarkerGrey',
});

const poiIconOsUnknownMajor = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [24, 24],
    className: 'poiMarkerGrey',
});


const poiIconOsPartial = L.icon({
    iconUrl: '/markers/home.svg',
    iconSize: [12, 12],
    className: 'poiMarkerYellow',
});


// Unesco world heritage icons
const poiUnescoIconOsOpen = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [12, 12],
    className: 'poiMarkerGreen',
});

const poiUnescoIconOsOpenImportant = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [18, 18],
    className: 'poiMarkerGreen',
});

const poiUnescoIconOsOpenMajor = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [24, 24],
    className: 'poiMarkerGreen',
});


const poiUnescoIconOsClosed = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [12, 12],
    className: 'poiMarkerRed',
});

const poiUnescoIconOsClosedImportant = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [18, 18],
    className: 'poiMarkerRed',
});

const poiUnescoIconOsClosedMajor = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [24, 24],
    className: 'poiMarkerRed',
});


const poiUnescoIconOsUnknown = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [12, 12],
    className: 'poiMarkerGrey',
});

const poiUnescoIconOsUnknownImportant = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [18, 18],
    className: 'poiMarkerGrey',
});

const poiUnescoIconOsUnknownMajor = L.icon({
    iconUrl: '/markers/greek-temple.svg',
    iconSize: [24, 24],
    className: 'poiMarkerGrey',
});


const MapController = ({center, zoom, mapTrigger, setMapTrigger, mapModifiedByUser} : MapControllerProps ) : null => {
	const map = useMap();
	//console.log("MapController ENTER: " + mapTrigger);
	if (mapTrigger) {
		// The update was triggered by Touredo => update Leaflet / OSM
	    //console.log("MapController: cneter=" + center + " , zoom=" + zoom);
        map.setView(center, zoom);
		setMapTrigger(false);
	}
		// The update was triggered within Leaflet => update Touredo model
		const map2 = useMapEvent('moveend', () => {
		if (!mapTrigger) {
			const bounds: L.LatLngBounds = map2.getBounds();
			const center = bounds.getCenter();
			const boundingBox: OSMBoundingBox = {east: bounds.getEast(), west: bounds.getWest(), south: bounds.getSouth(), north: bounds.getNorth()};
			//console.log("Map moved: " + center);
			mapModifiedByUser(leafletToModel(center), boundingBox);
		}
	  });

    //console.log("MapController EXIT");
    return null;
};




const geoccordToLeafletGeocoord = (geoCoordinate? : GeoCoordinate) : L.LatLng | undefined => {
    if (geoCoordinate) {
        return geoCoordinate ? L.latLng(geoCoordinate.latitude, geoCoordinate.longitude) : undefined;
    } else {
        return undefined;
    }
}

const eventToGeoccordinate = (event? : TourEvent) : L.LatLng | undefined => {
    return event ? geoccordToLeafletGeocoord(event.geoCoordinate) : undefined;
}


/*
const OS_CLOSED : OpeningStatus = OpeningStatus.CLOSED;

const renderCalendarDay = (openingDays : { [index: string]: OpeningPerDay }, defaultOpening: OpeningStatus): ReactElement[] => {
	const elems : ReactElement[] = [];
	
	// Note: openingPerDay is strangely not a Map, but an Object that contains something that looks verys much like a Map.
	// One can access it like this: calendar.openingDays["2022-04-04"]
	// And not like this          : calendar.openingDays.get("2022-04-04")
	// Probably we need to convert the Location Response from the backend when we load it.
	
	const openingDaysAsMap : Map<string, OpeningPerDay> = new Map(Object.entries(openingDays));
						
	openingDaysAsMap.forEach( (value, key, openingPerDay) => {
		let openingHours;
		if (defaultOpening.valueOf() !== value.openingStatus.valueOf()) {
            // We only show something if it differs from the default (which is shown by the caller of this method)
        		if (value.openingStatus.valueOf() !== OS_CLOSED.valueOf()) {
                    openingHours = value.openingHours ? ":" + value.openingHours.opens + "-" + value.openingHours.closes : "";
            } else {
    			openingHours = ": CLOSED";
    	       	}
    		      elems.push(<div>{key}{openingHours}</div>);
            }
	});
	if (elems.length > 0) {
            elems.unshift(<div>Opening days:</div>);
    }
	return elems;
};
*/






const determineOpening = (calendar?: OpeningCalendar) : OpeningStatus => {
	if (calendar) {
		return calendar.opened;
	}
	return OpeningStatus.PARTIAL;
}

const determineIcon = (loc: CoreMarker, poiIcon: PoiIcon) : L.Icon => {
    switch (poiIcon) {
        case PoiIcon.VISITED: return visitedIcon;
        case PoiIcon.BOOKMARK: return bookmarkIcon;
        case PoiIcon.SELECTED_POI: return focusIcon;
    }
    
    // poiIcon === DEFAULT
	const providerRef = loc.providerRef;
	const os : OpeningStatus = determineOpening(loc.calendar);
	if (providerRef) {
		if (providerRef.provider === "unesco-whc") {
            if (os === OpeningStatus.OPEN || os === OpeningStatus.PARTIAL) {
                if (loc.importance === Importance.MAJOR) {
                    return poiUnescoIconOsOpenMajor;
                } else if (loc.importance === Importance.NORMAL) {
                    return poiUnescoIconOsOpenImportant;
                } 
                return poiUnescoIconOsOpen;
            }
            if (os === OpeningStatus.CLOSED) {
                if (loc.importance === Importance.MAJOR) {
                    return poiUnescoIconOsClosedMajor;
                } else if (loc.importance === Importance.NORMAL) {
                    return poiUnescoIconOsClosedImportant;
                } 
                return poiUnescoIconOsClosed;
            }
            if (os === OpeningStatus.UNKNOWN) {
                if (loc.importance === Importance.MAJOR) {
                    return poiUnescoIconOsUnknownMajor;
                } else if (loc.importance === Importance.NORMAL) {
                    return poiUnescoIconOsUnknownImportant;
                } 
                return poiUnescoIconOsUnknown;
            }
		}
	}
	
	if (os === OpeningStatus.OPEN || os === OpeningStatus.PARTIAL) {
        if (loc.importance === Importance.MAJOR) {
            return poiIconOsOpenMajor;
        } else if (loc.importance === Importance.NORMAL) {
            return poiIconOsOpenImportant;
        } 
		return poiIconOsOpen;
	}
	if (os === OpeningStatus.CLOSED) {
        if (loc.importance === Importance.MAJOR) {
            return poiIconOsClosedMajor;
        } else if (loc.importance === Importance.NORMAL) {
            return poiIconOsClosedImportant;
        } 
		return poiIconOsClosed;
	}
    if (os === OpeningStatus.UNKNOWN) {
        if (loc.importance === Importance.MAJOR) {
            return poiIconOsUnknownMajor;
        } else if (loc.importance === Importance.NORMAL) {
            return poiIconOsUnknownImportant;
        } 
        return poiIconOsUnknown;
    }
	return poiIconOsPartial;
}

const addPoiMarker = (loc : CoreMarker, index: number,
  user: EnrichedUser,
  callbacks: EventToolbarCallbacks,  
  poiIcon: PoiIcon
  ) : ReactElement => {
	const coords = geoccordToLeafletGeocoord(loc.geoCoordinate);
	const icon = determineIcon(loc, poiIcon);
	//console.log("Add POI marker in city " + address.city + " at coordinate " + coords + " with icon " + icon);
	 
	return ( <React.Fragment key={index}>
		{coords && (
			<Marker key="1" zIndexOffset={8} position={coords as L.LatLng} icon={icon}>
        	    		<Popup >
                    <EventToolbar user={user} loc={loc} callbacks={callbacks}  withAddress={true}/>
                </Popup>
            </Marker> )
            }
        
    </React.Fragment> )
};

    function bookmarkToLocation(bookmark : Bookmark) : CoreMarker {
			const loc : CoreMarker = {
				name: bookmark.title,
				address: bookmark.address,
				geoCoordinate: bookmark.geoCoordinate as GeoCoordinate,
				providerRef: bookmark.providerRef,
				calendar: {from: "1970-01-01", to:"1970-01-01", opened:OpeningStatus.CLOSED, openingDays:{}, quality:DataQuality.Ruleset, openingHoursMinimum:{open:false, dataQuality:DataQuality.Ruleset}, openingHoursMaximum:{open:false, dataQuality:DataQuality.Ruleset} }
		     };
		     // TODO bookmarks currently lack calendar and seasons
		
		return loc;
    }
    
    function diaryEntryToLocation(diaryEntry: DiaryEntry): CoreMarker {
            const loc : CoreMarker = {
                name: diaryEntry.title,
                address: diaryEntry.address,
                geoCoordinate: diaryEntry.geoCoordinate as GeoCoordinate,
                providerRef: diaryEntry.providerRef,
                calendar: undefined
             };
        
        return loc;
    }
    
	function bookMarkerElements(
	  bookmarx : Bookmark[], 
   	  user: EnrichedUser,
   	  tour: Tour,
      callbacks: EventToolbarCallbacks,  
    	) : ReactElement[] {
		return (
			bookmarx.map((bm, index) => {
             return bm.providerRef === undefined ? <></> :  bm.geoCoordinate ? (
			<span key={index}>
		        {addPoiMarker(bookmarkToLocation(bm), index, user, callbacks, PoiIcon.BOOKMARK)}
		    </span>
		    )
		    : <React.Fragment key={index}/>;  // Bookmarks w/o GeoCoordinate will not be shown on the Map
		    }
		));
    }
    
    function visitedMarkerElements(
        visited: DiaryEntry[],
        user: EnrichedUser,
        callbacks: EventToolbarCallbacks          
        ) : ReactElement[] {
        return (
            visited.map((bm, index) => {
             return bm.providerRef === undefined ? <></> :  bm.geoCoordinate ? (
            <span key={index}>
                {addPoiMarker(diaryEntryToLocation(bm), index, user, callbacks, PoiIcon.VISITED)}
            </span>
            )
            : <React.Fragment key={index}/>;  // Bookmarks w/o GeoCoordinate will not be shown on the Map
            }
        ));
    }


	function poiMarkerElement(
		location: CoreMarker,
		index: number,
   	  user: EnrichedUser,
      callbacks: EventToolbarCallbacks,  
      useIconForSelectedPoi: boolean
		) : ReactElement {
            const loc : MapMarker = {
                name: location.name,
                address: location.address,
                geoCoordinate: location.geoCoordinate as GeoCoordinate,
                providerRef: location.providerRef,
                calendar: location.calendar,
                importance: location.importance,
                attractions: location.attractions
             };
		return <span key={index}>
		        {addPoiMarker(loc, index, user, callbacks, useIconForSelectedPoi ? PoiIcon.SELECTED_POI : PoiIcon.DEFAULT)}
		    </span>;
	}
	
	
	function poiMarkerElements(
	  locs : Location[], 
   	  user: EnrichedUser,
      callbacks: EventToolbarCallbacks
    	) : ReactElement[] {
		return (
			locs.map((loc, index) => (
			   poiMarkerElement(loc, index, user, callbacks, false)
		)));
    }



const OsmMap = ({center, zoom,
	mapTrigger, setMapTrigger, mapModifiedByUser,
	tour, events, highlightedEvent, mapPois, selectedPoi, bookmarks, visited, activePOIs,
	addTourEventCallback, errorHandler}  : MapProps): ReactElement => {
   
   const userContext = useContext(UserContext);
   const user = userContext.user;
        
    const [positions, setPositions] = useState<GeoData[]>();
    const [poiMarkerCache, setPoiMarkerCache] = useState<ReactElement[]>([]);
    const [bookMarkerCache, setBookMarkerCache] = useState<ReactElement[]>([]);
    const [locationToEdit, setLocationToEdit] = useState<CoreMarker | undefined>(undefined);
    const [locationDialog, setLocationDialog] = useState<boolean>(false);

    const bookmarkDialogContext = useContext(BookmarkDialogContext);
    const diaryDialogContext = useContext(DiaryDialogContext);

    
    const editLocation =  (loc: CoreMarker) : void => {
        setLocationToEdit(loc);
        setLocationDialog(true);
    }
    


    const callbacks : EventToolbarCallbacks = {
        openBookmarkDialog : bookmarkDialogContext.open,
        addTourEvent : addTourEventCallback,
        openLocationEditorDialog : editLocation,
        openDiaryDialog : diaryDialogContext.open
    };

    const visitedCache = useMemo<ReactElement[]>(() => visitedMarkerElements(visited, user, callbacks), [visited, user, addTourEventCallback, bookmarkDialogContext.open, diaryDialogContext.open]);


    useEffect(() => {
    const callbacks : EventToolbarCallbacks = {
        openBookmarkDialog : bookmarkDialogContext.open,
        addTourEvent : addTourEventCallback,
        openLocationEditorDialog : editLocation,
        openDiaryDialog : diaryDialogContext.open
    };

       setPoiMarkerCache(poiMarkerElements(mapPois, user, callbacks));
    } , [mapPois, user, tour, addTourEventCallback, bookmarkDialogContext.open, diaryDialogContext.open]);


    useEffect(() => {
        const callbacks : EventToolbarCallbacks = {
            openBookmarkDialog : bookmarkDialogContext.open,
            addTourEvent : addTourEventCallback,
            openLocationEditorDialog : editLocation,
            openDiaryDialog : diaryDialogContext.open
        };

       setBookMarkerCache(bookMarkerElements(bookmarks, user, tour, callbacks));
    } , [bookmarks, visited, user, tour, addTourEventCallback, bookmarkDialogContext.open, diaryDialogContext.open]);


    const createPolyline = (te : TourEvent[]) : GeoData[] => {
        let polyline: GeoData[] = [];
        for (let index = 0; index < te.length; index++) {
          let coord = te[index].geoCoordinate;
          if (coord) {
            let point : GeoData = [coord.latitude, coord.longitude];
            polyline.push(point);
          }
        }
        return polyline;
    }

    useEffect(() => {
       setPositions(createPolyline(events));
    } , [events]);




/* Add a Marker for one TourEvent, that also has a Popup. There is a zIndexOffset on these markers, so they go on
   top of the highlightedEvent marker. By this, the event remains clickable to show the Popup. */
const addMarker = (te : TourEvent, user: EnrichedUser, index: number, length: number) : ReactElement => {
    if (te.geoCoordinate === undefined) {
        return <React.Fragment key={index}/>
    }
    
    const marker : MapMarker = {geoCoordinate: te.geoCoordinate as GeoCoordinate, name: te.title,  address: te.address, providerRef: te.providerRef, tourEventId: te.uuid};
    
    const callbacks : EventToolbarCallbacks = {
        openBookmarkDialog : bookmarkDialogContext.open,
        addTourEvent : addTourEventCallback,
        openLocationEditorDialog : editLocation,
        openDiaryDialog : diaryDialogContext.open
    };
    
    return addPoiMarker(marker, index, user, callbacks, PoiIcon.DEFAULT);
};

const addMarkers = (tes : TourEvent[], user: EnrichedUser) : ReactElement[] => (
    tes.map((te, index) =>  (
        <span key={index}>
            {addMarker(te, user, index, tes.length)}
        </span>
    ))
);



const highlightedEventGeo = eventToGeoccordinate(highlightedEvent);


    


return (
<>
 <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
   integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
   crossOrigin=""/>

 <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
   integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
   crossOrigin=""></script>

<MapContainer className={lstyles.map}  scrollWheelZoom={true} style={{ height: "100%", minHeight: "100%", width: "100%", minWidth:"100%" }}>
  <MapController center={center} zoom={zoom} mapTrigger={mapTrigger} setMapTrigger={setMapTrigger} mapModifiedByUser={mapModifiedByUser} />
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://tiles.touredo.org/{z}/{x}/{y}.png"
  />
       
  {highlightedEventGeo ? <Marker position={highlightedEventGeo} icon={focusIcon}/> :"" }
  {positions && activePOIs.tourevents ? addMarkers(events, user) : ""}

  {poiMarkerCache !== undefined ? poiMarkerCache : ""}
  
  {selectedPoi !== undefined ? poiMarkerElement(selectedPoi, 0, user, callbacks, true) : ""}

  {bookMarkerCache !== undefined && activePOIs.bookmarks ? bookMarkerCache : ""}
  
  {visitedCache.length > 0 && visitedCache}


  {activePOIs.tourevents && <Polyline pathOptions={blueOptions} positions={positions as [number, number][]} />}

</MapContainer>

{ locationDialog && locationToEdit && locationToEdit.providerRef && // Edit only allowed, if this represents a known ProviderRef
     <EditLocationDialog location={locationToEdit} modalShow={locationDialog} setModalShow={setLocationDialog} user={user}
     /> }
     
     

</>
)
};

export default OsmMap;

