/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.36.1070 on 2024-02-27 00:07:01.

export interface Token {
    expired: boolean;
    admin: boolean;
    approver: boolean;
}

export interface ApiError {
    message: string;
    errorCode: string;
    requestid: string;
}

export interface SearchResult<T> extends Comparable<SearchResult<T>> {
    result: T;
    matchQuality: number;
}

export interface Tour {
    uuid: string;
    name: string;
    userId: number;
    visibility: Visibility;
    start: DateAsString;
    end: DateAsString;
    countryCodes: string[];
    providers: string[];
    atime: number;
    ctime: number;
    mtime: number;
}

export interface TourResponse {
    tours: EnrichedTour[];
    publicTours: EnrichedTour[];
}

export interface Activity {
    providerRef: ProviderRef;
    name: string;
    description: string;
    operatingStatus: OperatingStatus;
}

export interface Bookmark {
    uuid: string;
    userId: number;
    category: string;
    title: string;
    providerRef?: ProviderRef;
    address: Address;
    geoCoordinate?: GeoCoordinate;
    notes: string;
    liked: boolean;
    visited: boolean;
    visitPlanned: boolean;
    ignored: boolean;
    atime: number;
    ctime: number;
    mtime: number;
}

export interface DiaryEntry {
    uuid: string;
    userId: number;
    toureventUuid?: string;
    category: string;
    title: string;
    notes: string;
    providerRef?: ProviderRef;
    visitDate: VisitDate;
    visitTime: DateAsString;
    timeZoneOffsetSeconds?: number;
    timeZoneName?: string;
    geoCoordinate?: GeoCoordinate;
    address: Address;
    ctime: number;
    mtime: number;
}

export interface VisitDate {
    year?: number;
    month?: number;
    day?: number;
}

export interface TourEvent {
    uuid: string;
    tourUuid: string;
    userId: number;
    day: number;
    eventOrder: number;
    eventType: EventType;
    arrival?: DateAsString;
    stayDuration?: number;
    stayPadding?: StayPadding;
    departure?: DateAsString;
    title: string;
    providerRef?: ProviderRef;
    address: Address;
    geoCoordinate?: GeoCoordinate;
    notes?: string;
    costs?: string;
    openingHours?: string;
}

export interface TourEventSortByTourOrder extends Comparator<TourEvent> {
}

export interface Address {
    country?: string;
    city?: string;
    postcode?: string;
    street?: string;
}

export interface GeocodedLocation extends Comparable<GeocodedLocation> {
    address: Address;
    osmCityProviderRef?: string;
    providerRef: ProviderRef;
    name: string;
    geoCoordinate?: GeoCoordinate;
    boundingBox?: OSMBoundingBox;
    attractions: Activity[];
    importance: Importance;
    operatingStatus: OperatingStatus;
    operating: boolean;
}

export interface ISO3166Country {
    countryName: string;
    alpha2: string;
    alpha3: string;
    isoCC: number;
    subRegion: string;
}

export interface ProviderRef extends Comparable<ProviderRef> {
    provider: string;
    id: string;
}

export interface ImporterStatistics {
    imported: number;
    ignored: number;
    failed: number;
    duplicate: number;
}

export interface City extends GeocodedLocation {
    provider: string;
}

export interface LocationResponse extends GeocodedLocation {
    calendar: OpeningCalendar;
}

export interface Park extends GeocodedLocation {
    provider: string;
}

export interface SearchGeocodedLocation extends GeocodedLocation {
}

export interface UnescoWorldHertiage extends GeocodedLocation {
    endangered: boolean;
    category: string;
    provider: string;
}

export interface OpeningCalendar {
    from: DateAsString;
    to: DateAsString;
    opened: OpeningStatus;
    quality: DataQuality;
    openingHoursMinimum: OpeningHours;
    openingHoursMaximum: OpeningHours;
    openingDays: { [index: string]: OpeningPerDay };
}

export interface OpeningHours {
    opens?: DateAsString;
    closes?: DateAsString;
    open: boolean;
    dataQuality: DataQuality;
}

export interface OpeningNews {
    recently: OpeningNewsEntry[];
    soon: OpeningNewsEntry[];
}

export interface OpeningNewsEntry {
    openingDate: DateAsString;
    providerRef: string;
    cc: string;
    city: string;
    name: string;
}

export interface OpeningPerDay {
    openingStatus: OpeningStatus;
    openingHours: OpeningHours;
    quality: DataQuality;
}

export interface OpeningPerWeekday {
    standard: OpeningHours;
    weekdays: { [P in DayOfWeek]?: OpeningHours };
}

export interface OpeningPeriod {
    uuid: string;
    userId: number;
    providerRef: ProviderRef;
    type: OpeningPeriodType;
    from: DateAsString;
    to?: DateAsString;
    quality: DataQuality;
    description: string;
    weekdays: OpeningPerWeekday;
    mtime: number;
    confirmUserId?: number;
    confirmTime?: number;
}

export interface OpeningSeasons {
    seasons: OpeningPeriod[];
    exceptions: OpeningPeriod[];
    providerRef: ProviderRef;
}

export interface OpeningSummary {
    providerRef: ProviderRef;
    openToday: OpeningPerDay;
    openTomorrow: OpeningPerDay;
    nextOpenDay: DateAsString;
    openNext: OpeningPerDay;
}

export interface OmniSearchEntry {
    title: string;
    cc: string;
    city: string;
    providerRef: ProviderRef;
}

export interface EnrichedTour {
    tour: Tour;
    metadata: TourMetadata;
}

export interface TourMetadata {
    center: GeoCoordinate;
    boundingBox: OSMBoundingBox;
    distance: number;
    distanceUnit: string;
    owner: Owner;
    accessLevel: AccessLevel;
    writable: boolean;
}

export interface UrlLink {
    providerRef: ProviderRef;
    linkType: LinkType;
    url: string;
    languageCode: string;
    revision: number;
    reviewer: number;
    reviewTimestamp: number;
    ctime: number;
}

export interface AccountMetadata {
    atime: number;
    ctime: number;
    mtime: number;
    loginCount: number;
}

export interface User {
    userId?: number;
    accountStatus: AccountStatus;
    userProfile: UserProfile;
    metadata: AccountMetadata;
}

export interface UserIdentity {
    loginName: string;
    emailAddress: string;
    confirmationCode: string;
}

export interface UserProfile {
    publicName: string;
    language: string;
    countryCode: string;
    homeAddress: Address;
}

export interface UserRegistration {
    userIdentity: UserIdentity;
    userProfile: UserProfile;
    password: string;
}

export interface AuthResponse {
    user: User;
}

export interface RegisterResponse {
    user: User;
    confirmationCode: string;
}

export interface GeoCoordinate {
    latitude: number;
    longitude: number;
    geoPrecision: GeoPrecision;
}

export interface OSMBoundingBox extends Serializable {
    north: number;
    west: number;
    east: number;
    south: number;
}

export interface Comparable<T> {
}

export interface Comparator<T> {
}

export interface Serializable {
}

export type DateAsString = string;

export enum Owner {
    User = "User",
    Other = "Other",
    OtherPublic = "OtherPublic",
    NoSuchEntity = "NoSuchEntity",
}

export enum Visibility {
    Private = "Private",
    Public = "Public",
    Deleted = "Deleted",
}

export enum Importance {
    MINOR = "MINOR",
    NORMAL = "NORMAL",
    MAJOR = "MAJOR",
    OUTSTANDING = "OUTSTANDING",
}

export enum DetailType {
    TITLE = "TITLE",
    DESCRIPTION = "DESCRIPTION",
    COSTS = "COSTS",
    OPENING = "OPENING",
}

export enum EventType {
    DEPARTURE = "DEPARTURE",
    STAY = "STAY",
    OVERNIGHT_STAY = "OVERNIGHT_STAY",
    HOTEL_CHECK_IN = "HOTEL_CHECK_IN",
    HOTEL_CHECK_OUT = "HOTEL_CHECK_OUT",
}

export enum StayPadding {
    None = "None",
    Low = "Low",
    High = "High",
}

export enum DataQuality {
    Contributed = "Contributed",
    Imported = "Imported",
    Verified = "Verified",
    Derived = "Derived",
    Ruleset = "Ruleset",
}

export enum OpeningPeriodType {
    SEASON = "SEASON",
    EXCEPTION = "EXCEPTION",
}

export enum OpeningStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    PARTIAL = "PARTIAL",
    UNKNOWN = "UNKNOWN",
}

export enum OperatingStatus {
    OPERATING = "OPERATING",
    UC = "UC",
    SBNO = "SBNO",
    STORED = "STORED",
    CLOSED = "CLOSED",
}

export enum LinkType {
    Homepage = "Homepage",
    OpeningHours = "OpeningHours",
    Costs = "Costs",
    Other = "Other",
}

export enum AccessLevel {
    NONE = "NONE",
    READ = "READ",
    WRITE = "WRITE",
}

export enum AccountStatus {
    Guest = "Guest",
    Unconfirmed = "Unconfirmed",
    User = "User",
    Suspended = "Suspended",
    Deleted = "Deleted",
}

export enum DayOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

export enum GeoPrecision {
    None = "None",
    Continent = "Continent",
    Country = "Country",
    City = "City",
    Exact = "Exact",
}
