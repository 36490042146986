import React, { ReactElement, useState } from "react";

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
//import DragHandleIcon from '@mui/icons-material/DragHandle';

import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';

import { AccessLevel, EnrichedTour, TourEvent } from "../../api";
import { formatTimeHM } from "../tools/DateUtil";
import {TourEventAnalysis } from "../tools/TourAnalyzer";
import EventActionMenu, { Action } from "./EventActionMenu";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./TourCard.module.scss";

interface TourCardProps {
    tourEvent: TourEvent;
    eTour: EnrichedTour;
    moveEventUp?: (tourEvent: TourEvent) => void;
    moveEventDown?: (tourEvent: TourEvent) => void;
    onAction: (action: Action, tourEventId: string) => void;
    notifyCardSelected: (tourEvent : TourEvent) => void;
    onClick: () => void;
    tourEventAnalysis? : TourEventAnalysis;
}


const TourCard = ({tourEvent, eTour, onClick, moveEventUp, moveEventDown, onAction, notifyCardSelected, tourEventAnalysis}: TourCardProps): ReactElement => {
  const [shadow, setShadow] = useState<number>(1);
  const [colo, setColo] = useState<string>("yellow");

// potentially use onMouseenter
  const onMouseOver = () => { setShadow(0); setColo("red"); notifyCardSelected(tourEvent) }
  const onMouseOut = () => { setShadow(24); setColo("blue"); }


const tea = tourEventAnalysis;
const startHasProblem = tea && (tea.startsBeforeFormerEventEnds || tea.startsBeforeLocationOpens);
const endHasProblem = tea && tea.departureBeforeArrival;
const eventProblemCount = (startHasProblem ? 1 : 0)  + (endHasProblem ? 1 : 0);
const startsBeforeFormerEventEnds = tea && tea.startsBeforeFormerEventEnds;

const writable = eTour.metadata.accessLevel ===  AccessLevel.WRITE;

return (
    <div className={lstyles.horizontal} >


<div style={{display: "grid"  , position: "relative", width:"100%" }}>

    <Badge sx={{ position: "absolute", left:0, zIndex:"999"}}
        badgeContent={startsBeforeFormerEventEnds ? " " : 0} color="error" />

    <Paper elevation={shadow}
        variant="elevation"
        
        onClick={() => onClick()}
        className={lstyles.cardpaper}
        style={  colo==="red" ? { background: "#e99"} : {background : "#fff"  } }
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
    >

    <div style={{ position: "absolute", zIndex:"999", right:13, top:-1 }}>
    <Badge badgeContent={eventProblemCount} color="error" />
         </div>


        <div className={lstyles.carddetail}>
            <div className={lstyles.verticalList}>
               <span className={lstyles.arrival} style={{color: startHasProblem ? 'red' : 'black' , fontWeight: startHasProblem ? 'bold' : 'normal' }}>{formatTimeHM(tourEvent.arrival)}
               </span>

               <span className={styles.tourLocation}> {tourEvent.title}  </span>
               <span className={styles.tourDetails}>  {tourEvent.address?.city}   </span>
               <span className={lstyles.departure}  style={{color: endHasProblem ? 'red' : 'black' , fontWeight: endHasProblem ? 'bold' : 'normal'}}>{formatTimeHM(tourEvent.departure)}</span>
            </div>
        </div>
    </Paper>
</div>

       {writable ?
       <div className={lstyles.vertical}>
       { moveEventUp &&
            <ArrowCircleUpIcon  fontSize="small" onClick={() => { moveEventUp(tourEvent);}} />
            }
            <EventActionMenu onAction={onAction} tourEventId={tourEvent.uuid as string}
               actionJoinFormerDay={tourEventAnalysis ? tourEventAnalysis.isFirstEventOfDay && !tourEventAnalysis.isFirstEventOfTour : false}
               actionStartNewDay={tourEventAnalysis ? !tourEventAnalysis.isFirstEventOfDay : false}
               />
            { moveEventDown &&
            <ArrowCircleDownIcon fontSize="small" onClick={() => { moveEventDown(tourEvent);}} />
            }
       </div>
       : ""
       }
   </div>
 );

};

export default TourCard;