import React, { ReactElement, useContext, useState } from "react";
import * as L from 'leaflet';


import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import AttractionsIcon from '@mui/icons-material/Attractions';
import AttractionsOutlinedIcon from '@mui/icons-material/AttractionsOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ListIcon from '@mui/icons-material/List';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';

import {Bookmark, DiaryEntry, GeoCoordinate, OSMBoundingBox, Tour, TourEvent} from "../../api"
import {Location, leafletToModel, modelToLeaflet} from "../shared/Geo"
import PoiSelector from "../shared/PoiSelector"

import {ActivePOIs, CoreMarker, ErrorHandler, ErrorStatusType, PoiType} from "../shared/Types"
import OsmMap from "../Map/Map"
import {findLocation} from "../shared/Provider"

import { UserContext } from "../../contexts/UserContext";

import { EventToolbarCallbacks } from "../Map/EventToolbar";
import EventToolbarDialog from "../Map/EventToolbarDialog";

import lstyles from "./Plan.module.scss";
import { lastLocation, pushLocation } from "../Search/SearchTerms";


interface PlanProps {
	tour: Tour;
	tourEvents: TourEvent[];
	bookmarks: Bookmark[];
	visited: DiaryEntry[];
	mapPois: Location[];
	
	activePOIs: ActivePOIs;
	resultboxVisible: boolean;
	setResultboxVisible: (trigger: boolean) => void;
	changePoiActiveState: (poiType: PoiType, newState: boolean) => void;
	
	highlightedEvent: TourEvent | undefined;
	setHighlightedEvent: (highlightedEvent: TourEvent | undefined) => void;	
	
    handleAddTourEvent: (location : CoreMarker) => void;
    setModalAddEventShow : (show: boolean) => void;

    switchViewIcon: boolean;
    switchView: () => void;
    
    errorHandler: ErrorHandler;
	
	// map
	mapCenter: L.LatLng;
	setMapCenter: (center: L.LatLng) => void;
	mapZoom: number;
	mapTrigger: boolean;
	setMapTrigger: (trigger: boolean) => void;
	mapBoundingBox: OSMBoundingBox;
	setMapBoundingBox: (mapBoundingBox: OSMBoundingBox) => void;
}


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const PlanningMap = ({ tour, tourEvents, bookmarks, visited, mapPois, resultboxVisible, setResultboxVisible, highlightedEvent, setHighlightedEvent,
   mapCenter, setMapCenter, mapZoom, mapTrigger, setMapTrigger, mapBoundingBox, setMapBoundingBox,
   changePoiActiveState, handleAddTourEvent, setModalAddEventShow, activePOIs, errorHandler, switchViewIcon, switchView }: PlanProps): ReactElement => {

   const userContext = useContext(UserContext);


	// The state of the search box
	const [searchTerm, setSearchTerm] = useState<string>(lastLocation());
    const [locationFromSearchResult, setLocationFromSearchResult] = useState<CoreMarker>();
    const [countryCode, setCountryCode] = useState<string>(""); 
    // The POI's to display in the search box
    const [locations, setLocations] = useState<Location[]>([]);
    const [locationToAdd, setLocationToAdd] = useState<CoreMarker>();
    
    
    const closeAddDialogAndAddTourEvent = (loc: CoreMarker) => {
        setLocationToAdd(undefined);
        handleAddTourEvent(loc);
    }
    
    const callbacks : EventToolbarCallbacks = {
        openBookmarkDialog : undefined,
        addTourEvent : closeAddDialogAndAddTourEvent,
        openLocationEditorDialog : undefined,
        openDiaryDialog : undefined
    };

    const countryFilterHandler = (countryCodeArg : string) => {
       setCountryCode(countryCodeArg);
        if (countryCodeArg !== countryCode) {
            // Only update locations if the countryCode has actually been changed. This check prevents calling updateLocations() twice, when hen editing the saearch term: Onc via onChange() and once via the onKeyUp()
		    updateLocations(searchTerm, countryCodeArg);
	  }	
	}

    const updateLocations = (searchTerm: string, countryCode: string) : void => {
		findLocation(searchTerm, "", countryCode, leafletToModel(mapCenter), mapBoundingBox).then(response =>
			{
				if (response.error) {
	            	errorHandler({source:undefined, status:ErrorStatusType.SERVER_ERROR, message:"POI search failed"});					
				} else {
	                setLocations(response.locations);
	                errorHandler(undefined); // clear error
	            }
            }
        )
    };


    const hideOrShowPoiSearchResult = (key : string): void => {
		if (key === "Escape") {
			if (resultboxVisible) {
				setResultboxVisible(false); // 1st "Escape" -> hide search result box
			} else {
				setLocationFromSearchResult(undefined);  // 2nd "Escape" -> hide marker on map
			}
		} else {
			setResultboxVisible(true);
		}
		// On ANY key press: Reset the country filter, so we do not "lock ourselves out".
		// Example: A user clicks "FR" to filter for France, then clicks on the "x" to reset the filter.
		// This will result in doing a new search - if this search yields only results from France, the "FR"
		// filter is activated again and we are locked into "FR".
		countryFilterHandler("");
		setHighlightedEvent(undefined);
    }


	const updateMapMetadataFromOsmMap = (center: GeoCoordinate, boundingBox: OSMBoundingBox): void  => {
		setMapCenter(modelToLeaflet(center));
		setMapBoundingBox(boundingBox);
	}

    const previewLocation = (location : CoreMarker) => {
        setLocationFromSearchResult(location);
    }

	return (
		<>
		{/* map */}
			{/* the map itself */}
			<OsmMap center={mapCenter} zoom={mapZoom}
			mapPois={mapPois}
			bookmarks={bookmarks}
			visited={visited}
			selectedPoi={locationFromSearchResult}
			activePOIs={activePOIs}
			mapTrigger={mapTrigger} setMapTrigger={setMapTrigger}
			mapModifiedByUser={updateMapMetadataFromOsmMap}
			tour={tour} events={tourEvents} highlightedEvent={highlightedEvent}
			addTourEventCallback={handleAddTourEvent}
			errorHandler={errorHandler}
			/>

		{/* The POI bar on the map */}		
		<div className={lstyles.poiBar}>
		<Checkbox {...label} className={lstyles.poiBarCheckbox} icon={<AccountBalanceOutlinedIcon />} checkedIcon={<AccountBalanceIcon />} checked={activePOIs.unescoWhc} onChange={(event) => changePoiActiveState(PoiType.UNESCO_WHC, event.target.checked)} />
		<Checkbox {...label} className={lstyles.poiBarCheckbox} icon={<AttractionsOutlinedIcon />}    checkedIcon={<AttractionsIcon />}    checked={activePOIs.rcdb} onChange={(event) => changePoiActiveState(PoiType.RCDB, event.target.checked)}/>
		<Checkbox {...label} className={lstyles.poiBarCheckbox} icon={<BookmarkBorderIcon />}    checkedIcon={<BookmarkIcon />}    checked={activePOIs.bookmarks} disabled={!userContext.user.loggedIn} onChange={(event) => changePoiActiveState(PoiType.BOOKMARK, event.target.checked)}/>
		<Checkbox {...label} className={lstyles.poiBarCheckbox} icon={<LocationOffIcon />}    checkedIcon={<LocationOnIcon />}    checked={activePOIs.tourevents} onChange={(event) => changePoiActiveState(PoiType.TOUREVENT, event.target.checked)}/>
        <Checkbox {...label} className={lstyles.poiBarCheckbox} icon={<BookmarkAddedOutlinedIcon />}    checkedIcon={<BookmarkAddedIcon />}    checked={activePOIs.visited} onChange={(event) => changePoiActiveState(PoiType.VISITED, event.target.checked)}/>
		
		

		<Button size="small" color="secondary" variant="outlined" onClick={() => {} }> <SettingsIcon /> </Button>


        {/* Move this switch-back-to--list-view button to a more appropriate place */}
        {switchViewIcon && <> &nbsp; <Button color="secondary" variant="contained" size="small" onClick={() => { switchView(); }}> <ListIcon/>  </Button > </> }

		
		{userContext.user.loggedIn && (<span className={lstyles.poiBarMessage}>
		    {/* Attantion. When opneing the result box via ArrowDropUpOutlinedIcon an explicit search must be done.
		         Reason: When freshly opening the tour, no search was done, but a search term may be pre-filled from localstorage */}
			<TextField className={lstyles.poiBarMessage} value={searchTerm} label="Location Search"
			  InputProps={{endAdornment: (<InputAdornment position="start"><IconButton edge="end"
			      onClick={() => {
                             if (!resultboxVisible) { updateLocations(searchTerm, countryCode); } // It was NOT visible BEFORE the click.
                            setResultboxVisible(!resultboxVisible);
                  }}> {resultboxVisible ? <ArrowDropDownOutlinedIcon /> : <ArrowDropUpOutlinedIcon/> } </IconButton></InputAdornment>)}}
			  onKeyUp={e => hideOrShowPoiSearchResult(e.key)}
			  onChange={e => { setSearchTerm(e.target.value); setHighlightedEvent(undefined); updateLocations(e.target.value, countryCode); pushLocation(e.target.value)}} />

            {/* TODO: The PoiSelector must be on the "next line". The br tag is a hack, that can fail on different browsers */}
            <br/>

			<PoiSelector pristine={searchTerm.trim() === ""} selectedHandler={setLocationToAdd} previewHandler={previewLocation}
			locations={locations}  resultboxVisible={resultboxVisible} countryFilterHandler={countryFilterHandler}
            noResultsHelpMessage={<>No Locations found. You can use the &nbsp;
            <Button size="small" variant="outlined" disabled={!userContext.user.loggedIn} hidden={false} onClick={() => setModalAddEventShow(true) } endIcon=<AddLocationIcon />>  advanced search</Button>
                </>}  />
			</span>)
		}

        {/* The "guest" teaser */}
        {!userContext.user.loggedIn && (
        <div className={lstyles.teaserNote}>
        You are not logged in. Login to search for locations. 
        </div>
        )}
        {userContext.user.loggedIn && tour.userId !== userContext.user.userId && (
        <div className={lstyles.readonlyNote}>
        You can freely explore this shared tour. If you like it, create a personal copy. 
        </div>
        )}

		</div>
		
		
		{locationToAdd && <EventToolbarDialog user={userContext.user} location={locationToAdd} callbacks={callbacks} withAddress={true} modalShow={true} setModalShow={() => {setLocationToAdd(undefined)}} /> }

	</>
		
	);
};

export default PlanningMap;