import { ReactElement, useContext, useState, useEffect } from "react";
import  { useParams, useNavigate  } from "react-router-dom";
import {EnrichedTour} from "../../api"

import PlanList from "./PlanList"
import PlanEdit from "./PlanEdit"

import {UserContext} from "../../contexts/UserContext";

interface PlanProps {
    openLoginDialog: () => void;
    switchBackToTourList: boolean;
}

const Plan = ({openLoginDialog, switchBackToTourList}: PlanProps): ReactElement => {
    const userContext = useContext(UserContext); // We will need this for currentTour
    const eTour: EnrichedTour | undefined = userContext.currentTour;

    const [tourUuidPathParam, setTourUuidPathParam] = useState<string>();

    const params = useParams();
    const navigate = useNavigate();
  

	useEffect(() => {
        setTourUuidPathParam(params.tourid);
	}, [params.tourid]);

    const switchToEditTour = (etour : EnrichedTour) : void => {
        userContext.setCurrentTour(etour);
		const nextURL = '/tour/' + etour.tour.uuid;
		const nextTitle = 'Edit tour: ' + etour.tour.name;
		const nextState = { action: 'Edit ' + etour.tour.uuid };
		window.history.pushState(nextState, nextTitle, nextURL);
		navigate(nextURL);
    }

    const switchToTourList = () : void => {
	    setTourUuidPathParam(undefined);
        userContext.setCurrentTour(undefined);
    		const nextURL = '/planning';
		const nextTitle = 'Your tours';
		const nextState = { action: 'Tour list'};
		window.history.pushState(nextState, nextTitle, nextURL);
		navigate(nextURL);
    }

    useEffect(() => {
        if (switchBackToTourList) {
            switchToTourList();
        }
    }, [switchBackToTourList]);

    return (
        <>

            {eTour ?
             <PlanEdit etour={eTour} refreshEtour={userContext.setCurrentTour} openTourList={switchToTourList} openLoginDialog={openLoginDialog} />
             :
             <PlanList tourUuid={tourUuidPathParam} openTourEditor={switchToEditTour} openLoginDialog={openLoginDialog}/>
             }
        </>
    );
};


export default Plan;
