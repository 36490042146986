import React, { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AddIcon from '@mui/icons-material/Add';

import * as Config from "../../Config";
import { DiaryEntry } from "../../api";
import { insertEntity, updateEntity, deleteEntity} from "../../httpclient/DiaryEntryDAO";
import EditDiaryEntryDialog from "./EditDiaryEntryDialog";
import { len2, userTimezone } from "../tools/DateUtil";
import { providerLinkByRefOrCoordinate } from "../shared/Provider";
import { formatAddressNoCountry } from "../shared/Geo";
import FilterBar, {FilterParameter} from "../shared/FilterBar";

import { useViewport } from "../../components/tools/ViewportHook";

import {UserContext} from "../../contexts/UserContext";

import {flagImages} from "../tools/Assets";

import FileUpload from"./FileUpload";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./Diary.module.scss";

interface DiaryProps {
    openLoginDialog: () => void;
}

interface TableArgs {
    visit: DiaryEntry;
    localTimeZoneName: string;
    isSmallWidth: boolean;
    showEditor: (visit: DiaryEntry) => void;
}

const formatVisitTime = (visit: DiaryEntry, localTimeZoneName: string) : string => {
    //const withTimeZone : boolean = visit.timeZoneName !== localTimeZoneName;
    const vd = visit.visitDate;
    //const vt = visit.visitTime;
    
    let msg : string = "";
    if (vd.year != null) {
        msg += vd.year;
        if (vd.month != null) {
            msg += "-" + len2(vd.month);
            if (vd.day != null) {
                msg += "-" + len2(vd.day);
            }
        }
    }
    
    return msg;
} 

const applyFilters = (rawEntries: DiaryEntry[], filters: FilterParameter) : DiaryEntry[] => {
    const entries : DiaryEntry[] = [];
    const st : string = filters.searchTerm.toLocaleLowerCase();
    const ccs = filters.countryCodesSelected;

    for (const bm of rawEntries) {
        if (!bm.title.toLocaleLowerCase().includes(st) && !bm.notes.toLocaleLowerCase().includes(st) && !bm.address.city?.toLocaleLowerCase().includes(st)) {
            continue;
        }
        if (ccs && ccs.length > 0) {
            // Country code filter. Skip. if bookmark does not contain a CC, or the CC does not match the filter.
            if (!bm.address.country || !ccs.includes(bm.address.country)) {
                continue;
            }
        }
        entries.push(bm);
    }

    return entries;
}

const DiaryTableRow = ({visit, localTimeZoneName, isSmallWidth, showEditor}: TableArgs) :  ReactElement => {
        return (
            <>
            <TableRow
                  hover  sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor: 'pointer' }}
                  onClick={() => { showEditor(visit); }  }
                >

                    <TableCell component="td" scope="row">
                    {visit.address.country && (flagImages([visit.address.country], 2))}
                    </TableCell>
                    <TableCell component="td" scope="row"> {formatAddressNoCountry(visit.address)}      </TableCell>
                    {!isSmallWidth &&
                    <TableCell component="td" scope="row"> {formatVisitTime(visit, localTimeZoneName)}      </TableCell>
                    }
                    <TableCell component="td" scope="row"> <a href={providerLinkByRefOrCoordinate(visit.providerRef, visit.geoCoordinate)} target="_blank" rel="noopener noreferrer"> {visit.title} </a>    </TableCell>
            </TableRow>
            { visit.notes.length  > 0 &&
            <TableRow>
                <TableCell colSpan={9} component="td" scope="row"> {visit.notes}   </TableCell>
            </TableRow>
            }
            </>

    )};

    const renderAsTable = (rows: DiaryEntry[], localTimeZoneName: string, isSmallWidth: boolean, showEditor: (visit: DiaryEntry) => void  ): ReactElement[] => (
        rows.map((row, index) => (
            <React.Fragment key={index}>
                <DiaryTableRow visit={row}  localTimeZoneName={localTimeZoneName} isSmallWidth={isSmallWidth} showEditor={showEditor}  />
            </React.Fragment>
        ))
    );

const Diary = ({openLoginDialog}: DiaryProps): ReactElement => {
    const userContext = useContext(UserContext);
    const user = userContext.user;
    
    const [errormsg, setErrormsg] = useState<string>("");
    const [visited, setVisited] = useState<DiaryEntry[]>([]);
    const [filters, setFilters] = useState<FilterParameter>({countryCodesChoices: [], countryCodesSelected: [], searchTerm: ""});
    const [filteredDiary, setFilteredDiary] = useState<DiaryEntry[]>([]);
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [showImporter, setShowImporter] = useState<boolean>(false);
    const [selectedEntity, setSelectedEntity] = useState<DiaryEntry | undefined>(undefined);
    const { isSmallWidth } = useViewport();

    
    const loadData = () : void => {
        const url = Config.api_url + "diary";

        if (user.loggedIn) {
        axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
    
        axios.get<DiaryEntry[]>(url).then(response => {
            const ccs = new Set<string>();
            response.data.forEach(b => {
                if (b.address.country) {
                    ccs.add(b.address.country);
                }
            })

            setVisited(response.data);
            const newFilters : FilterParameter = {...filters, countryCodesChoices: Array.from(ccs)};
            setFilters(newFilters);
            setFilteredDiary(response.data); // Initially no filters. No need to apply them.

            setErrormsg("");
            })
            .catch((error) => {
                setVisited([]);
                setErrormsg("Failed loading diary");
            });
        };
    };
    
    useEffect(loadData, [user]); // Note: If "filters" dependency is added, the bookmarks reload infinitely
    
    const setShowEditorForVisit = (visit: DiaryEntry): void => {
        setSelectedEntity(visit);
        setShowEditor(true);
    }
    
    const handleInsert = (visit : DiaryEntry): void => {
        setErrormsg("");
        insertEntity(visit, loadData)
    };
        
    const handleUpdate = (visit : DiaryEntry): void => {
        setErrormsg("");
        updateEntity(visit, loadData);
    };

    const handleDelete = (visit : DiaryEntry): void => {
        setErrormsg("");
        deleteEntity(visit, loadData);
    };

        
        
	return (
        <>
        <div className={styles.verticalList}>
            { (errormsg) && <div className={styles.errormsg}> {errormsg} </div> }
            
            {user.loggedIn ? (<>
                 <span className={styles.tourTitle} >Your Diary{user.userProfile.publicName && ",  " + user.userProfile.publicName}</span>
                 <div><Button color="primary"  variant="outlined"  startIcon={<AddIcon />}
                        onClick={() => {  setSelectedEntity(undefined); setShowEditor(true); }  } >Add new diary entry</Button>
                        
                    <Button variant="outlined"  startIcon={<AddIcon />}
                        onClick={() => {  setSelectedEntity(undefined); setShowImporter(true); }  } >Import</Button>
                        </div>
                        

                 <div style ={{marginBottom:"10px"}}/>
                    <div className={lstyles.list}>
                        <FilterBar filters={filters} onChange={ newFilters => {setFilters(newFilters); setFilteredDiary(applyFilters(visited, newFilters));} }  />

                        {filteredDiary && filteredDiary.length ?
                         (
        
                            <TableContainer component={Paper}>
                              <Table stickyHeader size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    {/*<TableCell>Category</TableCell> */}
                                    <TableCell></TableCell>
                                    <TableCell><b>Address</b></TableCell>
                                    {/*<TableCell>Ignored</TableCell> */}
                                    {/*<TableCell>Visited</TableCell> */}
                                    {!isSmallWidth && 
                                    <TableCell><b>Date</b></TableCell>
                                    }
                                    <TableCell><b>Location</b></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
        
                         {renderAsTable(filteredDiary, userTimezone(), isSmallWidth, setShowEditorForVisit )}

                        </TableBody>
                      </Table>
                    </TableContainer> )
                  : <> { visited &&  visited.length > 0
                       ? "All diary entries filtered."
                       : <>Your diary is empty.  <br/> When you finish a tour, your diary gets updated. <br/>Also you can add entries manually from the tour editor.</>

                   }</>
                }

   
            </div>
                 
                 </>
                 ) : (
                <>
                    <span className={styles.tourTitle} >
                        Welcome guest
                
                    <div><Button color="primary"  variant="outlined"  startIcon={<AddIcon />}
                      onClick={() => {openLoginDialog(); }} >Login</Button> to access your diary</div>
                    </span>
            </>
            )
        }        
        </div>
            {showEditor && <EditDiaryEntryDialog
               user={user}
               diaryEntry={selectedEntity}
               modalShow={showEditor}
               hide={() => setShowEditor(false)}
               editCallback={handleUpdate}
               insertCallback={handleInsert}
               deleteCallback={handleDelete}
           />
           }
           
           {showImporter && (
               <Dialog open={showImporter}  PaperProps={{ sx: { minWidth: "30vw", padding:"0px" } }}

              onClose={() => setShowImporter(false)}>
                               <DialogTitle>Import Diary from Google Timeline</DialogTitle>

                <DialogContent>
               <FileUpload/>
                                           </DialogContent>
                            <DialogActions>
<Button variant="outlined" onClick={() => {setShowImporter(false);  } }>Close</Button>

                           </DialogActions>
        </Dialog>
               )
           }
		</>
	);
};

export default Diary;