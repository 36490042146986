import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./InfoBox.module.scss";

export interface InfoBoxContent {
    title: string;
    info: string;
    color: string;
    link?: string;
    linktext?: string;
    topElement?: ReactElement;
    midElement?: ReactElement; 
    bottomElement?: ReactElement;
};

const InfoBox = ({title, info, color, link, linktext, topElement, midElement, bottomElement}: InfoBoxContent) : ReactElement => {
    return (
        <div className={lstyles.Infocolumn} style={{backgroundColor: color}}>
            <div className={lstyles.InfocolumnHead}> {title} </div>
            <div className={lstyles.InfocolumnMsg}>
                { topElement && topElement}
                <p>{info}</p>
                { midElement && midElement}
                {link && <NavLink className={({ isActive }) => (isActive ? styles.tooltip : styles.tooltip)} to={link}>{linktext}</NavLink> }
                { bottomElement && bottomElement}
           </div>
        </div>
    );
}

export default InfoBox;
