import { ReactElement } from "react";

import TouredoTimePicker from "../shared/TouredoTimePicker";

import lstyles from "./TimeRangePicker.module.scss";



export interface TimeRangeProps {
    from: string; // Format: "HH:mm" or ""
    to: string;   // Format: "HH:mm" or ""
}

/** The OpeningPicker allows to edit opening hours for a single DayOfWeek */
interface EditProps {
    range: TimeRangeProps;
    writable: boolean;
	onUpdate: (timeRange: TimeRangeProps) => void;
    className: string;
}



const limitToHHMM = (dateString :string): string => {
    if (dateString)
        return dateString.length <= 5 ? dateString : dateString.substring(0,5);
    else
        return "";
}


/**
     The TimeRangePicker allow to edit a time span in LocalTime. Invariants: from <= to. The highest time span is 00:00 - 23:59 
*/ 
const TimeRangePicker = ({range, writable, onUpdate, className}: EditProps): ReactElement => {
    return (
        <div className={className}>
		<div className={lstyles.container}>
            <TouredoTimePicker id="range-from" small className={lstyles.clockpicker} label="Opens" time={limitToHHMM(range.from)} disabled={!writable}
                setTime={time => onUpdate( {...range,  from:limitToHHMM(time) } ) } />
            
            &nbsp;
            <TouredoTimePicker id="range-to"   small className={lstyles.clockpicker} label="Closes" time={limitToHHMM(range.to)} disabled={!writable}
                 setTime={time => onUpdate( {...range,  to:limitToHHMM(time)   } ) } />

            
		</div>
		</div>
   );
};

export default TimeRangePicker;
