import React, { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AddIcon from '@mui/icons-material/Add';

import * as Config from "../../Config";

import { Bookmark } from "../../api";
import { providerLinkByRefOrCoordinate } from "../shared/Provider";
import { formatAddressNoCountry } from "../shared/Geo";
import {flagImages} from "../tools/Assets";
import EditBookmarkDialog from "./EditBookmarkDialog";
import { insertEntity, updateEntity, deleteEntity} from "../../httpclient/BookmarkDAO";
import FilterBar, {FilterParameter} from "../shared/FilterBar";

import { useViewport } from "../../components/tools/ViewportHook";

import {UserContext} from "../../contexts/UserContext";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./Bookmarks.module.scss";

interface BookmarksProps {
    openLoginDialog: () => void;
}

const applyFilters = (rawBookmarks: Bookmark[], filters: FilterParameter) : Bookmark[] => {
    const bookmarks : Bookmark[] = [];
    const st : string = filters.searchTerm.toLocaleLowerCase();
    const ccs = filters.countryCodesSelected;
    
    for (const bm of rawBookmarks) {
        if (!bm.title.toLocaleLowerCase().includes(st) && !bm.notes.toLocaleLowerCase().includes(st) && !bm.address.city?.toLocaleLowerCase().includes(st)) {
            continue;            
        }
        if (ccs && ccs.length > 0) {
            // Country code filter. Skip. if bookmark does not contain a CC, or the CC does not match the filter.
            if (!bm.address.country || !ccs.includes(bm.address.country)) {
                continue;
            }
        }
        bookmarks.push(bm);
    }
 
    return bookmarks;
}


const Bookmarks = ({openLoginDialog}: BookmarksProps): ReactElement => {
    const userContext = useContext(UserContext);
    const user = userContext.user;

    
	const [rawBookmarks, setRawBookmarks] = useState<Bookmark[]>([]);
    //const [hiddenBookmarks, setHiddenBookmarks] = useState<Bookmark[]>([]);
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [selectedBookmark, setSelectedBookmark] = useState<Bookmark | undefined>(undefined);
    const [errormsg, setErrormsg] = useState<string>("");
    
    const [filters, setFilters] = useState<FilterParameter>({countryCodesChoices: [], countryCodesSelected: [], searchTerm: ""});
    const [filteredBookmarks, setFilteredBookmarks] = useState<Bookmark[]>([]);
    const { isSmallWidth } = useViewport();

	
	
    const loadData = () : void => {
        if (user.loggedIn) {
        axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        axios.get<Bookmark[]>(Config.api_url + "bookmark").then(response =>
            {
                const failure = response.status >= 300;
                if (failure) {
                    setFilteredBookmarks([]);
                    setErrormsg("Failed loading Bookmarks");
                    
                } else {
                const likedOrPlanned : Bookmark[] = [];
                const hidden : Bookmark[] = [];
                const ccs = new Set<string>();
                response.data.forEach(b => {
                    if (b.ignored) {
                        hidden.push(b);
                    } else if (b.liked || b.visitPlanned) {
                        likedOrPlanned.push(b);
                    }
                    if (b.address.country) {
                        ccs.add(b.address.country);
                    }
                })
                setRawBookmarks(likedOrPlanned);
                const newFilters : FilterParameter = {...filters, countryCodesChoices: Array.from(ccs)};
                setFilters(newFilters);
                setFilteredBookmarks(likedOrPlanned); // Initially no filters. No need to apply them.
                //setHidden(hidden);

                setErrormsg("");
                }
            })
            .catch(() => {
                setFilteredBookmarks([]);
                setErrormsg("Failed loading Bookmarks");
            });
        }
    };

    const handleInsertBookmark = (bookmark : Bookmark): void => {
        setErrormsg("");
        insertEntity(bookmark, loadData);
    };
    
    const handleUpdateBookmark = (bookmark : Bookmark): void => {
        setErrormsg("");
        updateEntity(bookmark, loadData);
    };

    const handleDeleteBookmark = (bookmark : Bookmark): void => {
        setErrormsg("");
        deleteEntity(bookmark, loadData);
    };

    useEffect(loadData, [user]); // Note: If "filters" dependency is added, the bookmarks reload infinitely





interface BookmarkTableArgs {
    bookmark: Bookmark;
    isSmallWidth: boolean;
    keyNumber: number;
}

const limitLength = (msg: string, maxLength: number): string => {
    if (msg.length <= maxLength) {
        return msg;
    }
    return msg.substring(0, maxLength) + "\u2026";
}

const BookmarkTableRow = ({bookmark, isSmallWidth, keyNumber}: BookmarkTableArgs) :  ReactElement => {
        return (
            <>
            <TableRow
                  hover  sx={{ '&:last-child td, &:last-child th': { border: 0 }  , cursor: 'pointer' }}
                  onClick={() => { setShowEditor(true); setSelectedBookmark(bookmark); }  }
                >

                    <TableCell component="td" scope="row">
                    {bookmark.address.country && (flagImages([bookmark.address.country], 2))}
                    </TableCell>
                    <TableCell component="td" scope="row"> {formatAddressNoCountry(bookmark.address)}      </TableCell>
                    <TableCell component="td" scope="row"> {bookmark.visitPlanned && bookmark.mtime && "x"}      </TableCell>
                    <TableCell component="td" scope="row"> <a href={providerLinkByRefOrCoordinate(bookmark.providerRef, bookmark.geoCoordinate)} target="_blank" rel="noopener noreferrer"> {bookmark.title} </a>    </TableCell>
            </TableRow>
            { bookmark.notes.length > 0 && 
            <TableRow>
                <TableCell colSpan={9} component="td" scope="row"> {limitLength(bookmark.notes, 100)}   </TableCell>
            </TableRow>
            }
            </>
       
    )};
  
    const renderAsTable = (rows: Bookmark[], isSmallWidth: boolean): ReactElement[] => (
        rows.map((row, index) => (
            <React.Fragment key={index}>
                <BookmarkTableRow bookmark={row} isSmallWidth={isSmallWidth} keyNumber={index}/>
            </React.Fragment>
        ))
    );
    
	return (
        <>
		<div className={styles.verticalList}>
            { (errormsg) && <div className={styles.errormsg}> {errormsg} </div> }
            
            {user.loggedIn ? (<>
			     <span className={styles.tourTitle} >Your Bookmarks{user.userProfile.publicName && ",  " + user.userProfile.publicName}</span>
                   <div><Button color="primary"  variant="outlined"  startIcon={<AddIcon />}
                          onClick={() => {  setSelectedBookmark(undefined); setShowEditor(true); }  } >Add new bookmark</Button></div>


			{/* Next step: Add sorting (e.g. by "modified") */}
            <div style ={{marginBottom:"10px"}}/>
            <div className={lstyles.list}>
                <FilterBar filters={filters} onChange={ newFilters => {setFilters(newFilters); setFilteredBookmarks(applyFilters(rawBookmarks, newFilters));} }  />
            
                {/** Future improvement: Possibly show bookmark on the Map (e.g. on a related or the last opened Tour) */}
                {filteredBookmarks && filteredBookmarks.length ?
                 (
                    <TableContainer component={Paper}>
                      <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {/*<TableCell>Category</TableCell> */}
                            <TableCell></TableCell>
                            <TableCell><b>Address</b></TableCell>
                            {/*<TableCell>Ignored</TableCell> */}
                            {/*<TableCell>Visited</TableCell> */}
                            <TableCell><b>Planned</b></TableCell>
                            <TableCell><b>Location</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                        {renderAsTable(filteredBookmarks, isSmallWidth)}

                        </TableBody>
                      </Table>
                    </TableContainer> )
                  :
                  
                    <>{rawBookmarks &&  rawBookmarks.length > 0 ? "All bookmarks filtered." : "You have no bookmarks. Add bookmarks from the tour editor." }</>
                }

            </div>

            </>
        ) : (
             <>
                <span className={styles.tourTitle} >
                Welcome guest
                
                <div><Button color="primary"  variant="outlined"  startIcon={<AddIcon />}
                      onClick={() => {openLoginDialog(); }} >Login</Button> to record places you want to visit</div>
                </span>
            </>
            )
        }        
		</div>
	    {showEditor && <EditBookmarkDialog
	           user={user}
	           bookmark={selectedBookmark}
	           modalShow={showEditor}
	           hide={() => setShowEditor(false)}
	           editCallback={handleUpdateBookmark}
	           insertCallback={handleInsertBookmark}
	           deleteCallback={handleDeleteBookmark}
           />
       }
		</>
		
	);
};

export default Bookmarks;
