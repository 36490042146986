import { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import CrossfadeImg from 'react-crossfade-img';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {flagImages} from "../tools/Assets";

import { UserContext } from "../../contexts/UserContext";

import * as Config from "../../Config";
import {EnrichedTour, OpeningNews, OpeningNewsEntry, TourResponse} from "../../api"

import InfoBox from './InfoBox';
import { formatYMD_l10n, parseLocalDate } from '../tools/DateUtil';

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./Home.module.scss";


/*
    "DALL·E 2023-02-22 22.45.57 - a green furry world map with indiana jones dotted lines.png",
    "DALL·E 2023-02-22 22.49.23 - a green world map with indiana jones plane lines.png",
    "DALL·E 2023-02-22 22.45.47 - a green furry world map with indiana jones dotted lines.png",
 */
const titleImages : string[] = [
    "DALL·E 2022-12-02 22.18.29 - an oil painting of a rollercoaster.png",
    "DALL·E 2022-12-02 22.26.22 - van gogh style of rollercoaster ridden by mona lisa.png",
    
    "DALL·E 2022-12-02 22.00.20 - an oil painting of a rollercoaster.png",
    "DALL·E 2022-12-02 22.00.36 - rollercoaster with firefly spaceship.png",
    "DALL·E 2022-12-02 22.00.44 - an oil painting of a rollercoaster.png",
    "DALL·E 2022-12-02 22.00.47 - an oil painting of a rollercoaster.png",
    "DALL·E 2022-12-02 22.00.50 - an oil painting of a rollercoaster.png",
    "DALL·E 2022-12-02 22.01.45 - an oil painting of a ferris wheel.png",
    "DALL·E 2022-12-02 22.01.48 - an oil painting of a ferris wheel.png",
    "DALL·E 2022-12-02 22.01.52 - an oil painting of a ferris wheel.png",
    "DALL·E 2022-12-02 22.02.47 - an oil painting of a theme park with alligators.png",
    "DALL·E 2022-12-02 22.02.52 - an oil painting of a theme park with alligators.png",
    "DALL·E 2022-12-02 22.02.54 - an oil painting of a theme park with alligators.png",
    "DALL·E 2022-12-02 22.02.57 - an oil painting of a theme park with alligators.png",
    "DALL·E 2022-12-02 22.03.56 - a stained glass image of a log flume.png",
    "DALL·E 2022-12-02 22.04.01 - a stained glass image of a log flume.png",
    "DALL·E 2022-12-02 22.04.04 - a stained glass image of a log flume.png",
    "DALL·E 2022-12-02 22.07.22 - expressive painting of Bumper cars.png",
    "DALL·E 2022-12-02 22.09.37 - futuristic rollercoaster flying over the himalaya.png",
    "DALL·E 2022-12-02 22.09.42 - futuristic rollercoaster flying over the himalaya.png",
    "DALL·E 2022-12-02 22.11.24 - cybepunk rollercoaster runneling matterhorn.png",
    "DALL·E 2022-12-02 22.11.31 - cybepunk rollercoaster runneling matterhorn.png",


    "DALL·E 2022-12-02 22.13.07 - pencil and watercolor monestary world heritage unesco.png",
    "DALL·E 2022-12-02 22.13.10 - pencil and watercolor monestary world heritage unesco.png",
    "DALL·E 2022-12-02 22.13.12 - pencil and watercolor monestary world heritage unesco.png",
    "DALL·E 2022-12-02 22.14.42 - pencil and watercolor colosseum ancient rome.png",
    "DALL·E 2022-12-02 22.15.29 - pencil and watercolor pillars ancient greece.png",
    "DALL·E 2022-12-02 22.15.35 - pencil and watercolor pillars ancient greece.png",
    
    "DALL·E 2022-12-02 22.26.40 - van gogh style of rollercoaster ridden by mona lisa.png",

    "DALL·E 2022-12-02 22.30.10 - van gogh style of theme park.png",
    "DALL·E 2022-12-02 22.30.12 - van gogh style of theme park.png",
    "DALL·E 2022-12-02 22.30.16 - van gogh style of theme park.png",
    
    "DALL·E 2022-12-02 22.31.14 - stained glass painting of a sherpa at a tent site.png",
    "DALL·E 2022-12-02 22.31.16 - stained glass painting of a sherpa at a tent site.png",

    "DALL·E 2022-12-02 22.32.23 - rollercoaster circling around köner dom.png",

    "DALL·E 2022-12-02 22.45.10 - oil pastel of ancient athens.png",
    "DALL·E 2022-12-02 22.45.14 - oil pastel of ancient athens.png",
    "DALL·E 2022-12-02 22.45.17 - oil pastel of ancient athens.png",
    "DALL·E 2022-12-02 22.45.19 - oil pastel of ancient athens.png"
];

const nextTitleImage = () : string => {
    const titleimage = localStorage.titleimage;
    const nextIndex = titleimage === undefined ? 0 : Math.floor(Math.random() * titleImages.length);
    localStorage.titleimage = "random";
    return titleImages[nextIndex];
}



const simpleNavLink = (link: string, linktext: string): ReactElement => {
    return (
        <NavLink className={({ isActive }) => (isActive ? styles.tooltip : styles.tooltip)} to={link}>{linktext}</NavLink>
    )
}

const Home = (): ReactElement => {
    /* Note: The links in the footer work, but the Browseer may not jump to the fragment. Reason is that the DOM
   gets asynchronously rendered by React and the fragment is simply not yet present when the
   Browser wants to scroll to it. Solution: Add a library that handles it, like
   https://github.com/rafgraph/react-router-hash-link . Until including it, the user must
   manually scroll to the fragment. */
   
   const userContext = useContext(UserContext);

   const devMode = false;
    //const [imageIndex, setImageIndex] = useState<number>(0);
    const [image, setImage] = useState<string>(nextTitleImage());
    const [ownTours, setOwnTours] = useState<EnrichedTour[]>([]);
    const [news, setNews] = useState<OpeningNews>({recently:[], soon:[]});

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        axios.get<TourResponse>(Config.api_url + "tours?count=1").then(response =>
        {
            const loadedTours = response.data.tours;
            setOwnTours(loadedTours);
        });
        
        axios.get<OpeningNews>(Config.api_url + "calendar/recent").then(response =>
        {
            const news = response.data;
            setNews(news);
        });

        const interval = setInterval(() => {
            setImage(nextTitleImage());
            /*
            if (imageIndex  >= titleImages.length-1) {
                setImageIndex(0);
            } else {
                setImageIndex(imageIndex => imageIndex+1 );
            }
            */
            //console.info("New image: ", imageIndex);
       }, devMode ? 2500 : 13000);
       return () => clearInterval(interval);
     }, [devMode, userContext]);
   
   
   
   
       const renderAsTable = (newsList: OpeningNewsEntry[], postfix: string): ReactElement[] => (
        newsList.map((news, index) => (
            <TableRow
                      key={index} hover  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => {} }>

                    <TableCell component="td" scope="row"> {formatYMD_l10n(parseLocalDate(news.openingDate))}   </TableCell>
                    <TableCell component="td" scope="row"> {news.cc && flagImages([news.cc], 1)  } {news.name + " in " + news.city + " " + postfix}      </TableCell>
            </TableRow>
        ))
    );
       const renderNews = (newsList: OpeningNewsEntry[], title: string, postfix: string): ReactElement => {
        return (
            <span style={{backgroundColor: "red"}}>
            {title}
                {newsList && newsList.length?
                 (

                    <TableContainer component={Paper} style={{backgroundColor: "#667788"}}>
                      <Table stickyHeader size="small" aria-label="simple table">
                        <TableBody>

                            {renderAsTable(newsList, postfix)}

                        </TableBody>
                      </Table>
                    </TableContainer> )
                  : ""}
        </span>
            );
    }
   
   
return (
    <>
     {/**
        <div className={lstyles.Fullrow}>
          <div style={{float:'left', width:'100%', backgroundColor:'#222'}}/>
          <div style={{float:'left', backgroundColor:'#222'}}><img src="/home_banner.jpg" width='800px' alt="bannerimage" /></div>
          <div style={{float:'left', width:'100%', backgroundColor:'#222'}}/>
        </div>
     */}
        <div className={lstyles.infoboxes}>
            <div className={lstyles.c11}>
                      <InfoBox info=""
                            topElement={<>
                                {ownTours?.length > 0 && (
                                    <>
                                    {flagImages(ownTours[0].tour.countryCodes, 2) } &nbsp;
                                    {simpleNavLink("/tour/" + ownTours[0].tour.uuid, ownTours[0].tour.name)}
                                    <br/>
                                    </>
                                )}
                                {simpleNavLink("/planning", "Your plans")}
                                <br/>
                                {simpleNavLink("/bookmarks", "Your bookmarks")}
                                <br/>
                                {simpleNavLink("/diary", "Your diary")}
                                <br/>
                                <br/>
                                 </>}
                            title="Whats hot today?" color="#667788"
                            midElement={renderNews(news.soon, "Opening soon", "opens")}
                            bottomElement={renderNews(news.recently, "Recently opened", "has opened")}
                              />
                            
            </div>
            <div className={lstyles.c12}>
                
                <InfoBox info=""
                    title="Get inspired" color="#887777"
                    topElement={<><CrossfadeImg width='40vw' height='40vh' src={"title/" + image} duration={devMode ? "1s" : "10s"}  objectFit="contain" /></>}
                    />
            </div>
            <div className={lstyles.c13}>
                      <InfoBox info="
                        Remember you visits in your diary.
                        Get aid in time management. Explore cities, theme parks, Unesco world heritage sites and more.
                        Do you have a dream location? Remember it in your Bookmarks."
                            link="/diary" linktext="Your diary"
                            title="Your vacation assistant" color="#668877"
                            bottomElement={<>
                                <br/>
                                {simpleNavLink("/bookmarks", "Your bookmarks")}
                                </>}
                            />   

            </div>
        </div>

    </>
    );
};

export default Home;
