
import {TourEvent} from "../../api";


export class DayAnalysis {
    hasAccommodation: boolean = false;  // true if there is an accommodation for this day
    // The following 2 properties refine the accommodation information. They can only be true, if accommodation == true
    checkInTimeUnknown: boolean = false; // If
    arriveTooLateForCheckIn: boolean = false; // If you arrive too late for checkin
}

export class TourEventAnalysis {
    startsBeforeLocationOpens: boolean = false;
    startsBeforeFormerEventEnds: boolean = false;
    departureBeforeArrival: boolean = false;
    // Distance in from the event before. km or miles, depending on users choice
    distanceFromPrecedingEvent: number = 0;
    isFirstEventOfTour: boolean = false;
    isLastEventOfTour: boolean = false;
    isFirstEventOfDay: boolean = false;
    isLastEventOfDay: boolean = false;
}

export class TourAnalysis {
    eventAnalysis: Map<String, TourEventAnalysis> = new Map();  // TourEventAnalysis
    dayAnalysis:  Map<String, DayAnalysis> = new Map(); // DayAnalysis
}

// In: TimeString "hh:mm". Out:   h*60 + m
export const timeStringToMinutestOfDay = (isoTime: string)  : number => {
   const hour = parseInt(isoTime.substr(0,2), 10);
   const minute = parseInt(isoTime.substr(3,5), 10);
   return hour * 60  + minute as number;
}

// Returns the arrival time in minutes of the day.
// If unknown, undefined is returned.
export const arrivalMinutesInDay = (tourEvent: TourEvent) : number | undefined => {
    if (tourEvent.arrival) {
        const minutes = timeStringToMinutestOfDay(tourEvent.arrival);
        return minutes;
    }
    return undefined;
}

// Returns the departure time in minutes of the day, as given by the departure field.
// If the departure field is undefined, derives departure from arrival + stayDuration.
// If unknown, undefined is returned.
export const departureMinutesInDay = (tourEvent: TourEvent) : number | undefined => {
    if (tourEvent.departure) {
        const minutes = timeStringToMinutestOfDay(tourEvent.departure);
        return minutes;
    }
    if (tourEvent.arrival && tourEvent.stayDuration) {
        const minutes = timeStringToMinutestOfDay(tourEvent.arrival);
        return minutes + tourEvent.stayDuration;
    }

    return undefined;
}

/**
 Analyze the given Tour and return statistics, information, and problems.
*/
export const analyzeTour = (tourEvents: TourEvent[]) : TourAnalysis => {
    let dayAnalysisMap = new Map();
    let teAnalysisMap = new Map();

    let eventOneBefore;
    let teaOneBefore;
    for (const tevent of tourEvents) {
        let tea : TourEventAnalysis = new TourEventAnalysis();
        teAnalysisMap.set(tevent.uuid, tea);

        const thisDeparture = departureMinutesInDay(tevent);
        const thisArrival = arrivalMinutesInDay(tevent);
        if (thisDeparture && thisArrival) {
            tea.departureBeforeArrival = thisArrival > thisDeparture;
        }
        // Check if we have a time overlap. This is only relevant if eventOneBefore is on the same day as tevent
        if (eventOneBefore && eventOneBefore.day === tevent.day) {
            const lastDeparture = departureMinutesInDay(eventOneBefore);
            if (lastDeparture && thisArrival) {
                tea.startsBeforeFormerEventEnds = thisArrival < lastDeparture;
            }
        }
        
        tea.isFirstEventOfDay = eventOneBefore ? eventOneBefore.day !== tevent.day : true;
        tea.isFirstEventOfTour = eventOneBefore ? false : true;
        
        tea.isLastEventOfDay = true; // Presume true. This will be adjusted in the next loop iteration, if required.
        tea.isLastEventOfTour = true; // Presume true. This will be adjusted in the next loop iteration, if required.
        
        
        // Adjust isLastEventOfDay of FORMER event
        if (teaOneBefore) {
            // "Fix" the  isLastEventOfDay of the former event. THis code will be called for ALL events, except for the VERY
            // last event (that one stays "true").
            teaOneBefore.isLastEventOfDay = tea.isFirstEventOfDay;
            teaOneBefore.isLastEventOfTour = false;
        }

        eventOneBefore = tevent;
        teaOneBefore = tea;
    }

    const ta : TourAnalysis = { eventAnalysis: teAnalysisMap , dayAnalysis: dayAnalysisMap };
    return ta;
};