import { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";

import * as L from 'leaflet';

import * as Config from "../../Config";

import {Bookmark, DiaryEntry, EnrichedTour, EventType, GeoCoordinate, OSMBoundingBox, Tour, TourEvent, TourMetadata} from "../../api"
import { dayDiff } from "../tools/DateUtil";
import {ActivePOIs, CoreMarker, PoiType} from "../shared/Types"
import {ErrorHandler, ErrorTelegram, EnrichedUser} from "../shared/Types";

import AddEventDialog from "./AddEventDialog";
import EditEventDialog from "./EditEventDialog";
import ConfirmDialog from "../shared/ConfirmDialog";
import EditTourDialog from "./EditTourDialog";

import PlanningBox from "./PlanningBox";
import PlanningMap from "./PlanningMap";
import {Location} from "../shared/Geo"

import { getPois } from "../../httpclient/PoiDAO"; 


import { useViewport } from "../../components/tools/ViewportHook";

import { UserContext } from "../../contexts/UserContext";

import lstyles from "./Plan.module.scss";

interface PlanProps {
	etour: EnrichedTour;
	refreshEtour: (etour: EnrichedTour) => void; // use this to update the edited tour, e.g. the countries or providers
	openTourList: () => void;
    openLoginDialog: () => void;
}



    const selectProvidersFromTour = (tourPar : Tour) : ActivePOIs => {
        const prov = tourPar.providers;
        let actPois : ActivePOIs;
        if (prov && prov.length > 0) {
            const withRcdb = prov.includes("rcdb");
            const withUnescoWhc = prov.includes("unesco-whc");
            actPois = {tourevents:true, rcdb:withRcdb, unescoWhc:withUnescoWhc, bookmarks:true, visited:false};
        } else {
            // Nothing set in tour. Then enable default providers
            actPois = {tourevents:true, rcdb:true, unescoWhc:true, bookmarks:true, visited:false};
        }
        return actPois;
    };


    

    
    interface MapState {
        center: L.LatLng;
        zoomLevel: number;        
    };
    
    const calcMapCenterAndZoom = (north: number | undefined, west: number | undefined, south: number | undefined, east: number | undefined): MapState => {
        let lat, lon, latDiff, lonDiff;

        if (north && south) {
            latDiff = north - south;
            lat = south + (latDiff / 2)
        } else {
            latDiff = 20;
            lat = 51; // Fallback: Focus on central Europe, if nothing can be determined
        }

        if (east && west) {
            lonDiff = east - west;
            lon = west + (lonDiff / 2)
        } else {
            lonDiff = 20;
            lon = 11; // Fallback: Focus on central Europe, if nothing can be determined
        }

        // Zoom levels are a bit tricky, so for now we only do an approximation.
        // For doing correct zoom calculations see https://wiki.openstreetmap.org/wiki/Zoom_levels
        const diffMax = Math.max(lonDiff, latDiff);
        let desiredZoomLevel;
        if (diffMax < 0.3) desiredZoomLevel = 14
        else if (diffMax < 0.4) desiredZoomLevel = 13
        else if (diffMax < 0.5) desiredZoomLevel = 12
        else if (diffMax < 0.6) desiredZoomLevel = 11
        else if (diffMax < 2) desiredZoomLevel = 10
        else if (diffMax < 3.5) desiredZoomLevel = 9
        else if (diffMax < 5) desiredZoomLevel = 8
        else if (diffMax < 8) desiredZoomLevel = 7
        else if (diffMax < 11) desiredZoomLevel = 6
        else if (diffMax < 15) desiredZoomLevel = 5
        else desiredZoomLevel = 4;

        const zoomLevel = Math.min(Math.max(1, desiredZoomLevel), 15); // keep between 1 and 15
        //console.log("diffLat=", latDiff, "diffLon=", lonDiff, "diffMax=", diffMax, "desiredZoomLevel=", desiredZoomLevel, "zoomLevel=", zoomLevel);
        
        return {center: L.latLng(lat, lon), zoomLevel: zoomLevel };
    }
    
    const calcMapCenterAndZoomFromTourEvents = (tevents: TourEvent[]): MapState => {
        let north = undefined;
        let south = undefined;
        let east = undefined;
        let west = undefined;

        for (const tevent of tevents) {
            const geo = tevent.geoCoordinate;
            if (geo) {
                if (!north || north < geo.latitude) { north = geo.latitude; }
                if (!south || south > geo.latitude) { south = geo.latitude; }
                if (!east || east < geo.longitude) { east = geo.longitude; }
                if (!west || west > geo.longitude) { west = geo.longitude; }
            }
        }
        return calcMapCenterAndZoom(north, west, south, east);
    }


    
export const lastSingleView = () : string => {
    const lastString : string = localStorage.editview;
    return lastString ? lastString : "list";
}

export const setLastSingleView = (theView: string) : void => {
    localStorage.editview = theView;
}


const PlanEdit = ({ etour, refreshEtour, openTourList, openLoginDialog }: PlanProps): ReactElement => {
    const user: EnrichedUser = useContext(UserContext).user;

	const [errormsg, setErrormsg] = useState<string>("");

	const [tourEvents, setTourEvents] = useState<TourEvent[]>([]);
	// Highlighted event, and corresponding point on the map
	const [highlightedEvent, setHighlightedEvent] = useState<TourEvent>();

	const [modalAddEventShow, setModalAddEventShow] = useState(false);

	const [day, setDay] = useState(1)
	const [intraday, setIntraday] = useState(1)

	const [mapCenter, setMapCenter] = useState<L.LatLng>(L.latLng(19.8703419, 2.7797221)); // TODO This should NOT use the Leaflet model, but our own model
	const [mapBoundingBox, setMapBoundingBox] = useState<OSMBoundingBox>({south:10, north:30, west:-10, east:10}); // Will be updated by Leaflet changes only
	// mapZoom could be moved to PlanningMap
	const [mapZoom, setMapZoom] = useState<number>(6);
    const [mapTrigger, setMapTrigger] = useState<boolean>(true);

	const [appendMode, setAppendMode] = useState('nextDay');

	// The POI's on the map
	const [mapPois, setMapPois] = useState<Location[]>([]);
	// Which POI's to take into account. FOr the map, for searching
	const [activePOIs, setActivePOIs] = useState<ActivePOIs>({tourevents:true, rcdb:true, unescoWhc:true, bookmarks:true, visited:false});

	const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
    const [visited, setVisited] = useState<DiaryEntry[]>([]);
    
    const [singleViewComponent, setSingleViewComponent] = useState<string>(lastSingleView());
    const { isSmallWidth } = useViewport();


    // Modal dialogs and popups
	const [modalEditTourShow, setModalEditTourShow] = useState(false);
	const [modalDeleteTourShow, setModalDeleteTourShow] = useState(false);
	const [modalEditTourEventShow, setModalEditTourEventShow] = useState(false);
	// The reuslt box is part of the map. As we also want to control it from top level (e.g. hide it) we maintain its visibility state here.
    const [resultboxVisible, setResultboxVisible] = useState<boolean>(false);



    const errorHandler: ErrorHandler = (et: ErrorTelegram | undefined)   : void => {
		setErrormsg(et ? et.message : "");
	}

	
	const resetError = (): void => {
		errorHandler(undefined);
	}



    const loadTourEvents = (tour: Tour, metadata: TourMetadata | undefined, moveMap: boolean): void => {
        axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

        const touruuid = tour.uuid as string;


        
        let url = Config.api_url + "tourevents/" + touruuid;
        axios.get<TourEvent[]>(url).then(response => {
            let tevents = response.data;

            // Calculate new insert position (at the end)
            let addPosDay = 1;
            let addPosIntraday = 1;
            for (const tevent of tevents) {
                if (tevent.day === addPosDay && tevent.eventOrder > addPosIntraday) {
                    addPosIntraday = tevent.eventOrder;
                } else if (tevent.day > addPosDay) {
                    addPosDay = tevent.day;
                    addPosIntraday = tevent.eventOrder;
                }
            }
            
            
            setDay(addPosDay);
            setIntraday(addPosIntraday);
            setTourEvents(tevents);
            if (moveMap) {
                const newMapState : MapState = calcMapCenterAndZoomFromTourEvents(tevents);
                setMapCenter(newMapState.center);
                setMapZoom(newMapState.zoomLevel);
                setMapTrigger(true);
            }
        });
        // TODO Error handling
    };

	
    const reloadTourEvents = (tour: Tour, metadata: TourMetadata | undefined, moveMap: boolean): void => {
        loadTourEvents(tour, metadata, moveMap);
    }

    // Load POI's that match the countries of this tour. They are also filtered by the users interest (e.g. "theme parks" + "Unesco World heritage sites").
    // Initially the POI providers are derived from the tour, but the user can show or hide any POI typüe individually. 
    // or if the travel countries are changing. We could also load POI's for a specific region ("Search in this area"), e.g. via a dynamic Geosearch request.
    const loadPois = (actPois : ActivePOIs, etour: EnrichedTour) : void => {
        const cc : string = etour.tour.countryCodes?.length > 0 ? etour.tour.countryCodes.join(",") : "";
        const fromDate = etour.tour.start?.length > 0 ? etour.tour.start : "";
        const daysAhead = dayDiff(etour.tour.start, etour.tour.end);
        getPois(actPois, cc, fromDate, daysAhead, () => {}).then(resp => setMapPois(resp || [])); 
    };


	const loadBookmarks = () : void => {
        const url = Config.api_url + "bookmark"; // + ccArg;
        axios.get<Bookmark[]>(url).then(response =>
            {
				setBookmarks(response.data);
            },
            () => {
                setErrormsg("Error loading bookmarks");
            });
	};
	
	const loadOrClearVisited = (tryLoad: boolean) : void => {
        if (!tryLoad) {
            setVisited([]);
            return;
        }
        const url = Config.api_url + "diary"; // + ccArg;
        axios.get<DiaryEntry[]>(url).then(response =>
            {
                setVisited(response.data);
            },
            () => {
                setErrormsg("Error loading diary");
            });
    };





	
	const changePoiActiveState = (poiType: PoiType, newState: boolean) : void => {
		const newActivePOIs : ActivePOIs = Object.assign({}, activePOIs);
		switch(poiType) {
			case PoiType.BOOKMARK: newActivePOIs.bookmarks = newState; break;
            case PoiType.VISITED: newActivePOIs.visited = newState; break;
			case PoiType.RCDB: newActivePOIs.rcdb = newState; break;
			case PoiType.TOUREVENT: newActivePOIs.tourevents = newState; break;
			case PoiType.UNESCO_WHC: newActivePOIs.unescoWhc = newState; break;
		}
		setActivePOIs(newActivePOIs);
		loadPois(newActivePOIs, etour);
		loadOrClearVisited(newActivePOIs.visited);
	}


	useEffect(() => {
        console.log("PlanEdit useEffect()");
        setErrormsg(""); // clear error
	    const actPois : ActivePOIs = selectProvidersFromTour(etour.tour);
        setActivePOIs(actPois);
        loadPois(actPois, etour);

		loadTourEvents(etour.tour, etour.metadata, true);
		
        if (user.loggedIn) {
            loadBookmarks();
            loadOrClearVisited(actPois.visited);
        } else {
            setBookmarks([]); // Guests do not have bookmarks, thus do not try to load them
            loadOrClearVisited(false);
        }
	}, [etour, user.loggedIn]);


    const switchView = (): void => {
        if (singleViewComponent === "map") {
            setSingleViewComponent("list");
            setLastSingleView("list");
            setMapTrigger(true);
        } else {
            setSingleViewComponent("map");
            setLastSingleView("map");
            setMapTrigger(true);
        }
        
    }
	
	// Edit a specifically given TourEvent.
	const showModalEditTourEvent = (tourEvent: TourEvent): void => {
		// At the moment the GUI logic will edit the highlightedEvent. So we set it set it before opening the dialog 
		setHighlightedEvent(tourEvent);
		setModalEditTourEventShow(true);
	};
		
		
	const restAddTourEvent = ( newTourEvent: TourEvent): void => {

        axios.post<TourEvent>(Config.api_url + "tourevents", newTourEvent
        ).then(() => {
            const geo : GeoCoordinate | undefined  = newTourEvent.geoCoordinate;
            let adjustMap : boolean = false;
            if (geo) {
                adjustMap = (geo.latitude < mapBoundingBox.south || geo.latitude > mapBoundingBox.north || geo.longitude < mapBoundingBox.west || geo.longitude > mapBoundingBox.east)
            }
            // Map boundaries will be adjusted, if the added TourEvent would not be visible in the current Map
            loadTourEvents(etour.tour, etour.metadata, adjustMap);
            resetError();
            //console.log(response.data);
        }).catch(() => {
            setErrormsg("Error saving Tour Event")
        });
    }

	const handleAddTourEvent = (location : CoreMarker): void => {
		let appendDay = day;
		let appendIntraDay = intraday;

		switch (appendMode) {
			case 'nextDay':
				appendDay += 1;
				appendIntraDay = 1;
		}

		const newTourEvent: TourEvent = {
            uuid: "", // will be filled by the backend
			tourUuid: etour.tour.uuid as string,
			userId: user.userId as number,
			eventType: EventType.STAY,
			day: appendDay,
			eventOrder: appendIntraDay,
			title: location.name,
			providerRef: location.providerRef,
			address: location.address || {},
			geoCoordinate: location.geoCoordinate
		};

		setDay(appendDay);
		setIntraday(appendIntraDay + 1);
		
		restAddTourEvent(newTourEvent);

	};
	
	
    const duplicateEventCallback = (tourEvent : TourEvent): void => {
        const newTourEvent: TourEvent = { ...tourEvent };
        newTourEvent.uuid =  ""; // will be filled by the backend;
        restAddTourEvent(newTourEvent);
    }

	
	const confirmDeleteTour = (tour: Tour): void => {
		setModalDeleteTourShow(true);
	}
	
		

	const handleDeleteTourEvent = (tourId?: string): void => {
		axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

		const url = Config.api_url + "tourevents/" + tourId;
		axios.delete<void>(url
		).then(response => {
            loadTourEvents(etour.tour, etour.metadata, true);
			resetError();
			//console.log(response.data.tours);
		},
			() => {
				setErrormsg("Error deleting tour event")
			});
	};



	const handleEditTour = (tour: Tour): void => {
		//console.log("Save edited tour now: " + tour);
		axios.put(Config.api_url + "tours/" + tour.uuid, tour
		).then(() => {
            // Note: This uses the old etour.metadata. It might not be completely correct after editing a Tour, but for now it is good enough.
            // TODO: The etour.tour may have changed. We should update it with the "tour"  parameter. Otherwise we have a wrong state, e.g.
            //   1. When opening the tour editor again, it shows the stale values, e.g. the selected providers. 
            //   2. The map will not reflect a change in the country list
            resetError();
            // If something changes, the editor reflects that via the refreshEtour() call. Examples:
            //  1. A changed start date has an effect on the effective opening times.
            //  2. Changing providers should update the POI's to only show the newly chosen providers
            //  3. Changed countries changes the Map bounding box for an empty tour, and can have an effect on POI search.
            refreshEtour({tour: tour, metadata: etour.metadata});
			// Editing the tour can change the countries. Then we should reload the POI's so that
			// they match the new countries. Also, if the start date changes, POI's may change open/closed status.
			// Because of these and other reasosns, it is bneter to reload the POI's.
			loadPois(activePOIs, etour);
			//console.log(response.data);
		}).catch(() => {
                setErrormsg("Error saving Tour Event")
            });
	}
	
	const handleDeleteTour = (referenceObject: object): void => {
		const tour: Tour = referenceObject as Tour;
		console.log("Delete tour now: " + tour);
        const url = Config.api_url + "tours/" + tour.uuid;
        axios.delete<void>(url
        ).then(response => {
                setErrormsg("")
                openTourList();
                //console.log(response.data.tours);
            },
            () => {
                setErrormsg("Error deleting Tour")
            });
	}
	
	
	
	const handleEditTourEvent = (tourEvent: TourEvent): void => {
		axios.put<TourEvent>(Config.api_url + "tourevents/" + tourEvent.uuid, tourEvent
		).then(response => {
            loadTourEvents(etour.tour, etour.metadata, false);
			resetError();
			//console.log(response.data);
		},
			() => {
				setErrormsg("Error saving Tour Event")
			});
	}

	const markActiveEvent = (tourEvent: TourEvent): void => {
		setHighlightedEvent(tourEvent);
	};

    const showSwitchViewIcon = isSmallWidth;

	return (
		<>
            <div className={lstyles.eventsAndMapGroup}>
                {isSmallWidth && singleViewComponent !== "list" ? <></> :
                <div className={lstyles.eventView}>
                    <PlanningBox tourEvents={tourEvents} openLoginDialog={openLoginDialog}
                        switchViewIcon={showSwitchViewIcon} switchView={switchView}
    				        loadTourEventsWithArgs={reloadTourEvents}
    				        externalErrorMessage={errormsg} appendMode={appendMode}
    				        setAppendMode={setAppendMode} setModalAddEventShow={setModalAddEventShow} setModalEditTourEventShow={showModalEditTourEvent} setModalEditTourShow={setModalEditTourShow} markActiveEvent={markActiveEvent}/>
                </div>
                }
                {isSmallWidth && singleViewComponent !== "map" ? <></> :
                <div  className={lstyles.mapview}>
    				    <PlanningMap tour={etour.tour} tourEvents={tourEvents} mapPois={mapPois} bookmarks={bookmarks} visited={visited} activePOIs={activePOIs} resultboxVisible={resultboxVisible} setResultboxVisible={setResultboxVisible}
    				        switchViewIcon={showSwitchViewIcon} switchView={switchView}
    				        highlightedEvent={highlightedEvent} setHighlightedEvent={setHighlightedEvent}
        				    setModalAddEventShow={setModalAddEventShow}
                        mapCenter={mapCenter} setMapCenter={setMapCenter} mapBoundingBox={mapBoundingBox} setMapBoundingBox={setMapBoundingBox} mapZoom={mapZoom} mapTrigger={mapTrigger} setMapTrigger={setMapTrigger}
    		      		    changePoiActiveState={changePoiActiveState} handleAddTourEvent={handleAddTourEvent} errorHandler={errorHandler}/>
                </div>
                }
            </div>
            {/**  All modal dialogs for the planning page go here */}
            {modalAddEventShow && <AddEventDialog tour={etour.tour} modalShow={modalAddEventShow} setModalShow={setModalAddEventShow} addLocationCallback={handleAddTourEvent} /> }
			{highlightedEvent && <EditEventDialog user={user} tour={etour.tour} tourEvent={highlightedEvent} modalShow={modalEditTourEventShow} setModalShow={setModalEditTourEventShow} editTourEventCallback={handleEditTourEvent} duplicateEventCallback={duplicateEventCallback} deleteTourEventCallback={handleDeleteTourEvent} /> }
			{modalEditTourShow && <EditTourDialog user={user} tour={etour.tour} modalShow={modalEditTourShow} setModalShow={setModalEditTourShow} editTourCallback={handleEditTour} deleteTourCallback={confirmDeleteTour} /> }
			{modalDeleteTourShow && <ConfirmDialog title={"Delete Tour " + etour.tour.name} message={"Are you sure you want to delete the tour '" + etour.tour.name + "'?"}  isDeleteAction={true} modalShow={modalDeleteTourShow} setModalShow={setModalDeleteTourShow} confirmedCallback={handleDeleteTour} reference={etour.tour} /> }



		</>
	);
};

export default PlanEdit;

