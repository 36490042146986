import React, { ReactElement} from "react";



export const flagImages = (ccs : string[], limit: number): ReactElement[] => {
    let result: ReactElement[] = [];
    for(let i =0; i<ccs.length; i++) {
        if (i >= limit) {
            break;
        }                
        const cc = ccs[i];
        if (cc.length === 2) {
            const srcx = "/flags/20x15/" + cc.toLowerCase() + ".png";
            const elem: ReactElement = <img key={i} src={srcx} alt={cc} />;
            if (result.length >0) {
                result.push(<span key={100 + i}> </span>);
            }
            result.push(elem);
        } 
    };
    if (result.length < ccs.length) {
        result.push(<React.Fragment key="hellip"> &hellip;</React.Fragment>);
    }
    
    return result;        
} 