import axios from "axios";


import * as Config from "../../Config";
import {AccountStatus, AuthResponse, User, UserIdentity, UserProfile, UserRegistration} from "../../api"
import {ErrorStatusType} from "../shared/Types"

export const logout = (onUpdateUser: (user: User, status: ErrorStatusType) => void) : void => {
    authCall(onUpdateUser, "logout");
};


export const login = (onUpdateUser: (user: User, status: ErrorStatusType) => void, userName: string, password: string, stayLoggedIn: boolean ) : void => {
    authCall(onUpdateUser, "login?user=" + userName + "&password=" + password + "&durableSession=" + stayLoggedIn)
};

export const guest: User = { accountStatus:AccountStatus.Guest, userProfile:{publicName:"",countryCode:"",language:"", homeAddress:{}}, metadata:{atime:0, ctime:0,mtime:0,loginCount:0} };

const authCall = (onUpdateUser: (user: User, status: ErrorStatusType) => void, authParams: string) :  void =>  {
	const url = Config.auth_url + authParams;

    axios.defaults.withCredentials = true; // disable cookies (especially the Auth Token). 
    // Login
    axios.get<AuthResponse>(url).then(response => {
    	    console.log("rs: " + response.status);
    	    onUpdateUser(response.data.user, ErrorStatusType.OK);
    	    })
    	    .catch((error) => {
        if (error.response) {
            const httpStatus = error.response.status;
             switch (httpStatus) {
                case 403: onUpdateUser(guest, ErrorStatusType.PERMISSION_DEINED); break;
                case 409: onUpdateUser(guest, ErrorStatusType.ACCOUNT_ALREADY_EXISTS); break;
                default: onUpdateUser(guest, ErrorStatusType.SERVER_ERROR); break;
             }
        } else {
            onUpdateUser(guest, ErrorStatusType.SERVER_UNAVAILABLE);
        }
    });
        
};


export const register = (onUpdateUser: (user: User, status: ErrorStatusType) => void, userIdentity: UserIdentity, userProfile: UserProfile, password: string, stayLoggedIn: boolean ) : void => {
	const url = Config.auth_url + "register?durableSession=" + stayLoggedIn;
    
    const userRegistration: UserRegistration = {userIdentity:userIdentity, userProfile: userProfile, password:password};
    axios.defaults.withCredentials = true; // disable cookies (especially the Auth Token). 
    // Login
    axios.post<AuthResponse>(url, userRegistration).then(response => {
	    	    console.log("rs: " + response.status);
	    	    onUpdateUser(response.data.user, ErrorStatusType.OK);
        })
        .catch((error) => {
            if (error.response) {
    			const httpStatus = error.response.status;
    			 switch (httpStatus) {
    				case 403: onUpdateUser(guest, ErrorStatusType.PERMISSION_DEINED); break;
    				case 409: onUpdateUser(guest, ErrorStatusType.ACCOUNT_ALREADY_EXISTS); break;
    				default: onUpdateUser(guest, ErrorStatusType.SERVER_ERROR); break;
    			 }
			} else {
                onUpdateUser(guest, ErrorStatusType.SERVER_UNAVAILABLE);
            }
	    });
};



