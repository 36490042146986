import React from "react";

import {NotificationOverlayProps} from "../dialogs/NotificationOverlay";

import { AlertColor  } from '@mui/material/Alert';

import { Bookmark, DiaryEntry } from "../api";

export type GenericModalContext<P> = {
 show: (modalProps: P) => void;
 hide: () => void;
 store: P;
};


export interface BookmarkEditorProps {
    open: (props: Bookmark) => void;
    hide: () => void;
    data: Bookmark | undefined;
}

export const BookmarkDialogContext = React.createContext<BookmarkEditorProps>({
    open: (props: Bookmark) => {
        console.log("BookmarkDialogContext.open default")
        },
    hide: ()  => {},
    data: undefined
});

export interface DiaryEditorProps {
    open: (props: DiaryEntry) => void;
    hide: () => void;
    data: DiaryEntry | undefined;
}

export const DiaryDialogContext = React.createContext<DiaryEditorProps>({
    open: (props: DiaryEntry) => {},
    hide: ()  => {},
    data: undefined
}); 

export const NotificationContext = React.createContext<NotificationOverlayProps>({
    show: false,
    setShow: (active: boolean) => { console.log("default nc show/hide")},
    message: "",
    setMessage: (msg: String) => { console.log("default nc message")},
    severity: "info",
    setSeverity: (severity: AlertColor) => { console.log("default nc message")}
});

