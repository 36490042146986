import { ReactElement, useEffect, useState } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import LogoutIcon from '@mui/icons-material/Logout';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import * as Config from "../../Config";
import { AccountStatus, Address, User, UserIdentity, UserProfile} from "../../api"
import ConfirmDialog from "../shared/ConfirmDialog";
import { guest, logout } from "../../components/Account/AccountTools"
import MultiItemPicker from "../shared/MultiItemPicker";
import { CountryMap } from "../shared/GeoConstants";

import styles from "../../components/shared/Styles.module.scss";

interface LoginProps {
    user: User;
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    onUpdateUser: (user: User) => void;
}

/** User profile dialog. The user can change prefernces like public name or language. Also the account confirmation is here.
    In futute, a "change email address" should also be possible.
    
    Please note that this dialog holds state of private data from UserIdentity like account name or email address. Due to the
    sensitive nature of this data, this dialog is currently the ony place in the frontend that deals has access to UserIdentity,
    and the data is discarded as soon as the user leaves this dialog.   
 */
// autocomplete docs: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
const AccountProfileDialog = ({modalShow, setModalShow, user, onUpdateUser}: LoginProps): ReactElement => {
    const [userIdentity, setUserIdentity] = useState<UserIdentity | undefined>(undefined);
    // Registration   
    const [profileCountry, setProfileCountry] = useState<string[]>([user.userProfile.homeAddress.country || ""]);
    const [profileCity, setProfileCity] = useState<string>(user.userProfile.homeAddress.city || "");
    const [profileAdressline, setProfileAdressline] = useState<string>(user.userProfile.homeAddress.street || "");
    const [publicName, setPublicName] = useState<string>(user.userProfile.publicName);
    
    const [modalConfirmDialog, setModalConfirmDialog] = useState<boolean>(false);


    const profile : UserProfile = user.userProfile;
    const accountUnconfirmed = user.accountStatus === AccountStatus.Unconfirmed;
    const accountConfirmed = user.accountStatus === AccountStatus.User;
    
    const confirmLink = "mailto:info@touredo.org?subject=Confirm Touredo account: " + userIdentity?.loginName + "&body=This mail confirms your Touredo acccount. "
    + "It must be sent from the email address " + (userIdentity?.emailAddress && userIdentity.emailAddress) + ", which was used to register the account. "
    + "%0D%0AConfirming an account unlocks advanced features, and prevents suspending the account."
    + " Confirmations are processed within one day. If you have trouble confirming the account write a mail to info@touredo.org"
    ;
    
    const confirmMessge = "Confirming your touredo account is required in the future to unlock all features." +
     " Confirmed accounts have less limitations, and can access advanced features. It also helps to restore account access if you lost the password or the account got disabled. " +
     " If you have problems accessing your account, please send a mail to info@touredo.org, from the email address " + (userIdentity?.emailAddress && userIdentity.emailAddress) + ", which was used to register the account.";
    
    const loadIdentity = () : void => {
        const url = Config.auth_url + "identity";
        axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token). 
    
        axios.get<UserIdentity>(url).then(response => {
            setUserIdentity(response.data);
            if (!publicName || publicName.trim() === "") {
                setPublicName(response.data.loginName);
            }
        }).catch(() => {
            setUserIdentity(undefined);
        });
    };
    
    const saveProfile = () : void => {
        const newAddress : Address = {country: profileCountry[0] || "", city: profileCity, street: profileAdressline};
        const newProfile : UserProfile = {...user.userProfile, publicName: publicName, countryCode: profileCountry[0] || "", homeAddress: newAddress};
        const url = Config.auth_url + "profile";
        axios.defaults.withCredentials = true; // disable cookies (especially the Auth Token). 
        axios.put<UserProfile>(url, newProfile).then(response => {
            const newUser : User = {...user, userProfile: response.data};
            onUpdateUser(newUser);
            setModalShow(false);
        }).catch(() => {
            onUpdateUser(guest);
            setModalShow(false);
        });
    };
    
        
    useEffect(loadIdentity, [user]);
    
    const setEmailAddress = (ea: string) :void => {
        // TODO Update emailAddress. Set new address to unconfirmed
    } 
    
    return (
      <>
        <Dialog open={modalShow} onClose={() => setModalShow(false)}>
        <DialogTitle>Account management</DialogTitle>
            <form>
            <DialogContent>
                <div className={styles.flexVertical}>

                    <Button variant="contained" color="primary" startIcon={<LogoutIcon/>} onClick={() => {setModalShow(false); logout(onUpdateUser) } }>Logout</Button>
    
                    User profile
    
                    <div className={styles.flexHorizontal}>
                        <TextField id="login-publicname" fullWidth disabled label="Username" type="text" value={userIdentity ? userIdentity.loginName : ""} />
                        <TextField id="login-lang" fullWidth disabled label="Language" type="text" value={profile.language} />
                    </div>

                    <TextField id="login-publicname" fullWidth label="Public name" type="nickname" value={publicName}
                        onChange={e => { setPublicName(e.target.value) }}
                    />

                    <div className={styles.flexHorizontal}>
                    <TextField id="email_address"  fullWidth disabled label="EMail Address" type="email" autoComplete="email" value={userIdentity ? userIdentity.emailAddress : ""}
                        onChange={e => { setEmailAddress(e.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start"> <Tooltip title={accountConfirmed ? "confirmed" : "uncónfirmed"} ><VerifiedUserIcon color={accountConfirmed ? "success" : "disabled"} /></Tooltip> </InputAdornment>
                        ),}}
                     />
                     {accountUnconfirmed && <Button variant="contained"  onClick={() =>setModalConfirmDialog(true)} >Confirm Account</Button>}
                    </div>

                    Home address
                    <div className={styles.flexHorizontal}>
                        <MultiItemPicker onChange={setProfileCountry} title="Country" selected={profileCountry} keyValues={CountryMap} multiple={false} colonSelectorFormat={true}/>
                        {/* <TextField id="login-homeaddress-cc"   sx={{ width: '20%' }} disabled label="Home Address" type="text" value={profile.homeAddress.country} /> */}
                        <TextField id="login-homeaddress-city" sx={{ minWidth: '6em' }} label="City" type="text" value={profileCity} onChange={e => setProfileCity(e.target.value)}/>
                        <TextField id="login-homeaddress-street" sx={{ minWidth: '6em' }} label="Street" type="text" value={profileAdressline}  onChange={e => setProfileAdressline(e.target.value)}/>
                    </div>
                    Your home address is only visible to you. When sharing a tour containing your home address, it is kept secret and not published.  
                </div>
            </DialogContent>
            <DialogActions>
                <Button type="button" variant="outlined"  onClick={saveProfile}>Save</Button>
                <Button variant="outlined"  onClick={() => {setModalShow(false);  } }>Close</Button>
                {/** 
                // VERY BIG WARNING: Button type MUST be "button", and not "submit". axios may reject to send the request
                // in loginHandler() with "request aborted" cause, which happens for example in Firefox.
                // See https://stackoverflow.com/questions/61522494/react-axios-error-request-aborted-for-delete-request-in-firefox-but-not-in-chro
                */} 
            </DialogActions>
            </form>
        </Dialog>
      
        <ConfirmDialog title="Confirming your account" isDeleteAction={false} hideCancelAction={true} confirmedCallback={() => {}} modalShow={modalConfirmDialog} setModalShow={() => setModalConfirmDialog(false)} reference={{}}
        message = {confirmMessge} /> 
      </>
    );
};

export default AccountProfileDialog;
