import { ReactElement, useContext } from "react";

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import AddLocationIcon from '@mui/icons-material/AddLocation';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import EditIcon from '@mui/icons-material/Edit';
import NotInterestedIcon from '@mui/icons-material/NotInterested';


import { Activity, Address, Bookmark, DiaryEntry, OpeningCalendar, OpeningPerDay, OpeningStatus, OperatingStatus } from "../../api";
import { CoreMarker, EnrichedUser } from "../shared/Types";

import { now, nowToVisitDate, nowToLocalTime, userTimezone } from "../tools/DateUtil";
import {providerLinkByRefOrCoordinate} from "../shared/Provider";
import { formatAddressFullWithCountryFlag } from "../shared/Geo";

import {BookmarkEditorProps, DiaryEditorProps} from '../../contexts/GlobalDialogContext';
import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./Map.module.scss";

import { BookmarkDialogContext, DiaryDialogContext } from '../../contexts/GlobalDialogContext';

const pluralize = (word: string): string => {
    return word + "s";
} 
const renderOpeningSummary = (openingDays : { [index: string]: OpeningPerDay }): string => {
    if (!openingDays) {
        return "";
    }
    
    const openingDaysAsMap : Map<string, OpeningPerDay> = new Map(Object.entries(openingDays));

    let openedDays = 0;
    let isUnknown = false;
    openingDaysAsMap.forEach( (value, key, openingPerDay) => {
        if (value.openingStatus === OpeningStatus.OPEN) {
            openedDays++;
        } else if (value.openingStatus === OpeningStatus.UNKNOWN) {
            isUnknown = true;
        } 
    });
    
    // TODO: Handle OpeningStatus.UNKNOWN. e.g. return "" in case of unknown
    if (openedDays === 0 && isUnknown) {
        return "";
    } else { 
        return openedDays === 0 ? "Closed" : ("Opened " + openedDays + " " +  pluralize("day"));
    }
};

function renderCalendar(loc : CoreMarker, calendar: OpeningCalendar | undefined): ReactElement {
    const openingDayInfo = calendar && calendar.openingDays;
    return ( <> {openingDayInfo  && renderOpeningSummary(openingDayInfo)} </>);

};



const bookmarkEvent = (loc : CoreMarker, userId: number, addOrModifyBookmark: BookmarkEditorProps) : void => {
    const addressWithFallback : Address = loc.address || {};
    const bm : Bookmark = {
        uuid: "",
        address: addressWithFallback,
        liked: true,
        ignored: false,
        visited: false,
        visitPlanned: false,
        userId: userId,
        title: loc.name,
        providerRef: loc.providerRef,
        geoCoordinate: loc.geoCoordinate,
        category: "",
        notes: "",
        atime: 0,
        ctime: 0,
        mtime: 0
    }
    
    addOrModifyBookmark.open(bm);
    
    //console.log("Bookmark this:  " + loc + " , bookmark:" + bm);
};

const markVisited = (loc : CoreMarker, userId: number, showDiaryEditor: DiaryEditorProps) : void => {
    const addressWithFallback : Address = loc.address || {};
    const nowTs = now();
    const visit : DiaryEntry = {
        uuid: "",
        userId: userId,
        toureventUuid: loc.tourEventId,
        category: "",
        title: loc.name,
        notes: "", // Could be take from the tourEvent, if present
        providerRef: loc.providerRef,
        
        visitDate: nowToVisitDate(nowTs),
        visitTime: nowToLocalTime(nowTs),
        timeZoneOffsetSeconds: 0, // will be replaced on server side, using the timeZoneName field 
        timeZoneName: userTimezone(),

        geoCoordinate: loc.geoCoordinate,
        address: addressWithFallback,
        ctime: 0,  // replaced on server side 
        mtime: 0   // replaced on server side
    };
    
    showDiaryEditor.open(visit);
};






const countOpenAttractions = (activities: Activity[] | undefined): number => {
    let openCount = 0;
    activities?.forEach(activity =>  { if (activity.operatingStatus === OperatingStatus.OPERATING) openCount ++; });
    return openCount;
}


export interface EventToolbarCallbacks {
    addTourEvent: ( (location : CoreMarker) => void ) | undefined;
    openBookmarkDialog: ( (bookmark: Bookmark) => void ) | undefined;
    openDiaryDialog: ( (diaryEntry: DiaryEntry) => void ) | undefined;
    openLocationEditorDialog: ((location: CoreMarker) => void) | undefined;
}

interface EventToolbarProps {
    user: EnrichedUser;
    loc : CoreMarker;
    callbacks : EventToolbarCallbacks;
    withAddress : boolean;
}


const EventToolbar = ({user, loc, callbacks, withAddress }: EventToolbarProps ): ReactElement => {
    const attractions = loc.attractions;
    const openAttractionCount : number = countOpenAttractions(attractions); 
    const hasAttractions = openAttractionCount > 0;
    const providerRef = loc.providerRef;
    
    const callbackATO = callbacks.addTourEvent;
    const bookmarkDialogContext = useContext(BookmarkDialogContext);
    const diaryDialogContext = useContext(DiaryDialogContext);
    const callbackOLED = callbacks.openLocationEditorDialog;

    return (
        <>
            <div style={{ padding: "0px", margin: "0px", display: "flex", flexDirection: "column" }}>
            
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                <span style={{ fontSize: "18px" }}>{loc.name as string}</span>
                {callbackOLED && user.loggedIn && (
                     <IconButton size="small" onClick={() => { callbackOLED(loc); }}> <EditIcon />  </IconButton >
                )}
            </div>

            {withAddress && loc.address && formatAddressFullWithCountryFlag(loc.address)}
                <div className={styles.horButtonsRow}>
                {callbackATO && user.loggedIn && (
                <Tooltip enterDelay={1000} title="Add event to tour">
                    <div className={styles.horButtonAndTextBox}>
                        <IconButton size="small" disabled={!user.loggedIn} hidden={callbackATO !== undefined} onClick={() => { callbackATO(loc); }}> <AddLocationIcon /></IconButton>
                        Add
                    </div>
                </Tooltip>
                )}
                
                {bookmarkDialogContext && user.loggedIn && (
                <Tooltip enterDelay={1000} title="Bookmark this event">
                    <div className={styles.horButtonAndTextBox}>
                        <IconButton size="small" disabled={!user.loggedIn} hidden={bookmarkDialogContext !== undefined} onClick={() => { bookmarkEvent(loc, user.userId as number, bookmarkDialogContext ); }}> <BookmarkAddIcon /> </IconButton>
                        Bookmark
                    </div>
                </Tooltip>
                )}

                {diaryDialogContext && user.loggedIn && (
                <Tooltip enterDelay={1000} title="Mark as already visited">
                    <div className={styles.horButtonAndTextBox}>
                        <IconButton size="small" disabled={!user.loggedIn} hidden={diaryDialogContext !== undefined} onClick={() => { markVisited(loc, user.userId as number, diaryDialogContext); }}> <BookmarkAddedIcon /> </IconButton >
                        Visited
                        </div>
                </Tooltip>
                )}

                {bookmarkDialogContext && user.loggedIn && (
                <Tooltip enterDelay={1000} title="Not interested">
                    <div className={styles.horButtonAndTextBox}>
                        <IconButton size="small" disabled={!user.loggedIn} hidden={bookmarkDialogContext !== undefined} color="error" onClick={() => { bookmarkEvent(loc, user.userId as number, bookmarkDialogContext); }}> <NotInterestedIcon/> </IconButton >
                        Ignore
                    </div>
                </Tooltip>
                )}

                </div>
                
                {renderCalendar(loc, loc.calendar)}
                <div className={lstyles.popupHorFlex}>
                
                    {hasAttractions && <>{openAttractionCount} {openAttractionCount > 1 ? "activities" : "activity"}</>}
                    {providerRef && hasAttractions && <>&nbsp;:&nbsp;</>}
                    {!providerRef && hasAttractions && <>Info:&nbsp;</>}
                    
                    {/* Safe link to OpenStreetMap website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                    {providerRef &&  <a href={providerLinkByRefOrCoordinate(providerRef, loc.geoCoordinate)}  className={styles.tooltip} target="_blank" rel="noopener">Open {providerRef.provider} in new tab</a> }
                </div>

                
            </div>  

        </>
    )
}


export default EventToolbar;
