/** A Dialog to edit an existing tour or add a new tour
*/
import { ReactElement, useEffect, useState } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';

import DeleteIcon from '@mui/icons-material/Delete';


import MultiItemPicker from "../shared/MultiItemPicker";
import { CountryMap } from "../shared/GeoConstants";
import { EnrichedUser } from "../shared/Types";
import { Bookmark } from "../../api";
import { formatYMD_weekday, now } from "../tools/DateUtil";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./EditBookmarkDialog.module.scss";

const isNew = (bookmark : Bookmark | undefined) : boolean => {
    return bookmark === undefined || bookmark.uuid === "";
}

// Note: Exporting BookmarkEditProps for the Context
export interface BookmarkEditProps {
    user: EnrichedUser;
    // If bookmark is undefined, then it means that a new bookmark should be inserted instead of modifying an existing one.
    // The DELETE button is also be hidden, as it makes no sense in that case.
    bookmark : Bookmark | undefined;
    modalShow: boolean;
    hide: () => void;
    editCallback: (bookmark : Bookmark ) => void;
    insertCallback: (bookmark : Bookmark ) => void;
    deleteCallback: undefined | ((bookmark : Bookmark ) => void);
}

const calcDefaultTitle = (user: EnrichedUser, bookmark?: Bookmark) : string => {
    if (!isNew(bookmark)) {
        return bookmark?.title ? bookmark?.title : "Bokmark added at " + formatYMD_weekday(now(), user.userProfile.language);
    } else {
        return bookmark?.title ? bookmark?.title : "New bookmark: " + formatYMD_weekday(now(), user.userProfile.language);
    }
       
}

const EditBookmarkDialog = ({user, bookmark, modalShow, hide, editCallback, insertCallback, deleteCallback}: BookmarkEditProps): ReactElement => {
    const [title, setTitle] = useState<string>(calcDefaultTitle(user, bookmark) );
    const [category, setCategory] = useState<string>((bookmark && bookmark.category) || "default");
    
    // The country only contains a single country code (or none). Using an array is more convenient for the MultiItemPicker
    const [country, setCountry] = useState<string[]>( (bookmark && bookmark.address.country) ? [bookmark.address.country] : []);
    const [city, setCity] = useState<string>((bookmark && bookmark.address.city) || "");
    const [street, setStreet] = useState<string>((bookmark && bookmark.address.street) || "");
    
    const [notes, setNotes] = useState<string>((bookmark && bookmark.notes) || "");
    

    const updateEditFields = () : void => {
        setTitle(calcDefaultTitle(user, bookmark));
        setCategory((bookmark && bookmark.category) || "default");
        setCountry((bookmark && bookmark.address.country) ? [bookmark.address.country] : []);
        setCity((bookmark && bookmark.address.city) || "");
        setStreet((bookmark && bookmark.address.street) || "");
        setNotes((bookmark && bookmark.notes) || "");        
    };
   
   useEffect( updateEditFields
    , [bookmark, user]
    );
    
    // add the new TourEvent, and close the Dialog
    const handleEdit = (bookmark: Bookmark | undefined) => {
	    if (isNew(bookmark)) {
            const hasDefaults = bookmark !== undefined;
		    const newBookmark : Bookmark = {
			 userId:user.userId as number,
             title: title,
             category: category,
			 providerRef: hasDefaults ? bookmark.providerRef : undefined,
			 address: { country: country.length > 0 ? country[0] : "", city: city, street: street},
			 geoCoordinate: hasDefaults ? bookmark.geoCoordinate : undefined,
			 notes: notes,
			 liked: true,
			 visited: false,
			 visitPlanned: false,
			 ignored: false,
			 
			 // The following fields are set by the backend
			 uuid: "",
			 atime: 0,
             ctime: 0,
             mtime: 0
            };			 

 		   insertCallback(newBookmark);
   	    } else {
            const bm : Bookmark = bookmark as Bookmark; 
            bm.title = title;
            bm.category = category;
            bm.address = { country: country.length > 0 ? country[0] : "", city: city, street: street};
            bm.notes = notes;
 	        editCallback(bm);
        }
        hide();
    }

    // For now the user can only retrieve its own bookmarks. Thus any bookmark is writable. 
    const writable: boolean = true;
     
    return (
    <>
        <Dialog open={modalShow}
            PaperProps={{ sx: { minWidth: "30vw", padding:"0px" } }}

              onClose={() => hide()}>

                <DialogTitle>{isNew(bookmark) ? "New bookmark" : "Edit bookmark"}</DialogTitle>

                <DialogContent>
                <div className={styles.modalcontent}>
                <hr/>

                   <div className={lstyles.dialogcontent} style={{alignItems:"flex-start"}}>

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="Event" autoFocus={true} type="search" value={title}
                      onChange={e => { setTitle(e.target.value) }} />

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="Category" autoFocus={true} type="search" value={category}
                      onChange={e => { setCategory(e.target.value) }} />

                    <MultiItemPicker onChange={setCountry} title="Country" selected={country} keyValues={CountryMap} multiple={false} colonSelectorFormat={true}/>

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="City" autoFocus={true} type="search" value={city}
                      onChange={e => { setCity(e.target.value) }} />

                    <TextField required id="editevent-title" margin="dense" fullWidth disabled={!writable}
                     label="Street / freeform address" autoFocus={true} type="search" value={street}
                      onChange={e => { setStreet(e.target.value) }} />
                      
                    <TextareaAutosize
                        className={lstyles.textarea}
                        minRows={1}
                        placeholder="Notes"
                        value={notes} onChange={e => { setNotes(e.target.value) }}
                        disabled={!writable}
                    />
                   

                      </div>

                <hr/>
            </div>

            </DialogContent>
            <DialogActions>
                {bookmark && writable && deleteCallback && (<Button variant="outlined" color="error" startIcon={<DeleteIcon />}
                  onClick={() => {deleteCallback(bookmark); hide(); }} >Delete</Button>)}
                <Button variant="outlined" onClick={() => {hide();  } }>Cancel</Button>
                <Button variant="outlined" disabled={!writable} onClick={() => {handleEdit(bookmark);  } }>OK</Button>
            </DialogActions>
        </Dialog>

     </>   );
};

export default EditBookmarkDialog;
