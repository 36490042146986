import { ReactElement } from "react";

import { KeyboardEvent as KeyboardEventReact } from "react"
 
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

import { LocalTime, localTimeDeserialize, localTimeSerialize } from "../tools/DateUtil";


interface EditProps {
    id: string;
    className?: string;
    label: string;
    time: Date | string;
    setTime: (time: string) => void;
    disabled: boolean;
    small?: boolean;
}



const sanifyTime = (time: string): string => {
    // Sending the string through the serializer sets fields date or time to 0, if they are empty "".  
    return localTimeSerialize(localTimeDeserialize(time));
}

/**
     The TouredoTimePicker allow to pick a Date, and to reset it.
*/ 
const TouredoTimePicker = ({className, id, label, time, setTime, disabled, small}: EditProps): ReactElement => {
    
    const preventStep = ( ev : KeyboardEventReact, key: string): void => {
        if (key === "ArrowUp" || key === "ArrowDown") {
            console.info("ArrowUp clicked. Prevent step for: " + time);
            ev.preventDefault();
            ev.stopPropagation();            
        }
    }
    const doStepOrDefault = ( ev : KeyboardEventReact, key: string): void => {
        if (key === "ArrowUp") {
            console.info("ArrowUp clicked. Do step for: " + time);
            ev.preventDefault();
            ev.stopPropagation();
            const hhmm : LocalTime = localTimeDeserialize(time as string);
            let hour = hhmm.hour;
            let minute = hhmm.minute;
            
            
            if (minute >= 45) {
                // wrap to next hour
                minute = 0;
                hour = hour === 23 ? 0 : hour + 1;
            } else if (minute < 15) {
                minute = 15;
            } else if (minute < 30) {
                minute = 30;
            } else if (minute < 45) {
                minute = 45;
            }
            setTime(localTimeSerialize({hour: hour, minute: minute}));
            
            //const newDate: Date = Date(time);
            //const newDateObj = new Date(oldDateObj.getTime() + diff*60000); 
        } else if (key === "ArrowDown") {
            console.info("ArrowDown clicked. Do negative step for: " + time);
            ev.preventDefault();
            ev.stopPropagation();
            const hhmm : LocalTime = localTimeDeserialize(time as string);
            let hour = hhmm.hour;
            let minute = hhmm.minute;

            if (minute > 45 || minute === 0) {
                // wrap to previous hour
                minute = 45;
                hour = hour === 0 ? 23 : hour - 1;
            } else if (minute > 30) {
                minute = 30;
            } else if (minute > 15) {
                minute = 15;
            } else if (minute <= 15) {
                minute = 0;
            }
            setTime(localTimeSerialize({hour: hour, minute: minute}));
        }

    }
    
    // Note: The standard browser time picker (type="time") has lots of quirks. Cursor keys (ArrowUp, ArrowDown) may or may not work.
    // If they work, the "step" value of 15 is either used or ignored (using step 1). The workaround above is also suboptimal. It works
    // nicely for Chrome, but in Firefox the arrow keys also increment the hour field (ev.preventDefault() does not do anything).
    // So in the long run the TouredoTimePicker should be migrated from <TextField> to a custom TimePicker, e.g. from MUI.
    return (
                <TextField className={className} id={id} margin="dense" disabled={disabled}
                 sx={{boxSizing: 'content-box', minWidth: "11em"}}
                    size="small"
                    variant={small ? "standard" : "outlined"}
                    label={label} type="time" value={time}
                    onChange={e => { setTime(sanifyTime(e.target.value)) }}
                    onKeyUp={e => { doStepOrDefault(e, e.key) }}
                    onKeyDown={e => { preventStep(e, e.key) }}
                    onKeyPress={e => { preventStep(e, e.key) }}
                    InputLabelProps={{ shrink: true  }}
                    InputProps={{
                        inputProps: { minuteStep: 15  },
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton edge="end" size="small" disabled={disabled} onClick={() => { setTime("") }}><ClearIcon color="error" /></IconButton>
                            </InputAdornment>
                          ),
                        }}
                />
           );
};

export default TouredoTimePicker;
