import { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import AddLocationIcon from '@mui/icons-material/AddLocation';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';

import { DragDropContext, Droppable, Draggable, DropResult} from "react-beautiful-dnd";

import * as Config from "../../Config";

import {UserContext} from "../../contexts/UserContext";


import { AccessLevel, EnrichedTour, Tour, TourEvent, TourMetadata} from "../../api"
import { EnrichedUser } from "../shared/Types";
import { formatYMD_weekday, parseLocalDate } from "../tools/DateUtil";

import TourCard from "./TourCard";
import { Action } from "./EventActionMenu";
import { TourAnalysis, TourEventAnalysis, analyzeTour } from "../tools/TourAnalyzer";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./Plan.module.scss";


const appendModes = [
	{
		value: 'nextDay',
		label: 'Next',
	},
	{
		value: 'sameDay',
		label: 'Same',
	}
];


interface PlanProps {
	tourEvents: TourEvent[];
	externalErrorMessage: string;
	
	switchViewIcon: boolean;
    switchView: () => void;

	
	loadTourEventsWithArgs: (tour: Tour, metadata: TourMetadata | undefined, moveMap: boolean) => void; 
	
	appendMode: string;
	setAppendMode : (appendMode: string) => void;
	
	markActiveEvent: (tourevent: TourEvent) => void;
	
	setModalEditTourShow : (show: boolean) => void;
	setModalAddEventShow : (show: boolean) => void;
	setModalEditTourEventShow: (tourevent: TourEvent) => void;
    openLoginDialog: () => void;
}


const isWritable = (accessLevel: AccessLevel): boolean =>  {
   return accessLevel ===  AccessLevel.WRITE;
}


const loadTourMetadata = (touruuid: string,
         setTourMetadata: (tourMetadata: TourMetadata) => void,
         setWritable: (writable: boolean) => void,
         setErrormsg: (errormsg: string) => void): void => {
    axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    const url = Config.api_url + "routing/basic/" + touruuid;
    axios.get<TourMetadata>(url).then(response => {
        setTourMetadata(response.data);
        setWritable(isWritable(response.data.accessLevel));
        setErrormsg("");
    },
    () => {
        setErrormsg("Failed loading route");
        //setRouting(undef)
    });
};


const PlanningBox = ({ openLoginDialog, switchViewIcon, switchView, externalErrorMessage, tourEvents,
appendMode, setAppendMode,
setModalAddEventShow, setModalEditTourEventShow, setModalEditTourShow, loadTourEventsWithArgs, markActiveEvent }: PlanProps): ReactElement => {
    const userContext = useContext(UserContext);
    const user: EnrichedUser = userContext.user;
    const eTour: EnrichedTour = userContext.currentTour as EnrichedTour;
    

    const [writable, setWritable] = useState<boolean>(isWritable(eTour.metadata.accessLevel))

	const [errormsg, setErrormsg] = useState<string>(externalErrorMessage);
	const [routing, setRouting] = useState<TourMetadata>();


	
    useEffect(() => {
        // We must (re)load metadata on various accasions. Obviously when the tour changes, but also when the user changes (e.g. logout)
    		loadTourMetadata(eTour.tour.uuid as string, setRouting, setWritable, setErrormsg);
   }, [eTour, tourEvents, userContext.user]
   );




    const onActionMenu = (action: Action, tourEventId: string): void  => {
        let methodName = undefined; 
        switch (action) {
            case Action.START_NEW_DAY: methodName = "startnewday"; break;
            case Action.JOIN_FORMER_DAY: methodName = "joinformerday"; break;
        }
        if (methodName) {
            axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

            const url = Config.api_url + "tourevents/" + tourEventId + "/" + methodName;
            axios.patch<void>(url
            ).then(() => {
                loadTourEventsWithArgs(eTour.tour, undefined, false);
                resetError();
            }).catch(error => {
                setErrormsg("Failed performing action on tour event");
                // Reload, as it could be a partial error.
                loadTourEventsWithArgs(eTour.tour, undefined, false);
            });
        }
    }

	const moveEventBefore = (fromUuid: string, toBeforeUuid: string): void => {
		moveEvent(fromUuid, "before", toBeforeUuid);
	}

	const moveEventAfter = (fromUuid: string, toAfterUuid: string): void => {
		moveEvent(fromUuid, "after", toAfterUuid);
	}

	const moveEvent = (fromUuid: string, placement: string, toUuid: string,): void => {
		axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

		const url = Config.api_url + "tourevents/" + fromUuid + "/reorder?placement=" + placement + "&toUuid=" + toUuid;
		axios.patch<void>(url
		).then(response => {
			loadTourEventsWithArgs(eTour.tour, undefined, false);
			resetError();
		}).catch(error => {
			setErrormsg("Failed moving tour event");
			// Reload, as it could be a partial error. Explanation: Usually the REST service will only
			// update day and intraday of "fromUuid". But if the backend finds that the target "intraday"
			// is already occupied by a different event it also moves that intraday. This can continue
			// for all the remaining TourEvent entries of that day. So, if some TourEvnets can be updated and
			// some cannot, we should reload to present the outcome to the user.
			loadTourEventsWithArgs(eTour.tour, undefined, false);
		});
	};

	const handleMoveTourUp = (tourEvent: TourEvent): void => {
		const tourEventUuid = tourEvent.uuid as string;

		let eventTarget;
		for (const tevent of tourEvents) {
			if (tevent.uuid === tourEventUuid) {
				// event that should be moved found. Lets move it to the eventTarget
				if (eventTarget) {
					if (tourEvent.day === eventTarget.day) {
						moveEventBefore(tourEventUuid, eventTarget.uuid as string); // same day. Move BEFORE eventTarget
					} else {
						moveEventAfter(tourEventUuid, eventTarget.uuid as string); // move to a day earlier, AFTER eventTarget
					}
				}  // else is "impossible" (it would mean that we try to move the first element up)
				break;
			} else {
				// Remember the event that is before the one to reorder
				eventTarget = tevent;
			}
		} // for
	};

	const handleMoveTourDown = (tourEvent: TourEvent): void => {
		const tourEventUuid = tourEvent.uuid as string;

		let eventToMoveFound = false;
		for (const tevent of tourEvents) {
			if (eventToMoveFound) {
				if (tourEvent.day === tevent.day) {
					moveEventAfter(tourEventUuid, tevent.uuid as string);
				} else {
					// The event moves to the start of the next day (before tevent)
					moveEventBefore(tourEventUuid, tevent.uuid as string);
				}
				break;
			}
			if (tevent.uuid === tourEventUuid) {
				// event that should be moved found. Lets move it after the next that appears
				eventToMoveFound = true;
			}
		} // for
	};

	const renderToursAsTable = (tour: Tour, toursArg: TourEvent[], tourAnalysis: TourAnalysis): ReactElement[] => {
        let dayIndex : number = 1;
        let currentDate = tour.start && tour.start.length >0 ? parseLocalDate(tour.start) : undefined;

        let lastDay = -1;
        let currentDay = 0;
        
		return (

		toursArg.map((tourevent, index) => {
			if (index === 0) {
				lastDay = -1; // ensure first header is rendered
			} else {
				lastDay = currentDay;
			}
			currentDay = toursArg[index].day;

            let headerRequired = index === 0 || currentDay !== lastDay;
            if (headerRequired && index !== 0) {
               dayIndex = dayIndex + 1;
               if (currentDate) {
                    currentDate = currentDate.add(1, "day");
                }
            }

			let dayHeader = headerRequired  ? <>{currentDate !== undefined ? formatYMD_weekday(currentDate, user.userProfile.language) : "Day " + dayIndex}</> : "";

			const upHandler = index >= 1;
			const downHandler = index < toursArg.length - 1;

			const tourEventAnalysis: TourEventAnalysis | undefined = tourAnalysis.eventAnalysis.get(tourevent.uuid as string);
			//const dayAnalysis   : DayAnalysis | undefined = tourAnalysis.dayAnalysis.get(tourevent.uuid as string);

			return (

				<span key={index}>
					{dayHeader}

				
					<Draggable key={tourevent.uuid} draggableId={tourevent.uuid as string} index={index} isDragDisabled={!writable}>
	                  {(provided, snapshot) => (
    		                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
							<TourCard tourEvent={tourevent} eTour={eTour}
								onClick={() => { setModalEditTourEventShow(tourevent); }}
								moveEventUp={upHandler ? handleMoveTourUp : undefined}
								moveEventDown={downHandler ? handleMoveTourDown : undefined}
								onAction={onActionMenu}
								notifyCardSelected={markActiveEvent}
								tourEventAnalysis={tourEventAnalysis}
							/>
					 </div>
                  )}
                  </Draggable>
				</span>
			)
		})
	);
	}

	const resetError = (): void => {
		setErrormsg("");
	}

	const  handleDrop = (result: DropResult) =>  {
    		// dropped outside the list
        if (!result.destination) {
            return;
        }

		const destArrayIndex = result.destination.index;
		const sourceArrayIndex = result.source.index;
        if (destArrayIndex === sourceArrayIndex) {
            return; // position is the same as before
        } else if (destArrayIndex < sourceArrayIndex ) {
			console.log("Deopped upwards: " + JSON.stringify(result, null, 4) )
  	        moveEventBefore(tourEvents[sourceArrayIndex].uuid as string, tourEvents[destArrayIndex].uuid as string);
  	    }
        else {
	        console.log("Dropped downwards: " + JSON.stringify(result, null, 4) )
	        moveEventAfter(tourEvents[sourceArrayIndex].uuid as string, tourEvents[destArrayIndex].uuid as string);
	    }
    }
    


	return (
		<div className={lstyles.toplevel}>
		{errormsg ? <><span className={lstyles.errormsg}> {errormsg} <HelpIcon sx={{ color: "yellow" }} fontSize="small" /></span></> : ""}


		<div className={styles.tourTitle}>
			    {eTour.tour.name}
			    {switchViewIcon && <>
			    &nbsp; <Button color="secondary" variant="contained" size="small" onClick={() => { switchView(); }}> <MapIcon/>  </Button ></> }
		</div>
		
		{routing && (<div className={styles.tourLocation}>{Number(routing.distance).toFixed(1)}  {routing.distanceUnit} (air line)</div>)}


    		<div className={lstyles.vertbox}>
        		<div className={styles.horButtonsRow}>
    				<Tooltip enterDelay={1000} title="Add an event">
        				<IconButton size="small" color="primary" disabled={!writable} onClick={() => { setModalAddEventShow(true); }}> <AddLocationIcon /></IconButton >
                    </Tooltip>
						
			    	<TextField select size="small" label="Day" value={appendMode} 	onChange={(event) => { setAppendMode(event.target.value) }} >
				        {appendModes.map((option) => (
					       <MenuItem key={option.value} value={option.value}> {option.label} 	</MenuItem> ))}
			        </TextField>
                    <IconButton color={"info"} size="small" onClick={() => { setModalEditTourShow(true); }}> {user.loggedIn ? <EditIcon /> : <InfoIcon/>} </IconButton >

    			</div>
            {!user.loggedIn &&
                <div>
                    <span className={lstyles.poiBarMessage}>
                        <Button color="primary"  variant="outlined" onClick={() => {openLoginDialog(); }} >Login</Button>
                        <br/> to use this tour as a template
                    </span>
                </div>
            }
        </div>
		<hr />

		<div className={lstyles.list}>
			{tourEvents && tourEvents.length ? (
				<div className={lstyles.card}>
					<DragDropContext onDragEnd={handleDrop}>
				        <Droppable droppableId="tourlist-droppable">
          					{(provided, snapshot) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
							{
								renderToursAsTable(eTour.tour, tourEvents, analyzeTour(tourEvents))
							}
							{provided.placeholder}
				            </div>
				          )}
				        </Droppable>
					</DragDropContext>
				</div>)
				: <>
				       Add your first event by clicking an icon on the map,<br/> via the location search on the Map or using the search button <AddLocationIcon /> above.
				       </> 
			}
		</div>
</div>
		
	);
};

export default PlanningBox;