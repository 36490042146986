import React, { ReactElement } from "react";

import MuiAlert, { AlertProps, AlertColor  } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface NotificationOverlayProps {
    message: string;
    setMessage: (msg: string) => void;
    severity: AlertColor;
    setSeverity: (severity: AlertColor) => void;
    show: boolean;
    setShow: (active: boolean) => void;
}

export const NotificationOverlay = ({message, severity, show, setShow} : NotificationOverlayProps) : ReactElement => {
 const hideModal = () => {
     setShow(false);
 };


    const handleGrowlClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        hideModal();
    };

     return (
         <>
         {show &&
                 <Snackbar open={show} anchorOrigin={{vertical: 'top', horizontal:'right'}} autoHideDuration={2500} onClose={handleGrowlClose}>
                <Alert severity={severity} sx={{ width: '100%' }}>
                  {message}
                </Alert>
            </Snackbar>
     }
            </>
     );
}

