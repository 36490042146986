import axios from "axios";

import * as Config from "../../Config";

import { ReactElement } from "react";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttractionsIcon from '@mui/icons-material/Attractions';
import PlaceIcon from '@mui/icons-material/Place';

import {Location} from "../shared/Geo"
import {GeoCoordinate, OSMBoundingBox, ProviderRef} from "../../api"


// This should only contain providers that have attractions (parks, unesco-whc)
export const providerMap: Map<string,string> = new Map([
    ["rcdb", "Theme parks"],
    ["unesco-whc", "Unesco World Heritage"]]
    );





export const providerLinkByRefOrCoordinate = (providerRef : ProviderRef | undefined, geoCoordinate : GeoCoordinate | undefined) : string | undefined => {
	if (providerRef?.provider === "rcdb")
    		return "https://rcdb.com/" + providerRef.id + ".htm";
	if (providerRef?.provider === "unesco-whc")
    		return "https://whc.unesco.org/en/list/" + providerRef.id;
    if (geoCoordinate)
            return "https://www.openstreetmap.org/#map=17/" + geoCoordinate.latitude + "/" + geoCoordinate.longitude;
	return undefined;    		
};

export const providerLinkByRef = (providerRef? : ProviderRef) : string | undefined => {
    return providerLinkByRefOrCoordinate(providerRef, undefined);
};


export const providerLinkByRefOrCoordinateLocation = (providerRef? : ProviderRef) : string | undefined => {
    if (providerRef?.provider === "rcdb")
            return "https://rcdb.com/" + providerRef.id + ".htm";
    if (providerRef?.provider === "unesco-whc")
            return "https://whc.unesco.org/en/list/" + providerRef.id;
    if (providerRef?.provider === "osm")
            return "https://www.openstreetmap.org/#map=17/54.07478/10.77906?" + providerRef.id;
    return undefined;           
};



export const osmLatLonLink = (lat : number, lon: number) : string => {
    return "https://www.openstreetmap.org/?mlat=" + lat + "&mlon=" + lon + "&zoom=16";
};

export const providerIcon =(providerRef? : ProviderRef) : ReactElement  => {
	if (providerRef?.provider === "rcdb")
    		return <AttractionsIcon/>;
	if (providerRef?.provider === "unesco-whc")
    		return <AccountBalanceIcon/>;
	return <PlaceIcon/>;    		

};


interface PoiSearchResult {
    matchQuality : number;
    result : Location ;
}

interface LocationAnswer {
    locations: Location[];
    error?: string;
}


export const findLocation = (location: string, city: string, country: string, center: GeoCoordinate | undefined, boundingBox: OSMBoundingBox | undefined ) : Promise<LocationAnswer> => {
        axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

        const geoParameter = center ? "&geoCoordinate=" + center.latitude + "," + center.longitude : "";
        const bbParameter = boundingBox ? "&boundingBox=" + boundingBox.south + "," + boundingBox.north + "," + boundingBox.west + "," + boundingBox.east : "";
        const url = Config.poi_url + "pois?q=" + encodeURIComponent(location) + "&city=" + encodeURIComponent(city) + "&cc=" + encodeURIComponent(country) + geoParameter + bbParameter;
        return axios.get<PoiSearchResult[]>(url).then(response =>
            {
                const foundLocations = response.data;
                const newLocations = [];
                for (let i=0; i < foundLocations.length; i++) {
                    newLocations.push(foundLocations[i].result);
                }
                return {locations: newLocations, errors: undefined};
            },
            () => {
				return {locations: [], errors: "Error searching for locations"};
            });
    };