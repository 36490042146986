import { ReactElement, useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';


import OpeningPicker from "./OpeningPicker"
import TouredoDatePicker from "../shared/TouredoDatePicker";

import * as Config from "../../Config";
import {AccountStatus, DataQuality, DateAsString, DayOfWeek,
	OpeningPeriod, OpeningPerWeekday, OpeningHours, OpeningPeriodType,
 	ProviderRef} from "../../api";
import { CoreMarker, EnrichedUser } from "../shared/Types"
import { formatDateYMD, parseLocalDate } from "../tools/DateUtil";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./EditSeasonDialog.module.scss";

interface EditProps {
    opening?: OpeningPeriod;
    // Edit type
    updateAction: boolean;
    isExcpetion: boolean;

    // What
    location: CoreMarker;
    seasonStart: string;
    
    // Tech
    show: boolean;
    hideCallback: (dataChanged: boolean) => void;
    user: EnrichedUser;
}


// A copy of this interface is in EditSeasonDialog nad OpeningPicker
interface DefaultOpening {
    opens?: DateAsString;
    closes?: DateAsString;
}


//const openingHoursClosed : OpeningHours = {open: false, dataQuality: DataQuality.Contributed};
const openingHoursOpen : OpeningHours = {open: true, dataQuality: DataQuality.Contributed};

const defaultOpening : DefaultOpening = { opens: "10:00", closes: "18:00" };

const EditSeasonDialog = ({opening, location, isExcpetion, updateAction, user, show, hideCallback, seasonStart}: EditProps): ReactElement => {
    // Editing controls
    const proposedSeasonEnd = formatDateYMD(parseLocalDate(seasonStart).add(150, 'day'));
    const [startDate, setStartDate] = useState<Date | string>(seasonStart);
	const [endDate, setEndDate] = useState<Date | string>(proposedSeasonEnd);
    const [notesTF, setNotesTF] = useState<string>("");
    const [confirmed, setConfirmed] = useState<boolean>(false);
    
    // WEEK
    const [opw0, setOpw0] = useState<OpeningHours>(opening?.weekdays.standard ? opening?.weekdays.standard : openingHoursOpen);
    // MO - FR
    const [opw1, setOpw1] = useState<OpeningHours | undefined>(undefined);
    const [opw2, setOpw2] = useState<OpeningHours | undefined>(undefined);
    const [opw3, setOpw3] = useState<OpeningHours | undefined>(undefined);
    const [opw4, setOpw4] = useState<OpeningHours | undefined>(undefined);
    const [opw5, setOpw5] = useState<OpeningHours | undefined>(undefined);
    const [opw6, setOpw6] = useState<OpeningHours | undefined>(undefined);
    const [opw7, setOpw7] = useState<OpeningHours | undefined>(undefined);

    const [defaultOpeningTime, setDefaultOpeningTime] = useState<DefaultOpening>(defaultOpening);




    const setOpw = (dayOfWeek: DayOfWeek | undefined, openingHours: OpeningHours| undefined) => {
        if (openingHours) {
            // Set the defaultOpeningTime, if it was defined. Otherwise do nothing, which means keep the older defaults.
            // If there are no defaults yet, set some default strings (they could also be empty strings, but NOT undefined)
            const opensDefault = openingHours.opens ? openingHours.opens : defaultOpening.opens;
            const closesDefault = openingHours.closes ? openingHours.closes : defaultOpening.closes;
            setDefaultOpeningTime({ opens: opensDefault, closes: closesDefault } );
        }
        
        switch (dayOfWeek) {
            case DayOfWeek.MONDAY: setOpw1(openingHours); break;
            case DayOfWeek.TUESDAY: setOpw2(openingHours); break;
            case DayOfWeek.WEDNESDAY: setOpw3(openingHours); break;
            case DayOfWeek.THURSDAY: setOpw4(openingHours); break;
            case DayOfWeek.FRIDAY: setOpw5(openingHours); break;
            case DayOfWeek.SATURDAY: setOpw6(openingHours); break;
            case DayOfWeek.SUNDAY: setOpw7(openingHours); break;
            default: setOpw0(openingHours as OpeningHours); break;
        }        
    }

	// Copies the data from an existing OpeningPeriod to the editing fields 
    const handleSelectForEdit = (opening : OpeningPeriod) => {
		const wd : { [P in DayOfWeek]?: OpeningHours } = opening.weekdays.weekdays;
		setStartDate(opening.from);
		setEndDate(opening.to || "");
        //setWeekdays(opening.weekdays);
        setNotesTF(opening.description);
		setConfirmed(opening.confirmTime !== undefined && opening.confirmTime !== null);
		
		const ws : OpeningHours = opening.weekdays.standard;
		console.log("OPENS:", ws.opens);
		setOpw0(ws);
		setOpw1(wd.MONDAY || undefined);
		setOpw2(wd.TUESDAY || undefined);
		setOpw3(wd.WEDNESDAY || undefined);
		setOpw4(wd.THURSDAY || undefined);
		setOpw5(wd.FRIDAY || undefined);
		setOpw6(wd.SATURDAY || undefined);
		setOpw7(wd.SUNDAY || undefined);
	}

    
   
    useEffect(() => {
        if (updateAction) {
            handleSelectForEdit(opening as OpeningPeriod)
        } else {
            //setStartDate("");
            //setEndDate("");
            setNotesTF("");
            setConfirmed(false);
        }
    } , [opening, updateAction]);


    const mergeSeason = (): void => {
        let wd: { [P in DayOfWeek]?: OpeningHours } = {};
        wd.MONDAY = opw1;
        wd.TUESDAY = opw2;
        wd.WEDNESDAY = opw3;
        wd.THURSDAY = opw4;
        wd.FRIDAY = opw5;
        wd.SATURDAY = opw6;
        wd.SUNDAY = opw7;
        
        const openingPerWeekday : OpeningPerWeekday = { standard: opw0, weekdays: wd };

        const confirmUserId = confirmed ? user.userId : undefined;

        const endDateString = endDate.toString();
        const startDateString = startDate.toString();
        // Sometimes only the start date of an OpeningPeriod is known, e.g. if a
        const newSeason : OpeningPeriod = {
            uuid: "", // set in backend
            userId: user.userId as number,
            providerRef: location.providerRef as ProviderRef,
            type: isExcpetion ? OpeningPeriodType.EXCEPTION : OpeningPeriodType.SEASON,
            from: startDateString,
            to: endDateString.length === 0 ? undefined : endDateString,
            quality: DataQuality.Contributed,
            description: notesTF,
            weekdays: openingPerWeekday,
            mtime: 0, // set in backend
            confirmUserId: confirmUserId,
            confirmTime: undefined // set in backend
        };
        let httpPromise: Promise<AxiosResponse<OpeningPeriod, any>>;
        if (updateAction) {
            newSeason.uuid = (opening as OpeningPeriod).uuid;
            httpPromise = axios.put<OpeningPeriod>(Config.api_url + "opening/" + newSeason.uuid, newSeason);
        }
        else {
            httpPromise = axios.post<OpeningPeriod>(Config.api_url + "opening", newSeason);
        }
        httpPromise.finally( () => {
            // When we reeach this place, the season was persisted. We can safely call hideCallback(true), which
            // loads the (persisted, and thus not stale) season.
            hideCallback(true);
        });
    };
    
    // Start date must be set. If end date can be set, it is a fully specified season. Otherwise, it is a season start date. 
    const startDateValid : boolean = startDate.toString().length > 0;
    const endDateString = endDate.toString();
    const endDateValid : boolean = endDateString.length === 0 || endDateString.length >= 6;
    const formValid : boolean = startDateValid && endDateValid;
    const writable = user.accountStatus === AccountStatus.User || user.accountStatus === AccountStatus.Unconfirmed; // TODO Should be an admin function  

    return (
        <Dialog open={show}
            PaperProps={{ sx: { width:"50vw", minWidth: "40vw", padding:"0px" } }}

              onClose={() => hideCallback(false)}>

                <DialogTitle>{updateAction ? "Edit" : "Add new"} {isExcpetion ? OpeningPeriodType.EXCEPTION : OpeningPeriodType.SEASON}: {location.name}</DialogTitle>

                <DialogContent>
                <div className={styles.modalcontent}>
                   <div className={lstyles.container}>

                    <TextField className={lstyles.chead1} value={notesTF}  label="Season description"   onChange={e => { setNotesTF(e.target.value) }} />

                    <FormControlLabel className={lstyles.chead2} disabled
                            control={<Switch checked={confirmed} onChange={event => setConfirmed(event.target.checked)} />}
                           label="Confirm season" />

                    <TouredoDatePicker className={lstyles.c11} editDate={startDate} setDate={setStartDate} disabled={false} id="season-start" label="Season start" />
                    <TouredoDatePicker className={lstyles.c12} editDate={endDate} setDate={setEndDate} disabled={false} id="season-end" label="Season end" />

                   <OpeningPicker className={lstyles.c13} writable={writable} useDefault={false} defaultOpeningTime= {defaultOpeningTime} openingHours={opw0} onUpdate={setOpw} />
					
					
					<div className={lstyles.c23x}>
				    Exceptions for specific weekdays 
					</div>
					



		                   <OpeningPicker className={lstyles.c31} writable={writable} useDefault={true} defaultOpeningTime= {defaultOpeningTime} openingHours={opw1} onUpdate={setOpw} dayOfWeek={DayOfWeek.MONDAY} />
		                   <OpeningPicker className={lstyles.c32} writable={writable} useDefault={true} defaultOpeningTime= {defaultOpeningTime} openingHours={opw2} onUpdate={setOpw} dayOfWeek={DayOfWeek.TUESDAY} />
		                   <OpeningPicker className={lstyles.c33} writable={writable} useDefault={true} defaultOpeningTime= {defaultOpeningTime} openingHours={opw3} onUpdate={setOpw} dayOfWeek={DayOfWeek.WEDNESDAY} />
		                   <OpeningPicker className={lstyles.c34} writable={writable} useDefault={true} defaultOpeningTime= {defaultOpeningTime} openingHours={opw4} onUpdate={setOpw} dayOfWeek={DayOfWeek.THURSDAY} />
		                   <OpeningPicker className={lstyles.c35} writable={writable} useDefault={true} defaultOpeningTime= {defaultOpeningTime} openingHours={opw5} onUpdate={setOpw} dayOfWeek={DayOfWeek.FRIDAY} />
		                   <OpeningPicker className={lstyles.c36} writable={writable} useDefault={true} defaultOpeningTime= {defaultOpeningTime} openingHours={opw6} onUpdate={setOpw} dayOfWeek={DayOfWeek.SATURDAY} />
		                   <OpeningPicker className={lstyles.c37} writable={writable} useDefault={true} defaultOpeningTime= {defaultOpeningTime}openingHours={opw7} onUpdate={setOpw} dayOfWeek={DayOfWeek.SUNDAY} />
		                   

			
                  </div>
            </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => {hideCallback(false);  } }>Cancel</Button>
                <Button variant="outlined" disabled={!formValid} onClick={() => {mergeSeason();  } }>OK</Button>
            </DialogActions>
        </Dialog>

   );
};

export default EditSeasonDialog;
