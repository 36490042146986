import { ReactElement, useState } from "react";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import {CoreMarker} from "../shared/Types"
import MultiItemPicker from "../shared/MultiItemPicker";
import { CountryMap } from "../shared/GeoConstants";
import {lastCCs, lastCity, lastLocation, pushCCs, pushCity, pushLocation} from "../Search/SearchTerms"


interface SectionProps {
    addTourEvent: (location : CoreMarker ) => void;
    setLocation: (location: CoreMarker) => void;
}


const AddEventDialogFreetextSection = ({addTourEvent, setLocation}: SectionProps): ReactElement => {
    const [locationTF, setLocationTF] = useState<string>(lastLocation());
    const [cityTF, setCityTF] = useState<string>(lastCity());
    const [country, setCountry] = useState<string[]>(lastCCs());
    
    const handleAddTourEvent = (): void => {
        const tourEvent : CoreMarker = {
            name: locationTF,
            address: { city: cityTF, country: country[0] || ""}
        };
        console.info("add from freetext")
        addTourEvent(tourEvent);
    }
    
    return (
        <>
           <p>
            Add a custom activity, or a location that is not shown on the Map or has no exact location.
            You can also note down activities, like "<i>roam the old city center</i>", "<i>candlelight dinner</i>", "<i>pub crawl</i>" or a "<i>hotel stay</i>". 
            </p>

            <TextField required id="addevent-location"  label="Location or activity" fullWidth autoFocus={true} type="search" value={locationTF}
                 onChange={e => { setLocationTF(e.target.value); pushLocation(e.target.value); }} />
            <TextField id="addevent-city"  label="City" fullWidth type="search"  value={cityTF}
                 onChange={e => {setCityTF(e.target.value); pushCity(e.target.value); }}    />
            <MultiItemPicker onChange={e => {setCountry(e); pushCCs(e);}} title="Country" selected={country} keyValues={CountryMap} multiple={true} colonSelectorFormat={true}/>

             <Button variant="contained" disabled={locationTF.trim() === ""} onClick={() => handleAddTourEvent()}>Add event</Button>

        </>
    );
}

export default AddEventDialogFreetextSection;
