import { ReactElement, useEffect } from "react";

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButton from '@mui/material/ToggleButton';

import { DataQuality, DateAsString, DayOfWeek, OpeningHours } from "../../api";
import TimeRangePicker from  "../shared/TimeRangePicker";

import lstyles from "./OpeningPicker.module.scss";

/** 
     The OpeningPicker allow to edit a single opening and closing time.
*/ 

// A copy of this interface is in EditSeasonDialog nad OpeningPicker
interface DefaultOpening {
    opens?: DateAsString;
    closes?: DateAsString;
}


/** The OpeningPicker allows to edit opening hours for a single DayOfWeek */
interface EditProps {
	dayOfWeek?: DayOfWeek;
	openingHours: OpeningHours | undefined;
	defaultOpeningTime: DefaultOpening | undefined;
	useDefault: boolean;
	className?: string;
	writable: boolean;	
	onUpdate: (dayOfWeek: DayOfWeek | undefined, openingHours: OpeningHours | undefined) => void;
}




const OpeningPicker = ({dayOfWeek, openingHours, defaultOpeningTime, useDefault, className, writable, onUpdate}: EditProps): ReactElement => {
	
	useEffect(() => {
        if (openingHours) {
		  console.log("Update OpeningHours of day " + dayOfWeek + ": " + openingHours.opens + " - " + openingHours.closes);
	  }
	},
	 [dayOfWeek, openingHours]);

	
	const createOpeningHours = (override: boolean): OpeningHours | undefined => {
        if (override) {
            const opens = openingHours?.opens ? openingHours?.opens : defaultOpeningTime?.opens;
            const closes = openingHours?.closes ? openingHours?.closes : defaultOpeningTime?.closes;
            const isOpen = opens && closes ? true : false;
            const dataQuality = openingHours?.dataQuality ? openingHours.dataQuality : DataQuality.Contributed;
             
            const oh : OpeningHours = {open:isOpen, opens:opens, closes:closes, dataQuality:dataQuality };
            console.log("createOpeningHours(): " + dayOfWeek + ": ", override, oh);
            return oh;
        } else {
            console.log("createOpeningHours(): " + dayOfWeek + ": ", override, "undefined");
            return undefined;
        }
    }
    
    const toggleOpeningHours = ():  OpeningHours | undefined => {
        return createOpeningHours(openingHours === undefined);
    }
    
    const toggleIsOpen = (show: boolean):  OpeningHours | undefined => {
        // mehtod overlaps with createOpeningHours(). We mightt want to unify them later
        const opens = openingHours?.opens ? openingHours?.opens : defaultOpeningTime?.opens;
        const closes = openingHours?.closes ? openingHours?.closes : defaultOpeningTime?.closes;
        const isOpen = show;
        const dataQuality = openingHours?.dataQuality ? openingHours.dataQuality : DataQuality.Contributed;

        const oh : OpeningHours = {open:isOpen, opens:opens, closes:closes, dataQuality:dataQuality };
        console.log("createOpeningHours(): " + dayOfWeek + ": ", show, oh);
        return oh;
    }


    return (
		<div className={className}>
		<div className={lstyles.container}>
        		{dayOfWeek && 
                <ToggleButton className={lstyles.c1x} value={dayOfWeek.substring(0,3)} selected={openingHours !== undefined} disabled={dayOfWeek === undefined}
                     onChange={() => {onUpdate( dayOfWeek, toggleOpeningHours()) } } >
                        {dayOfWeek.substring(0,3)}
                </ToggleButton >
            }

               
               {openingHours && (<>
			     <FormControlLabel className={lstyles.c2x} 
	          	    control={<Checkbox checked={openingHours.open}
	          	     onChange={e => onUpdate( dayOfWeek, toggleIsOpen(e.target.checked))  } /> }  label="Open" /> 

                {openingHours.open && <>
                <TimeRangePicker  className={lstyles.c3x} range={ {from: openingHours.opens || "", to: openingHours.closes || ""} } writable={writable}
                    onUpdate={e => onUpdate( dayOfWeek, {...openingHours,  opens:e.from, closes:e.to } ) }
                 />
		      	</>
		      	}
			     </>
     		 )}
			
			
		</div>
		</div>	
   );
};

export default OpeningPicker;
