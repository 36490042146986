import React, { useState } from "react";
import axios from "axios";

import * as Config from "../../Config";
import { ImporterStatistics } from "../../api"

import lstyles from "./FileUpload.module.css";


const FileUpload = () => {
  const [uploadMessage, setUploadMessage] = useState<string>("");
  const [dragActive, setDragActive] = React.useState(false);
  const [imported, setImported] = React.useState<number>(0);
  const [duplicates, setDuplicates] = React.useState<number>(0);


  const handleUpload = async (file: File) => {
    const fileSizeOK = file && file.size <= 10000000;
    const fileTypeOK = file && file.type === "application/json";
    const fileNameOK = file && file.name.endsWith(".json");
    
    if (!fileSizeOK) {
        setUploadMessage("File too large. Maxmimum size 10MB.");
        return;
    }
    if (!fileNameOK || !fileTypeOK) {
        setUploadMessage("Bad file. It must be a json file from the Google maps export.");
        return;
    }
    
    setUploadMessage("Importing");
    const formData = new FormData();
 
        // Update the formData object
        formData.append(
            "myFile",
            file,
            file.name
        );
 
        axios.defaults.withCredentials = true;
        // Request made to the backend api
        // Send formData object
        const url = Config.api_url + "import/gmaps";
        axios.post<ImporterStatistics>(url, file, 
        { headers: {
            "Content-Type": "application/json",
            }}
            
        
        ).then(response => {
            const data = response.data;
            const newImported = imported + data.imported;
            const newDuplicate = duplicates + data.duplicate;
            setImported(imported => imported + data.imported);
            setDuplicates(duplicates => duplicates + data.duplicate);
            const duplicateMessage = newDuplicate > 0 ? " Ignored " + newDuplicate + " duplicates." : "";
            setUploadMessage("Upload finished. Imported " + newImported + " entries." + duplicateMessage);
        }).catch(() => {
            setUploadMessage("Upload failed. Please retry.");
        });

  };

  const handleUploads = async (files: File[]) => {
      setImported(0);
      setDuplicates(0);
      files.forEach(f => handleUpload(f));
  }
  
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
        const files : File[]  = Array.from(e.target.files)
      handleUploads(files);
    }
  };



  // handle drag events
  const handleDrag = function(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
const handleDrop = function(e: React.DragEvent) {
  e.preventDefault();
  e.stopPropagation();
  setDragActive(false);
  if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    // at least one file has been dropped so do something
    console.log(e.dataTransfer.files);
    handleUpload(e.dataTransfer.files[0]);
    //handleFiles(e.dataTransfer.files);
  }
};
  
  
  return (
    <>
    <form id={lstyles.formfileupload}
    onDragEnter={handleDrag}
     onSubmit={(e) => e.preventDefault()}>
      <input type="file" id="input-file-upload" multiple={true} onChange={handleFileChange} />
      <label htmlFor="input-file-upload" className={dragActive ? lstyles.labelfileuploaddragactive : lstyles.labelfileupload}>
        <div>
          Import Google maps timeline (e.g. 2023_SEPTEMBER.json)
          <br/> 
          <p>Drag and drop your file here.</p>
          <p>Or click too chose a file.</p>
          <br/>
          {uploadMessage}
        </div> 
      </label>
      { dragActive && <div id={lstyles.dragfileelement} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>


        
    </>
  );
};

export default FileUpload;