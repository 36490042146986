import axios from "axios";

import * as Config from "../../Config";

import { SearchGeocodedLocation } from "../../api"; 

interface LocationAnswer {
    locations: SearchGeocodedLocation[];
    error?: string;
}

export const geosearch = (location: string, countries: string[] ) : Promise<LocationAnswer> => {
    axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)
    // /api/search?q=miniatur+wunderland,hamburg&limit=400
    
    let url = Config.api_url + "search?q=" + encodeURIComponent(location);
    if (countries.length > 0) {
        url += "&countries=";
        url += countries.join(",");
    }
    url += "&limit=30";
    
    console.info("Geosearch: " + url);
    return axios.get<SearchGeocodedLocation[]>(url).then(response =>
        {
            return {locations: response.data, errors: undefined};
        },
        () => {
            return {locations: [], errors: "Error searching for locations"};
        });
};