import { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";

import {AlertColor} from '@mui/material/Alert';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginDialog from "./components/Account/LoginDialog"

import {EnrichedUser, ErrorHandler, ErrorTelegram} from "./components/shared/Types"
import {AccountStatus, AuthResponse, Bookmark, DiaryEntry, User} from "./api";
import AccountProfileDialog from "./components/Account/AccountProfileDialog";

// Contexts
import {GUEST, UserContext} from "./contexts/UserContext";
import {NotificationContext} from "./contexts/GlobalDialogContext";
import { BookmarkDialogContext, DiaryDialogContext } from './contexts/GlobalDialogContext';


import Home from "./components/Home/Home";
import Info from "./components/Info/Info";
import Plan from "./components/Plan/Plan";
import Diary from "./components/Diary/Diary";
import Footer from "./components/NavigationBar/Footer";
import Bookmarks from "./components/Bookmarks/Bookmarks";
import Search from "./components/Search/Search";
import NavigationBar from "./components/NavigationBar/NavigationBar";


import { insertEntity as insertEntityBm, updateEntity as updateEntityBm, deleteEntity as deleteEntityBm} from "./httpclient/BookmarkDAO";
import { insertEntity as insertEntityVisit, updateEntity as updateEntityVisit, deleteEntity as deleteEntityVisit} from "./httpclient/DiaryEntryDAO";


import {NotificationOverlay} from "./dialogs/NotificationOverlay";
import EditDiaryEntryDialog from "./components/Diary/EditDiaryEntryDialog";
import EditBookmarkDialog from "./components/Bookmarks/EditBookmarkDialog";

import * as Config from "./Config";

import lstyles from "./App.module.scss";

const errorHandler: ErrorHandler = (error?: ErrorTelegram) => {
    console.log("TODO Add error hanlder. For example use the growl: " + error)
}

const messageForAccountStatus = (accountStatus: AccountStatus) : string => {
    switch(accountStatus) {
        case AccountStatus.User:
            return "Logged in";
        case AccountStatus.Unconfirmed:
            return "Logged in";  // "Please confirm your account."
        case AccountStatus.Guest:
            return "You are now a guest (not logged in)"
        default:
            return "Not logged in"
    }
}


    



    const handleInsertBookmark = (bookmark : Bookmark): void => {
        insertEntityBm(bookmark, errorHandler);
    };

    const handleUpdateBookmark = (bookmark : Bookmark): void => {
        updateEntityBm(bookmark, errorHandler);
    };

    const handleDeleteBookmark = (bookmark : Bookmark): void => {
        deleteEntityBm(bookmark, errorHandler);
    };


    const handleInsertVisit = (visit : DiaryEntry): void => {
        insertEntityVisit(visit, errorHandler);
    };
    
    const handleUpdateVisit = (visit : DiaryEntry): void => {
        updateEntityVisit(visit, errorHandler);
    };

    const handleDeleteVisit = (visit : DiaryEntry): void => {
        deleteEntityVisit(visit, errorHandler);
    };






export const AppToplevel = () : ReactElement => {
    const [loginModalShow, setLoginModalShow] = useState<boolean>(false);
    const [accountProfileShow, setAccountProfileShow] = useState<boolean>(false);

    const userContext = useContext(UserContext);
    const notificationContext = useContext(NotificationContext);
    const bookmarkDialogContext = useContext(BookmarkDialogContext);
    const diaryDialogContext = useContext(DiaryDialogContext);
    


    // Shows either the login dialog, or the user profile dialog.
    const showHideLoginDialog = () => {
        setAccountProfileShow(userContext.user.loggedIn);
        setLoginModalShow(!userContext.user.loggedIn);
    };


    const setNotificationShowDebug = (active: boolean) => {
        console.log("Notification active=: " + active);
        notificationContext.setShow(true);
    }

    const showGrowl = (message: string, severity: AlertColor) => {
        notificationContext.setMessage(message);
        notificationContext.setSeverity(severity);
        setNotificationShowDebug(true);
    }

    const setUserFromAPI = (userFromAPI: User) => {
        const loggedInRes = userFromAPI.accountStatus === AccountStatus.User || userFromAPI.accountStatus === AccountStatus.Unconfirmed;
        userContext.setUser({loggedIn:loggedInRes, ...userFromAPI});
        showGrowl(messageForAccountStatus(userFromAPI.accountStatus), loggedInRes ? "success" : "info");
    }
    
    useEffect(() => {
        if (! userContext.user.loggedIn) {
           // Try to auto-login (via token)
           axios.defaults.withCredentials = true; // enable cookies (especially the Auth Token)

            let resultingUser: EnrichedUser;
            let message: string;
            let severity: AlertColor = "info"; // success, info, warning, error

           axios.get<AuthResponse>(Config.auth_url + "tokenlogin")
            .then(response => {
                const authResponse: AuthResponse = response.data;
                const authRU: User = authResponse.user;
                
                message = messageForAccountStatus(authRU.accountStatus);
                switch (authRU.accountStatus) {
                    case AccountStatus.User:
                        resultingUser = {loggedIn:true, ...authRU};
                        severity = "success";
                        break;
                    case AccountStatus.Unconfirmed:
                        resultingUser = {loggedIn:true, ...authRU};
                        severity = "success";
                        break;
                    case AccountStatus.Guest:
                        resultingUser = GUEST;
                        severity = "info";
                        break;
                    default:
                        resultingUser = GUEST;
                        severity = "warning";
                        break;
                }
                userContext.setUser(resultingUser);
                //showGrowl(message, severity);
            }).catch((error) => {
                if (error.response) {
                    const httpStatus = error.response.status;
                     switch (httpStatus) {
                        // The tokenlogin will and has to fail if a user is not logged in. This is normal behavior.
                        // As this is an implict login try (that failed), we should not scare the user. 
                        case 403: message = ""; break;
                         
                        default: severity = "error"; message = "Error reaching the touredo server. Cannot login."; break;
                     }
                     resultingUser = GUEST;
                     
                } else {
                    severity = "error"; message = "Error reaching the touredo server. Cannot login.";
                    resultingUser = GUEST;
                }
                userContext.setUser(resultingUser);
                showGrowl(message, severity);

            }); // catch error block
    }
    
    }, []);

    

    return (
        <>
        <BrowserRouter>
        <div className={lstyles.head}>
            <NavigationBar user={userContext.user} onUpdateUser={setUserFromAPI} onLoginButton={showHideLoginDialog}/>
        </div>
        
        <div className={lstyles.content}>
            <Routes>
                <Route path="/"  element={<Home />} />
                <Route path="/planning" element={<Plan openLoginDialog={showHideLoginDialog} switchBackToTourList={true} />} />
                <Route path="/tour/:tourid"  element={<Plan openLoginDialog={showHideLoginDialog} switchBackToTourList={false} />} />
                <Route path="/bookmarks"  element={<Bookmarks openLoginDialog={showHideLoginDialog} />} />
                <Route path="/diary"  element={<Diary openLoginDialog={showHideLoginDialog} />} />
                {/** <Route path="search" element={<Search/>} /> */}
                <Route path="/info"  element={<Info />} />
            </Routes>
        </div>

 {/* TOOD Remove Footer on the planning page */}
 {/* 
        <div className={lstyles.foot}>
            <Footer/>
        </div>
 */}
    </BrowserRouter>

    <NotificationOverlay
        message={notificationContext.message} setMessage={notificationContext.setMessage}
        severity={notificationContext.severity} setSeverity={notificationContext.setSeverity}
        show={notificationContext.show} setShow={notificationContext.setShow}
          />


    

    {loginModalShow && <LoginDialog modalShow={loginModalShow} setModalShow={setLoginModalShow} onUpdateUser={setUserFromAPI} />  }
    {accountProfileShow && <AccountProfileDialog modalShow={accountProfileShow} setModalShow={setAccountProfileShow} 
        user={userContext.user}
        onUpdateUser={setUserFromAPI}
        />}
    
    
          { bookmarkDialogContext.data !== undefined && <EditBookmarkDialog
               user={userContext.user}
               bookmark={bookmarkDialogContext.data}
               modalShow={bookmarkDialogContext.data !== undefined}
               hide={bookmarkDialogContext.hide}
               editCallback={handleUpdateBookmark}
               insertCallback={handleInsertBookmark}
               deleteCallback={handleDeleteBookmark}
           />
       }
       
        { diaryDialogContext.data !== undefined && <EditDiaryEntryDialog
               user={userContext.user}
               diaryEntry={diaryDialogContext.data}
               modalShow={diaryDialogContext.data !== undefined}
               hide={diaryDialogContext.hide}
               editCallback={handleUpdateVisit}
               insertCallback={handleInsertVisit}
               deleteCallback={handleDeleteVisit}
           />
       }

        </>
    )
    
    
}