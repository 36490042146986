import React, { ReactElement } from "react";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;


export enum Action {
    START_NEW_DAY,
    JOIN_FORMER_DAY,
    ADD_OVERNIGHT_STAY,
    ADD_EMPTY_DAY,
};

interface ComponentProps {
    tourEventId: string;
    actionStartNewDay: boolean;
    actionJoinFormerDay: boolean;
    onAction: (action: Action, tourEventId: string) => void;
}

const EventActionMenu = ({tourEventId, actionStartNewDay, actionJoinFormerDay, onAction}: ComponentProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Note: we have to overriode color, as long-button somhow overrides the inherited style
  return (
    <div>
      <IconButton
        size="small"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{color:"lightgray"}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5,
            width: '21ch',
          },
        }}
      >

          <MenuItem disabled={!actionJoinFormerDay}  onClick={ () => { handleClose(); onAction(Action.JOIN_FORMER_DAY, tourEventId); } }>Join with former day</MenuItem>
          <MenuItem disabled={!actionStartNewDay}    onClick={ () => { handleClose(); onAction(Action.START_NEW_DAY, tourEventId); } }>Start new day here</MenuItem>

      </Menu>
    </div>
  );
}

export default EventActionMenu;
