const { REACT_APP_RUNLOCAL } = process.env
const runlocal = REACT_APP_RUNLOCAL;
//const runlocal = true;
//const runlocal = false;


//const baseurl = "https://www.touredo.org/";

// We usually build all our URL's based on an absolute path, this means it excludes protocol, host and port.
// This helps to server different domains. Also it makes sure the whole site consistently stays on https (or http),
// which makes browsers happy (no warning about mixed http/https content) and avoids SOP/CORS issues.
// While developing locally, we are on a different port, so we need a different baseurl, and it means we need CORS headers!
const baseurl = runlocal === "true" ? "http://localhost:8080/" : "/";

console.log("REACT_APP_RUNLOCAL: " + runlocal + ", baseurl: " + baseurl);

export const auth_url = baseurl + "auth/";
export const api_url = baseurl + "api/";
export const poi_url = baseurl + "api/";