import { ReactElement, useContext, useState } from "react";

import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddEventDialogPoiSection from './AddEventDialogPoiSection';
import AddEventDialogFreetextSection from './AddEventDialogFreetextSection';
import AddEventDialogGeoSearchSection from './AddEventDialogGeoSearchSection';

import { EventToolbarCallbacks } from "../Map/EventToolbar";
import EventToolbarDialog from "../Map/EventToolbarDialog";


import {EnrichedUser, CoreMarker} from "../shared/Types"
import { Tour} from "../../api"

import { UserContext } from "../../contexts/UserContext";

import lstyles from "./AddEventDialog.module.scss";


interface PlanProps {
    tour: Tour;
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    addLocationCallback: (location : CoreMarker ) => void;
}

export const pushSection = (term: string) : void => {
    localStorage.lastSection = term;
}

export const lastSection = () : string => {
    const lastString : string = localStorage.lastSection;
    return lastString ? lastString : "poi";
}

const AddEventDialog = ({tour, modalShow, setModalShow, addLocationCallback}: PlanProps): ReactElement => {
    const user: EnrichedUser = useContext(UserContext).user;

    const [errormsg, setErrormsg] = useState<string>("");
    const [section, setSection] = useState<string | null>(lastSection());
    const [location, setLocation] = useState<CoreMarker | undefined>(undefined);
    
    // add the new TourEvent, and close the DialoghandleAddTourEvent
    const handleAddTourEvent = (location : CoreMarker ) => {
        addLocationCallback(location);
        setModalShow(false);
    }
    
    const callbacks : EventToolbarCallbacks = {
        openBookmarkDialog : undefined,
        addTourEvent : handleAddTourEvent,
        openLocationEditorDialog : undefined,
        openDiaryDialog : undefined
    };
	
    const handleSection = (
        event: React.MouseEvent<HTMLElement>,
        newSection: string | null,
    ) => {
        if(newSection !== null) {
            pushSection(newSection);
            setSection(newSection);
        }
    };


    
    return (
        <>
          <Dialog open={modalShow}
            PaperProps={{ sx: { width: "66%", height: "100%" } }}

            onClose={() => setModalShow(false)}>

            <DialogTitle>Add event - {tour.name} </DialogTitle>
            <DialogContent >
            <ToggleButtonGroup className={lstyles.sectionbuttons} exclusive aria-label="Search type" value={section} onChange={handleSection}>
                <ToggleButton value="poi">Attraction</ToggleButton>
                <ToggleButton value="geosearch">Address</ToggleButton>
                <ToggleButton value="freetext">Custom</ToggleButton>
                {/*-  <ToggleButton value="mappicker">Pick from Map</ToggleButton> */}
            </ToggleButtonGroup>
            
            <hr/>

            {errormsg ? errormsg : ""}

            {section==="poi"       && <AddEventDialogPoiSection addTourEvent={setLocation} setErrormsg={setErrormsg} />}
            {section==="freetext"  && <AddEventDialogFreetextSection addTourEvent={setLocation} setLocation={setLocation} />}
            {section==="geosearch" && <AddEventDialogGeoSearchSection addTourEvent={setLocation} setErrormsg={setErrormsg} />}
            {section==="mappicker" && <> </> }

            </DialogContent>

            </Dialog>
            
            {location && <EventToolbarDialog user={user} location={location} callbacks={callbacks} withAddress={true} modalShow={modalShow} setModalShow={() => {setLocation(undefined)}} /> }
        </>
       );
};

export default AddEventDialog;
