import { ReactElement, useState } from "react";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { GeocodedLocation } from "../../api"
import {CoreMarker} from "../shared/Types"

import { geosearch } from "../shared/GeoService"
import MultiItemPicker from "../shared/MultiItemPicker";
import { CountryMap } from "../shared/GeoConstants";
import PoiSelector from "../shared/PoiSelector"
import {lastCCs, lastLocation, pushCCs, pushLocation} from "../Search/SearchTerms"

interface SectionProps {
    addTourEvent: (location : CoreMarker ) => void;
    setErrormsg: (msg: string) => void;
}


const AddEventDialogGeoSearchSection = ({setErrormsg, addTourEvent}: SectionProps): ReactElement => {
    const [locations, setLocations] = useState<GeocodedLocation[]>([]);
    const [locationTF, setLocationTF] = useState<string>(lastLocation());
    const [country, setCountry] = useState<string[]>(lastCCs());
    const [hasSearched, setHasSearched] = useState<boolean>(false);

    const updateLocations = (location: string, country: string[]) : void => {
        // Do a global search. This is currently independent from map (this means w/o Geo coordinate)
        geosearch(location, country.length > 0 ? country : []).then(response =>
            {
                setHasSearched(true)
                if (response.error) {
                    setErrormsg("Error searching for locations.");                  
                } else {
                    setLocations(response.locations);
                    setErrormsg(""); // clear error
                }
            }
        )
    };

    // Do not use any useEffect() here. Otherwise, the Geosearch could be triggered multiple times. Reason:
    // This would be an "autocomplete", which is strictly forbidden by the OpenStreetMap / Geocoding fair-use policy.

    return (
        <>
       <p>
            Freetext address search, using OpenStreetMap.
            </p>

                <TextField required id="addevent-location"  label="Location" fullWidth autoFocus={true} type="search" value={locationTF}
                   onKeyDown={ev => {
                        if (ev.key === 'Enter') {  updateLocations(locationTF, country); ev.preventDefault(); } } }
                   onChange={e => { setLocationTF(e.target.value); pushLocation(e.target.value); }}
                  />
                  <MultiItemPicker onChange={e => {setCountry(e); pushCCs(e);}} title="Country" selected={country} keyValues={CountryMap} multiple={true} colonSelectorFormat={true}/>

                  <br/>
                 <Button  variant="contained" disabled={locationTF.trim() === ""} onClick={() => {updateLocations(locationTF, country);  } }>Search</Button>

            <PoiSelector pristine={!hasSearched} selectedHandler={addTourEvent} previewHandler={undefined}
             locations={locations} resultboxVisible={true} countryFilterHandler={cc => setCountry([cc])}
             noResultsHelpMessage={<>No Locations found in OpenStreetMap.<br/>Search tips: Vary your search terms (e.g. bahnhofsstr vs bahnhofsstrasse). Remove the country filter. Switch to the ATTRACTION search. If you still cannot find it, add the location manually via CUSTOM.</>}
               />
        </>
    );
}

export default AddEventDialogGeoSearchSection;
