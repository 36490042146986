import axios, { AxiosInstance } from "axios";

import * as Config from "../Config";

import { ErrorHandler, et500 } from "../components/shared/Types";

import { EnrichedTour, Tour } from "../api";


const axiosClient = (): AxiosInstance => {
    axios.defaults.withCredentials = true;
    return axios;
}


 export const postTour = (tour: Tour, callback: ErrorHandler):  Promise<EnrichedTour | void> => {
    return axiosClient().post<EnrichedTour>(Config.api_url + "tours", tour).then(response => {
        return response.data;
    }).catch(()  => {
        callback(et500("Error loading tour"));
    }).finally(callback) ;
}

 export const exportTour = (tour: Tour, callback: ErrorHandler):  Promise<String | void> => {
    return axiosClient().get<String>(Config.api_url + "tours/" + tour.uuid + "/export").then(response => {
        return response.data;
    }).catch(()  => {
        callback(et500("Error exporting tour"));
    }).finally(callback) ;
}
