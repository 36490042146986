
import { useEffect, useState } from "react";

// This code is inspired by https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const smallWidth = 620; 

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      // Set the height in state as well as the width
      setHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return both the height and width
  const isSmallWidth : boolean = width < smallWidth;
  return { width, height, isSmallWidth };
}

