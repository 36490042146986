import { ReactElement } from "react";

import TextField from '@mui/material/TextField';

import MultiItemPicker from "../shared/MultiItemPicker";
import { CountryMap } from "../shared/GeoConstants";

import lstyles from "./FilterBar.module.scss";

export interface FilterParameter {
    countryCodesChoices: string[];
    countryCodesSelected: string[];
    searchTerm: string;
}
    
interface ComponentProps {
	filters: FilterParameter;
    onChange: (filter : FilterParameter ) => void;
}

const FilterBar = ({filters, onChange}: ComponentProps): ReactElement => {
    const filteredCountryMap = new Map<string,string>();
    CountryMap.forEach((value, key) => {
        //console.log(value, key)
        if (filters.countryCodesChoices.includes(key)) {
           filteredCountryMap.set(key, value);
        }
    });
         
    return (
        <div className={lstyles.filterbar}>
            <MultiItemPicker onChange={ccs => onChange({...filters, countryCodesSelected: ccs }) } title="Countries" selected={filters.countryCodesSelected} keyValues={filteredCountryMap} multiple={true} colonSelectorFormat={true}/>
            <TextField value={filters.searchTerm} label="Search term" sx={{width: "50%"}} 
              onChange={e => { onChange({...filters, searchTerm: e.target.value})  }} />
        </div>
    );
};

export default FilterBar;
