import { ReactElement } from "react";
import {Address, GeoCoordinate, GeoPrecision, LocationResponse, ProviderRef} from "../../api";

import {flagImages} from "../tools/Assets";

import * as L from 'leaflet';

// Historically the name was "Location". Then the calendar was added in the LocationResponse.
// So the codebase uses Location, but it actually refere to the enriched LocationResponse.
export type Location = LocationResponse;

export const providerRefSerialize = (providerRef : ProviderRef) : string => {
	return providerRef.provider + ":" + providerRef.id;
}

export const formatAddress = (address: Address) : string => {
	let result = "";
	if (address) {
        if (address.postcode && address.postcode !== "") {
            result += address.postcode + " ";
        }
		if (address.city) {
			result += address.city;
		}
		if (address.country && address.country !== "CC") {
			if (result.length > 0) {
				result += ", ";
			}
			result += address.country;
		}
	}
	
	return result;
}


export const formatAddressAfterCountry = (address: Address, countrySepartor: string | undefined) : string => {
    let result = "";
    let hasCity = false;
    if (address.postcode && address.postcode !== "") {
        if (countrySepartor) {
            result += countrySepartor;
            countrySepartor = undefined;
        }
        result += address.postcode + " ";
    }
    if (address.city && address.city !== "") {
        if (countrySepartor) {
            result += countrySepartor;
            countrySepartor = undefined;
        }
        result += address.city;
        hasCity = true;
    }
    if (address.street && address.street !== "") {
        if (hasCity) {
            result += ", ";
        }       
        result += " " + address.street;
    }
   
    return result;
}

export const formatAddressFull = (address: Address) : string => {
    let result = "";
    let countrySepartor : string | undefined = undefined;
    if (address.country && address.country !== "") {
        result += address.country;
        countrySepartor = " : "; 
    }
    return result + formatAddressAfterCountry(address, countrySepartor);
}



export const formatAddressFullWithCountryFlag = (address: Address) : ReactElement => {
    return (
       <div>
       { address.country && flagImages([address.country], 1) }
       { formatAddressAfterCountry(address, address.country ? " " : undefined)}
       </div> 
    )
}


export const formatAddressNoCountry = (address: Address) : string => {
    let result = "";
    if (address) {
        if (address.city) {
            result += address.city;
        }
    }
    
    return result;
}


export const formatAddressOnlyCountry = (address: Address) : string => {
	let result = "";
	if (address) {
		if (address.country && address.country !== "CC") {
			result = address.country;
		}
	}
	
	return result;
}


export const leafletToModel = (leafletGeoCoordinate : L.LatLng) : GeoCoordinate => {
	const geoCoordinate : GeoCoordinate = {
		latitude: leafletGeoCoordinate.lat,
		longitude: leafletGeoCoordinate.lng,
		geoPrecision: GeoPrecision.Exact
	};
	
	return geoCoordinate;
}


export const modelToLeaflet = (geoCoordinate : GeoCoordinate) : L.LatLng => {
	return L.latLng(geoCoordinate.latitude, geoCoordinate.longitude);
}
