import React, { ReactElement, useContext, useState, useRef } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import {login, register} from "../Account/AccountTools"
import ConfirmDialog from "../shared/ConfirmDialog";
import {ErrorStatusType} from "../shared/Types"
import {User, UserIdentity, UserProfile} from "../../api"

import {UserContext} from "../../contexts/UserContext";

import styles from "../../components/shared/Styles.module.scss";
import lstyles from "./LoginDialog.module.scss";

interface LoginProps {
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    onUpdateUser: (user: User) => void;
}

// Validation fields
interface VFields {
    username?: string;
    password?: string;
    repeatPassword?: string;
    emailAddress?: string;
    countryCode?: string;
}

const forgotPasswordMessge = "If you have problems accessing your account, please contact us and give as much information as possible. Please use the email address of your touredo account, if possible.";

const forgotMailLink = "mailto:info@touredo.org?subject=Reset touredo account password" +
     "&body=Please give as much information as possible to help restoring access to your account."
    + "%0D%0AAccount name: "
    + "%0D%0AEmail address: "
    + "%0D%0APublic name: "
    + "%0D%0AAdditional information (e.g. last usage, account created when): "
    + "%0D%0A%0D%0ADisclaimer: I affirm that I am the rightful owner of the given account."
    + "%0D%0A%0D%0A%0D%0A%0D%0ANote: Restoring access is a manual process. If required the touredo team will contact you.";
    ;

const sendMailButton = () : ReactElement => {
    return ( <Button variant="contained"  href={forgotMailLink} >Send mail</Button> )
}

// autocomplete docs: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
const LoginDialog = ({modalShow, setModalShow, onUpdateUser}: LoginProps): ReactElement => {
    const userContext = useContext(UserContext);
    const user = userContext.user;

    
    const [usernameTF, setUsernameTF] = useState<string>(user && user.userId ? "" + user.userId : "");
    const [passwordTF, setPasswordTF] = useState<string>("");
    const [repeatPassword, setRepeatPassword] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [durableSession, setDurableSession] = useState<boolean>(false);
    
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [passwordRecoveryHint, setPasswordRecoveryHint] = useState<boolean>(false);
    // Registration
    const [registrationMode, setRegistrationMode] = useState<boolean>(false);
    
    // validation
    const [validations, setValidations] = useState<VFields>();
	const [invalid, setInvalid] = useState<boolean>(true);

    const [modalConfirmDialog, setModalConfirmDialog] = useState<boolean>(false);

    const countryCode: string = ""; // not set. Will be set at registration time to a default, derived from the langauge in the backend 


	const loginResponseHandler = (userApiModel: User, status: ErrorStatusType): void => {
		console.log("status: " + status);
		switch (status) {
			case ErrorStatusType.OK: {
				//const loggedIn: boolean = userApiModel.accountStatus === AccountStatus.User || userApiModel.accountStatus === AccountStatus.Unconfirmed;
				//const up = userApiModel.userProfile;
				//const user: User = {userId:userApiModel.userId, countryCode: up.countryCode, language: up.language, name: up.publicName };
				onUpdateUser(userApiModel);
		        setModalShow(false);
				break;
			}
			case ErrorStatusType.ACCOUNT_ALREADY_EXISTS: {
				setErrorMessage("Username already in use. Please select a different one.");
				setPasswordRecoveryHint(false);
				break;
			}
			case ErrorStatusType.PERMISSION_DEINED: {
				setErrorMessage("Failed to log in");
				setPasswordRecoveryHint(true);
				break;
			}
			case ErrorStatusType.SERVER_ERROR:
			case ErrorStatusType.SERVER_UNAVAILABLE: {
				setErrorMessage("Problem logging in. If this problem persists contact the site admin.");
				setPasswordRecoveryHint(false);
				break;
			}
			default: {
				// Unexpected: At the moment, show the same error message as SERVER_ERROR
				setErrorMessage("Unexpected problem logging in. If this problem persists contact the site admin.");
				setPasswordRecoveryHint(false);
				break;				
			}
			
		}
	}

    const resetErrors = () => {
       setErrorMessage("");
       setPasswordRecoveryHint(false);
    }

    const loginHandler = () => {
        resetErrors();
        login(loginResponseHandler, usernameTF, passwordTF, durableSession);
    };
    
    const registerHandler = () => {
        resetErrors();
        const userIdentity: UserIdentity = {loginName:usernameTF, emailAddress:emailAddress, confirmationCode:""}; // confirmationCode is ignored. It is created in the backend.
        const userProfile: UserProfile = {countryCode:countryCode, publicName:"", homeAddress:{country:countryCode}, language:navigator.language};
        register(loginResponseHandler, userIdentity, userProfile, passwordTF, durableSession);
        console.log("Register new account: " + usernameTF);
        //login(loginResponseHandler, usernameTF, passwordTF, durableSession);
    };


    const validate = () : boolean => {
        let tmp : VFields = {};
        tmp.username = usernameTF.length > 0 ? undefined : "Username must be set";
        tmp.password = !registrationMode ? undefined : passwordTF.length < 6 ? "Password must contain 7 or more characters" : (passwordTF.length > 32 ? "Password too long" : undefined);
        tmp.repeatPassword = !registrationMode ? undefined : passwordTF === repeatPassword ? undefined : "Passwords do not match";
        tmp.emailAddress = !registrationMode ? undefined :  (/^[^ ]+@[^ ]+\.[^ ]+$/).test(emailAddress) ? undefined : "Invalid email address.";
        tmp.countryCode = !registrationMode ? undefined : countryCode.length === 0 || countryCode.length === 2 ? undefined : "Country  must be a two letter country code (DE, FR, IT, US, ...)"
        setValidations(tmp);
        
        const isInvalid = ! Object.values(tmp).every(x => x === undefined)
        setInvalid(isInvalid);
        return isInvalid;
    }

	const inputUsername = useRef<HTMLInputElement>(null);

    return (
    <>
        <Dialog open={modalShow}

                      onClose={() => setModalShow(false)}>
        <DialogTitle>Login or Register</DialogTitle>
            <form>
            <DialogContent>
                <div className={styles.modalcontent}>

                <TextField required id="login-username" autoFocus fullWidth
                     ref={inputUsername}
                     error={validations?.username !== undefined}
                     helperText={validations?.username}
                     onBlur={e => { validate()}}
                     autoComplete="username"
                     label="Username" type="username" value={usernameTF}
                     onChange={e => { setUsernameTF(e.target.value); validate(); }} />

                <TextField required id="login-password" fullWidth
                   label="Password" type="password" autoComplete="current-password" value={passwordTF}
                   error={validations?.password !== undefined}
                     helperText={validations?.password}
                     onBlur={e => { validate()}}
                    onChange={e => { setPasswordTF(e.target.value); validate(); }} />
                
                {registrationMode && (<>
                <TextField id="repeat_password"  fullWidth
                   label="Repeat password" type="password" autoComplete="new-password" value={repeatPassword}
                   error={validations?.repeatPassword !== undefined}
                   helperText={validations?.repeatPassword}
                   onBlur={e => { validate()}}
                   onChange={e => { setRepeatPassword(e.target.value); validate(); }} />

                <TextField id="email_address"  fullWidth
                   label="EMail Address" type="email" autoComplete="email" value={emailAddress}
                   error={validations?.emailAddress !== undefined}
                   helperText={validations?.emailAddress}
                   onBlur={e => { validate()}}
                   onChange={e => { setEmailAddress(e.target.value); validate(); }} />
                </>)
                }
                    
    			    <FormControlLabel
     		          	control={<Switch checked={durableSession} onChange={event => setDurableSession(event.target.checked)} />}
	                   label="Angemeldet bleiben" />

<hr/>
                {!registrationMode
                ?  <>New here? <Button variant="outlined" onClick={() => {setRegistrationMode(true); inputUsername.current && inputUsername.current.focus(); } }>Register</Button>
                </>
                : <>
{/**
                <TextField id="country"  fullWidth
                     label="Favorite travel country: DE,FR,US, ..." type="country" autoComplete="country" value={countryCode}
                      onChange={e => { setCountryCode(e.target.value); validate(); }} />
 */}
                </>
                }


				{passwordRecoveryHint && <><hr/><span className={lstyles.errormsg}> <b>Forgot your password?</b> <Button variant="contained"  onClick={() => setModalConfirmDialog(true)} >Restore password</Button> </span> </>}
				{(!passwordRecoveryHint && errorMessage.length > 0) && <><hr/><span className={lstyles.errormsg}><b> {errorMessage} </b> </span> </>}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => {setModalShow(false);  } }>Cancel</Button>
                {/** 
                // VERY BIG WARNING: Button type MUST be "button", and not "submit". axios may reject to send the request
                // in loginHandler() with "request aborted" cause, which happens for example in Firefox.
                // See https://stackoverflow.com/questions/61522494/react-axios-error-request-aborted-for-delete-request-in-firefox-but-not-in-chro
                */} 
                { registrationMode
                ? <Button type="button" variant="outlined" disabled={invalid} onClick={() => {registerHandler();  } }>Register</Button>
                : <Button type="button" variant="outlined" disabled={invalid} onClick={() => {loginHandler();  } }>Login</Button>
                }
            </DialogActions>
            </form>
        </Dialog>
        
        <ConfirmDialog title="Password recovery" isDeleteAction={false} hideCancelAction={true}
            confirmedCallback={() => {}} modalShow={modalConfirmDialog} setModalShow={() => setModalConfirmDialog(false)}
            reference={{}}
            message = {forgotPasswordMessge} extraElement={sendMailButton()}/>
    </>
    );
};

export default LoginDialog;
