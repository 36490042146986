import { ReactElement } from "react";

//import IconButton from '@mui/material/IconButton';
//import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

//import ClearIcon from '@mui/icons-material/Clear';


/** The OpeningPicker allows to edit opening hours for a single DayOfWeek */
interface EditProps {
    id: string;
    className?: string;
    label: string;
    editDate: Date | string;
    setDate: (date: Date | string) => void;
    disabled: boolean;
}




/**
     The TouredoDatePicker allow to pick a Date, and to reset it. 
*/ 
const TouredoDatePicker = ({className, id, label, editDate, setDate, disabled}: EditProps): ReactElement => {
    //console.log("The edit date is: " + editDate);
    
    return (
                <TextField className={className} id={id} margin="none" disabled={disabled}
                     label={label} type="date" value={editDate}
                     size="small"
                      onChange={e => { setDate(e.target.value) }}
                      InputLabelProps={{ shrink: true  }}
                     />
           );
           
           /**
                                 InputProps={{
                      startAdornment: (
                        
                        <InputAdornment position="start">
                            <IconButton edge="end" onClick={() => { setDate("") }}>
                                <ClearIcon color="error" />
                            </IconButton>
                        </InputAdornment>
                      ),
                    }}
            
            * 
            */
};

export default TouredoDatePicker;
