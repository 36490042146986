import {Address, Activity, GeoCoordinate, Importance, OpeningCalendar, OSMBoundingBox, ProviderRef, User} from "../../api"

// This file contains frontend specific domain models.

export interface EnrichedUser extends User {
    name?: string;
    loggedIn: boolean;
    language?: string;
    countryCode?: string;
};


export enum PoiType {
	BOOKMARK,
    VISITED,
	RCDB,
	UNESCO_WHC,
	TOUREVENT
}

// Contains activation state of data providers and user data (bookmarks)
export interface ActivePOIs {
	// User POI's 
	bookmarks: boolean;
    visited: boolean;
	tourevents: boolean;
	// Providers
	rcdb: boolean;
	unescoWhc: boolean;
}



/** BaseMarker holds Location data. It could be renamed to Location.  */
export interface BaseMarker {    
    name: string;                 // It should have a name
    address?: Address;            // A readable address would be nice
    boundingBox?: OSMBoundingBox; // 
    providerRef?: ProviderRef;    // A standard event will have a providerRef. A custom evnet doe not require it
    calendar?: OpeningCalendar;   // totally optional
    tourEventId?: string;         // Referencing tourEvent, if any
    importance?: Importance;
    attractions?: Activity[];
}


/* A MapMarker holds all data of the markers on the map, e.g. for TourEvent, Bookmark or POI.
   Some data like the GeoCoordinate is required), others like the providerReference are optional (you can have a custom TourEvent w/o
   providerReference).
*/
export interface MapMarker extends BaseMarker {
    geoCoordinate: GeoCoordinate; // Anything on the map must have a GeoCoordinate.
}

/** A CoreMarker is exactly like a Mapmarker, but it does not need to have a GeoCoordinate.
 */
export interface CoreMarker extends BaseMarker {
    geoCoordinate?: GeoCoordinate; // User can add events w/o GeoCoordinate. e.g, "Highland Games 2023", or "Hotel Adlon in Berlin center"
}




export enum ErrorStatusType {
	OK,
	PERMISSION_DEINED,
	// Backend Server down. This is due to planned maintencance or outage. All connect failures.
	SERVER_UNAVAILABLE,
	// Server response exists, but is unexpected/exceptional. All 5xx errors. Usually caused by software bug.
	SERVER_ERROR,
	// For registration dialog:
	ACCOUNT_ALREADY_EXISTS,
	INVALID_EMAIL_ADDRESS
}

export interface ErrorTelegram {
	source: string | undefined;
	status: ErrorStatusType;
	message: string;
}

export const et500 = (message: string): ErrorTelegram =>  {
    return {message: message, status: ErrorStatusType.SERVER_ERROR, source: undefined };
}

// A function for sending error information to a different React component, which is responsible to handle it.
// Sending "undefined" means that the error situation is resolved. 
export type ErrorHandler = (error?: ErrorTelegram) => void;
